export const TrendingVideos = [
    { id: "96E4vXlXq9U", title: "LL COOL J Needs Some Milk While Eating Spicy Wings | Hot Ones", channelTitle: "First We Feast" },
    { id: "rnFOWvAAapU", title: "Catching the guy who stole 20 things from me.", channelTitle: "WhistlinDiesel" },
    { id: "-Zi1m8hzK8I", title: "Asking A Private Jet Billionaire How To Make $1,000,000", channelTitle: "Noah Kagan" },
    { id: "GC0j2Ey5NNk", title: "How To DRY POUR CONCRETE SLAB and Update of Our 1st Pour", channelTitle: "Cajun Country Livin'" },
    { id: "_T7Wpg7A_xw", title: "This tiny NYC apartment goes for $650 a month!", channelTitle: "Caleb Simpson" },
    { id: "dPGoQDsILlI", title: "I got the NEW Longest Username Possible on a Youtube Play Button", channelTitle: "JackSucksAtLife" },
    { id: "KkIkymh5Ayg", title: "The Deceptive World of Ghost Kitchens", channelTitle: "Eddy Burback" },
    { id: "b8SDfsimvWY", title: "Math Olympiad Question | Nice Algebra Equation | You should know this trick!", channelTitle: "LK Logic" },
    { id: "TtVCNCZZ47g", title: "When Magnus Carlsen Destroyed Bill Gates in 12 Seconds", channelTitle: "CHECKMATE" },
    { id: "DOgOMLG-Zyg", title: "How Much Food Can I Grow in 1 Year?", channelTitle: "Just Alex" },
    { id: "jZ7Bu_SDJgM", title: "Inside Tokyo's TINIEST Luxury Apartment", channelTitle: "Tokyo Lens" },
    { id: "Qpn-G-OFbkM", title: "I Survived the World's Quietest Room", channelTitle: "Ryan Trahan" },
    { id: "-d5kk6SkyY8", title: "I Learned 92 Skills in 2 Years, Can I Still Do Them?", channelTitle: "Mike Shake" },
    {id:"FTqmOp3Zwp0", title: "I Cried TEARS TWICE.. CAN'T HOLD IT IN ANYMORE | Try Not To Laugh Challenge #9", channelTitle: "CoryxKenshin" },
    { id: "VVQjQMtjLpI", title: "Do Spring Emoji's Cause Cavities !?", channelTitle: "Dental Digest" },
    {id: "t6mc-zFfjP4", title: "My Farm Pond Is Off To A Rough Start", channelTitle: "letsdig18"},
    {id:"9HBzIsFs1d4", title: "Rebuilding A Wrecked 2021 Ford Bronco Part 10!!!", channelTitle: "goonzquad"},
  
  ];