import React, { useState } from 'react';
import { Modal, Button, Typography, TextField, Stepper, Step, StepLabel, ButtonBase, Dialog, Grow, Slide, Card, IconButton, MobileStepper } from "@mui/material";
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/system';
import { ArrowBackIos, ArrowForward, ArrowForwardIos, Close, CloudDownload, Pause, PlayArrow } from '@mui/icons-material';
import { OnboardingPanel } from './CaptionFeedbackforIframe.js';
import { Stage, Layer, Star, Text, Rect, Image } from 'react-konva';
import Konva from 'konva';
import ReactCardFlip from 'react-card-flip';
import useImage from 'use-image';
import { Functions } from './Functions.js';
import { isMobile } from 'react-device-detect';
const themeOptions = {
    palette: {
        // mode: 'dark',
        primary: {
            main: '#edf9f9',
        },
        secondary: {
            main: '#ffbd58',
        },
        contrastThreshold: 4.5,


    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        body1: {
            color: "white",
            fontSize: '.9rem',
        },
        h3: {
            color: "white",
        },
        h4: {
            color: "white",
        },
        h6: {
            color: "white",
            fontSize: '1.1rem'
        },
        h5: {
            color: "white",
            fontSize: '1.5rem'
        },
        caption: {
            color: "white",
        },
        body2: {
            color: "white",
        },
        subtitle1: {
            color: "white",
        },
        subtitle2: {
            color: "white",
        },
        h2: {
            color: "white",
            fontWeight: '400',
            fontSize: '1rem',
            '@media (min-width:750px)': {
                fontSize: '1rem',
            }
        }
    },
    contrastThreshold: 4.5,
    contrastText: '#fff',
    overrides: {
        MuiButton: {
            raisedPrimary: {
                color: 'white',
            },
        },
    }

};

const theme = responsiveFontSizes(createTheme(themeOptions));

export function OnboardingModal(props) {

    const { showOnboarding, viewerId, openOnboarding, width, height } = props;

    const [activeStep, setActiveStep] = useState(0);

    const smallFrame = window.innerWidth < 650 || isMobile;
    const largeFrame = window.innerWidth > 1200;

    const steps = [
        {
            name: "Welcome", title: "Viewers improve subtitles and enjoy videos with better captions on dubsee.", content: "", action: "How It Works"
        },
        {
            name: "accessing dubsee", title: "If a caption is wrong, just pause the video to see the dubsee panel.", content: "", action: "Using dubsee"
        },
        {
            name: "dubsee panel", title: "The dubsee panel shows the caption and tools to update it.", content: "", action: "Signing In"
        },
        {
            name: "Sign In", title: "Start by signing in with your Google account.", content: "", action: "Flag & Fix"
        },
        {
            name: "Flag & Fix", title: "Once signed in, you can flag any caption as wrong.", content: "", action: "Improving Captions"
        },
        {
            name: "Updates", title: "Then, type in what the caption should say and submit it.", content: "", action: "Voting"
        },
        {
            name: "Voting", title: "Viewers vote on other viewer's changes.", content: "", action: "Accepted Changes"
        },
        {
            name: "Acceptence", title:"Viewer approved changes are published on dubsee. 🎉 " , content: "", action: "Your Stats"
        },
        {
            name: "Stats", title:"That's it! Check your stats on your viewer profile.", content: "", action: "Start Watching"
        },
    ];

    // Creators download updated captions to upload directly to Youtube.

    const completeOnboarding = async() => {
        document.cookie = "onboarded=true; path=/;";
        await Functions.user.setUserOnboarded(viewerId);
    }

      const handleClose = () => {
        openOnboarding(false);
      };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = () => {
        if (activeStep < 8) {
            handleNext();
        }
        else {
            completeOnboarding();
            handleClose();
        }
    };

    const selected = activeStep == 2;
    const originalText = "This caption is write!";
    const interText = "This caption is";
    const finalText = "This caption is right!";


    var textObj = new Konva.Text({
        text: originalText,
        fontSize: width * .03,
        align: 'center',
        wrap: "word"
    });

    const textWidth = textObj.textWidth;
    const textHeight = textObj.textHeight * textObj.textArr.length;
    const padding = width * .05;

    const [image] = useImage("/accepted.png");

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{position: "absolute", top: 0, left: 0, width:"100%", height:"100%"}}>
            <Box sx={{ display: 'flex', flexDirection: "column", pointerEvents: "auto", justifyContent: 'center', backdropFilter: "blur(10px)", background: "rgba(25,25,25,.3)", alignItems: 'top', height:"100%", width:"100%" }}>
                <Box sx={{background: "rgba(25,25,25,0)", borderRadius: 1, position: "absolute", top: 0, left: 0, height:"100%", width:"100%" }}>
                    <Box>
                        <Box sx={{ display: "flex", m: 1, cursor: "pointer", alignItems: "center" }}>
                            {smallFrame ? 
                            
                            <Typography align="right" variant="h6">{activeStep + 1}/{steps.length}</Typography> :
                            <>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <Box sx={{ display: "flex", mr: 1 }}>
                                    <Typography variant={smallFrame ? "body1" : largeFrame ? "h5" : "h6"} letterSpacing={-1}
                                        fontWeight="600" fontFamily="Inter, sans-serif"
                                        color="#ffbd58">dubsee</Typography>
                                </Box>
                            </Box>
                                <Box sx={{ display: "flex", flexGrow: 1 }}>
                                    <Box sx={{ width: "100%", zIndex: 10 }} >
                                        {smallFrame ?
                                            <Typography align="right" variant="h6">{activeStep + 1}/{steps.length}</Typography>
                                            :
                                            <Stepper activeStep={activeStep}>
                                                {steps.map((step, index) => (
                                                    <Step key={step.name} onClick={() => setActiveStep(index)}>
                                                        <StepLabel />
                                                    </Step>
                                                ))}
                                            </Stepper>}
                                    </Box>
                                </Box> 
                                    </>

                                                }       

                            <Box sx={{display: "flex", m: 1,  pointerEvents: "auto", zIndex:10 }}>
                                <Button color="primary" size={smallFrame ? "small" : "large"} onClick={handleClose} endIcon={<ArrowForwardIos />} variant="outlined">
                                    Skip
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {activeStep == 0 ?

                        <Box sx={{ position: "absolute", left: 0, top: 0, display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%", pointerEvents: "none" }}>
                            <Typography color="white" variant={smallFrame ? "h6" : largeFrame ? "h3" : "h4"} >
                                Welcome to <Typography variant={smallFrame ? "h6" : largeFrame ? "h3" : "h4"} component="span" letterSpacing={-1}
                                    fontWeight="600" fontFamily="Inter, sans-serif"
                                    color="#ffbd58">dubsee </Typography> for YouTube
                            </Typography>
                        </Box>

                        :null}

                {activeStep >= 1 ?
                    <Grow duration={2000} in mountOnEnter unmountOnExit>
                        <Box sx={{ position: "absolute", left: 0, top: 0, display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%", pointerEvents: "none" }}>
                            <Box>
                                    <Pause color="primary" sx={{opacity:activeStep > 1 ? .2 : 1, mt:smallFrame ? -10 : 0, fontSize: smallFrame ? 50 : 100 }} />
                            </Box>
                        </Box>
                    </Grow> : null}

                {activeStep >= 1 && (smallFrame ? activeStep <= 5 : activeStep <= 6) ?
                    <Grow duration={2000} in mountOnEnter unmountOnExit>
                        <div>
                            <Stage width={width} height={height} opacity={1}>

                                <Layer width={width} height={height}>
                                    <Rect
                                        x={((width - textWidth - padding)) / 2}
                                        y={smallFrame ? height * .55 : height * .65}
                                        width={textWidth + padding}
                                        height={textHeight + padding}
                                        fill="black"
                                        opacity={.6}
                                        cornerRadius={width * .03 / 5}
                                        strokeEnabled={selected}
                                        strokeWidth={smallFrame ? 4 : 8}
                                        stroke="#ffbd58"
                                        shadowColor='#ffbd58'
                                        shadowBlur={10}
                                        shadowOpacity={1}
                                        shadowEnabled={selected}
                                    />

                                    <Text
                                        text={originalText}
                                        x={((width - textWidth - padding)) / 2}
                                        y={smallFrame ? height * .55 : height * .65}
                                        fontSize={width * .03}
                                        padding={padding / 2}
                                        align="center"
                                        fill="#ffffff"
                                        wrap="word"
                                    />
                                </Layer>
                            </Stage>
                        </div>
                    </Grow>
                    : null}

                {(smallFrame ? activeStep == 7 : activeStep >= 7) ?
                    <Grow duration={2000} in mountOnEnter unmountOnExit>
                        <div>
                            <Stage width={width} height={height} opacity={1}>

                                <Layer width={width} height={height}>
                                    <Rect
                                        x={((width - textWidth - padding)) / 2}
                                        y={smallFrame ? height * .55 : height * .65}
                                        width={textWidth + padding}
                                        height={textHeight + padding}
                                        fill="black"
                                        opacity={.6}
                                        cornerRadius={width * .03 / 5}
                                        strokeEnabled={activeStep == 7}
                                        strokeWidth={smallFrame ? 4 : 8}
                                        stroke="#ffbd58"
                                        shadowColor='#ffbd58'
                                        shadowBlur={10}
                                        shadowOpacity={1}
                                        shadowEnabled={activeStep == 7}
                                    />

                                    <Text
                                        text={finalText}
                                        x={((width - textWidth - padding)) / 2}
                                        y={smallFrame ? height * .55 : height * .65}
                                        fontSize={width * .03}
                                        padding={padding / 2}
                                        align="center"
                                        fill="#ffffff"
                                        wrap="word"
                                    />

                                    <Image image={image} x={((width / 2 + textWidth / 2))}
                                        y={(smallFrame ? height * .55 : height * .65) - (width * .03 / 3)} width={width * (smallFrame ? .05 : .03)} height={width * (smallFrame ? .05 : .03)} />

                                </Layer>
                            </Stage>
                        </div>
                    </Grow>
                    : null}

{activeStep >= 2 && (activeStep != 7) ?
                    <Grow duration={2000} in mountOnEnter unmountOnExit>
                        <Box sx={{ position: "absolute", top: 20, right:0, pointerEvents: "none" }}>

                            {isMobile ?
       <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexGrow: 1, m: 2, mr: smallFrame ? -5 : 2, mt: smallFrame ? -4 : 7,  transform: smallFrame ? "scale(0.75)" : null}} onClick={props.pause} >
                                <OnboardingPanel originalText={originalText} interText={interText} finalText={finalText} activeStep={activeStep} width={props.width} />
                                </Box>
                                :

                            <ReactCardFlip isFlipped={(activeStep == 4 || activeStep == 6)} flipDirection="horizontal">
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexGrow: 1, m: 2, mr: smallFrame ? -5 : 2, mt: smallFrame ? -4 : 7,  transform: smallFrame ? "scale(0.75)" : null}} onClick={props.pause} >
                                    <OnboardingPanel originalText={originalText} interText={interText} finalText={finalText} activeStep={activeStep} width={props.width} />
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexGrow: 1, m: 2, mr: smallFrame ? -5 : 2, mt: smallFrame ? -4 : 7,  transform: smallFrame ? "scale(0.75)" : null}} onClick={props.pause} >
                                    <OnboardingPanel originalText={originalText} interText={interText} finalText={finalText} activeStep={activeStep} width={props.width} />
                                </Box>
                            </ReactCardFlip>}
                        </Box>
                    </Grow> : null}

                <Box sx={{position: "absolute", bottom: 0, left: 0, width:"100%"}}>
                <Box sx={{background: smallFrame ? "transparent" : "rgba(25,25,25,.8)", borderRadius: 1, m: smallFrame ? 0 : 1, display: "flex", flexDirection: "row"}}>

                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", ml: 2, flexGrow: 1 }}>
                            
                            <Typography variant={smallFrame ? "caption" : largeFrame ? "h5" : "h6"}>{steps[activeStep].title}</Typography>
                            <Typography sx={{mt:1}} variant={smallFrame ? "caption" : largeFrame ? "h6" : "body1"} align="center">
                                {steps[activeStep].content}
                            </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", alignItems:"center", justifyContent: "flex-end", mr: 2, ml:2 }}>
                        <Box sx={{flexGrow:.2}}>
                        <IconButton
                            size={largeFrame ? "large" : "small"}
                            color="secondary"
                            variant='outlined'
                            disabled={activeStep === 0}
                            onClick={handleBack}>

                           <ArrowBackIos sx={{m:2}}/>
                            
                            </IconButton>
                            
                            </Box>
                            <Box sx={{flexGrow:1}}>
                        <Button size={largeFrame ? "large" : "small"}
                        fullWidth
                            variant="contained" color="primary" onClick={handleSubmit} endIcon={activeStep == steps.length - 1 ? <PlayArrow /> : <ArrowForwardIos />}>
                            {steps[activeStep].action}
                        </Button>
                        </Box>
                    </Box>
                    </Box>
                </Box>
            </Box>
            </Box>
        </ThemeProvider>
    );
};

export default OnboardingModal;


