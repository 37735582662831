import React from 'react';
import { ClosedCaption, Flag, Feedback, Edit, Check, AssistantPhotoTwoTone, FastRewind, Replay, Forward, Forward5, NextPlan, LastPage, ArrowBack, ArrowForward, CheckCircleOutline, Clear, PunchClock, TextFields, SpaceBar, Spellcheck, Timer, Timer10, Info, Start, RateReview, TextFieldsOutlined, ThumbsUpDown, ThumbUp, ArrowRight, PlayArrow, Close, HideImage, HideSource, FirstPage, Restore, Update, Place, EditLocationAlt, EditLocation, VerticalAlignBottom, QuestionAnswer, Rule, ThumbDown, PriorityHigh, Error, SmsFailed, ErrorOutline, ClosedCaptionOutlined, Add, AddCircle, FilterNone, CheckCircle, CheckBox, LocalPolice, MilitaryTech, Verified, Loyalty, Token, Language, School, OpenInFull, Visibility, Pause, EmojiEvents, Person, Backup, FlipToFront, NewReleases, Settings, BarChart, CloudDownload, Logout, TextFormat, Flip, Reply, ArrowDropDown, FullscreenExit, Fullscreen, VolumeUp, VolumeDown, VolumeOff, ArrowForwardIos, QuestionMark, HelpOutline, Translate, CopyAll, InfoOutlined, Hearing } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Avatar, ButtonBase, Checkbox, Divider, Grid, MenuItem, Select, Slider, Skeleton, Typography, Zoom, Menu, Modal, Dialog, Link, Switch, FormControl, InputLabel, ListItemText, OutlinedInput, useMediaQuery } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Badge, Grow, IconButton, Slide, Tooltip, Chip } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { Functions } from './Functions.js';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';
import { VideoSeekSlider } from "react-video-seek-slider";
import "react-video-seek-slider/styles.css";
import "./CaptionFeedback.css";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import moment from 'moment';
import ReactCardFlip from 'react-card-flip';
import { Stage, Layer, Star, Text, Rect } from 'react-konva';
import {
    MediaController,
    MediaControlBar,
    MediaTimeRange,
    MediaTimeDisplay,
    MediaVolumeRange,
    MediaPlayButton,
    MediaSeekBackwardButton,
    MediaSeekForwardButton,
    MediaMuteButton,
    MediaCaptionsButton,
} from 'media-chrome/dist/react';
import "./CaptionFeedbackforIframe.css";
import { GoogleOAuthProvider, GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { gapi, loadGapiInsideDOM, loadAuth2, loadAuth2WithProps, loadClientAuth2 } from 'gapi-script';
import { isExpired, decodeToken } from "react-jwt";
import axios from 'axios';
import OnboardingModal from './OnboardingModal.js';
import { BrowserView, MobileView, isBrowser, isMobile, browserName } from 'react-device-detect';
import { TypeAnimation } from 'react-type-animation';
import config from '../config.js';
import supportedLanguages from './SupportedLanguages.js';
import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import { constants } from 'media-chrome';

const INTERRUPT_TIME = 7000;

const isSafari = browserName === "Safari" || browserName === "Mobile Safari";

const clientId = config.GOOGLE_CLIENT_ID;
const API_KEY = config.CCF_API_KEY;
const socketUrl = config.CCF_SOCKET_URL;

const themeOptions = {
    palette: {
        // mode: 'dark',
        primary: {
            main: '#edf9f9',
        },
        secondary: {
            main: '#ffbd58',
        },
        error: {
            main: '#b70000',
        },

    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        body1: {
            color: "white",
            fontSize: '.9rem',
        },
        h6: {
            color: "white",
            fontSize: '1.1rem'
        },
        h5: {
            color: "white",
            fontSize: '1.5rem'
        },
        caption: {
            color: "white",
        },
        body2: {
            color: "white",
        },
        subtitle1: {
            color: "white",
        },
        subtitle2: {
            color: "white",
        },
        h2: {
            color: "white",
            fontWeight: '400',
            fontSize: '1rem',
            '@media (min-width:750px)': {
                fontSize: '1rem',
            }
        }
    },
    contrastThreshold: 4.5,
    contrastText: '#fff',
    shape: {
        pillRadius: 50,
        borderRadius: 5,
    }
};

const theme = responsiveFontSizes(createTheme(themeOptions));

const Button = styled(MuiButton)(({ theme, highlight }) => ({
    borderRadius: theme.shape.pillRadius,
    fontWeight: 'bold',
    fontFamily: 'Inter, sans-serif',
    letterSpacing: -1,
    border: highlight ? '4px solid #ffbd58' : "",
    color: highlight ? '#ffbd58' : "",

}));

export function FlagTypeLabel(type, confirm = true) {
    return confirm ? flagTypes.filter(flagType => flagType.type == type)[0].label : flagTypes.filter(flagType => flagType.type == type)[0].confirmation;
}

export const flagTypes = [
    { type: "text", icon: <PriorityHigh />, color: "error", label: "MISSPELLING(S)", confirmation: "CORRECT SPELLING" },
    { type: "name", icon: <TextFieldsOutlined />, color: "info", label: "WRONG NAME(S)", confirmation: "CORRECT NAMES" },
    { type: "missing", icon: <SpaceBar />, color: "primary", label: "MISSING WORD(S)", confirmation: "NO MISSING WORDS" },
    { type: "punctuation", icon: <Feedback />, color: "secondary", label: "BAD PUNCTUATION", confirmation: "CORRECT PUNCTUATION" },
    // { type: "timing", icon: <Timer />, color: "error", label: "WRONG TIMING", confirmation: "CORRECT TIMING" },
]


export function findDiffs(str1, str2) {

    let diffs = [];
    str2.split(' ').forEach((val, i) => {

        if (val != str1.split(" ")[i]) {
            diffs.push({ original: str1.split(" ")[i], change: val });
        }
        else {
            diffs.push({ original: val, change: "" });
        }

    });
    // console.log(diffs);
    return diffs;
}


export function CaptionFeedbackProvider(props) {

    const { player, textTracks, setTextTracks, cue, duration, textIndex, currentTime, activeCueIndex, ccfId, language, setLanguage, setActiveText, setUpdatedCue, entities } = props;
    const [feedbackItems, setFeedbackItems] = React.useState([]);
    const [cueItems, setCueItems] = React.useState([]);
    const [userItems, setUserItems] = React.useState([]);
    const [openFlags, setOpenFlags] = React.useState([]);
    const [itemsToVoteOn, setItemsToVoteOn] = React.useState([]);
    const [hasItemsToVoteOn, setHasItemsToVoteOn] = React.useState(false);
    const [upVotedItems, setUpVotedItems] = React.useState([]);
    const [downVotedItems, setDownVotedItems] = React.useState([]);
    const [acceptedItems, setAcceptedItems] = React.useState([]);
    const demo = false;
    const [hidePanel, setHidePanel] = React.useState(false);
    const [isItemOwner, setIsItemOwner] = React.useState(false);
    const [hasVoted, setHasVoted] = React.useState(false);
    const [hasVotedUp, setHasVotedUp] = React.useState(false);
    const [hasVotedDown, setHasVotedDown] = React.useState(false);
    const [feedbackMode, setFeedbackMode] = React.useState(true);
    const [viewerId, setViewerId] = React.useState(null);
    const [viewer, setViewer] = React.useState(null);
    const [userStats, setUserStats] = React.useState(null);
    const [signedIn, setSignedIn] = React.useState(Functions.auth.getCookie("id_token"));
    const [userInfo, setUserInfo] = React.useState(signedIn ? decodeToken(Functions.auth.getCookie("id_token")) : null);
    const [showOnboarding, openOnboarding] = React.useState(!(Functions.auth.getCookie("onboarded") === "true") || (userInfo && userInfo.onboarded));
    const [replay, setReplay] = React.useState(false);
    const [takingAction, setToActionSide] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [currentCue, setCurrentCue] = React.useState(null);
    const [interrupt, setInterrupt] = React.useState(false); 

    React.useEffect(() => {

        async function fetchData() {
            if (signedIn) {
                let viewer = await loadViewer();
                if (!language){
                setLanguage(viewer.language ? viewer.language.preferred : "en");
                }
            }
            else{
                setLanguage("en");
            }
        }

        fetchData();

    }, [signedIn]);

    async function loadViewer() {
        let viewer = await Functions.user.getUserByEmail(userInfo.name, userInfo.email, "Google");
        setViewerId(viewer.uuid);
        setViewer(viewer);
        openOnboarding(userInfo.onboarded);

        console.log("entities", entities);

        return viewer;
    }


    React.useEffect(() => {
        if (viewer && language) {

            loadFeedbackSession(viewer, language);
        }
    }, [viewer, textTracks, language]);


    const loadFeedbackSession = React.useCallback((viewer, language) => {

        async function fetchData(viewer) {
            console.log("Fetching FLAGS");
            const items = await Functions.item.getFeedbackItems(ccfId, language, viewer.language ? viewer.language.known : []);
            setFeedbackItems(items);


            if (!items || items.length == 0) {


                if (entities) {
                    for (var key in Object.keys(entities)) {
                        if (entities[Object.keys(entities)[key]][0].type == "PERSON" || entities[Object.keys(entities)[key]][0].type == "OTHER") {
                        await Functions.item.createFlag((parseInt(Object.keys(entities)[key])+1).toString(), null, ccfId, language, "text");
                        }
                    }
                }



            }


            if (viewer != null) {
                let stats = await Functions.user.getUserStats(viewer.uuid);
                setUserStats(stats);
            }

        }

        fetchData(viewer)
            .catch(console.error);
    }, []);

    const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl,
        {
            queryParams: { "x-api-key": API_KEY, "fuuid": ccfId },
            onOpen: () => console.log('opened'),
            shouldReconnect: (closeEvent) => true,
            reconnectAttempts: 5,
        });

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    React.useEffect(() => {
        console.log("Connection Status: " + connectionStatus);
    }, [connectionStatus]);

    React.useEffect(() => {

        const fetchData = async () => {
            if (lastMessage !== null) {
                let event = JSON.parse(lastMessage.data);
                console.log("CaptionFeedBack - Feedback Item Updated: " + event.uuid)
                if (event.fuuid == ccfId) {
                    let items = {};
                    let newItem = await Functions.data.getFeedbackItem(ccfId, event.iuuid);
                    if (feedbackItems.filter(item => item.iuuid == newItem.iuuid).length == 0) {
                        setFeedbackItems((prevState) => ([...prevState, newItem]));
                    }
                    else {
                        setFeedbackItems((prevState) => ([...prevState.filter(item => item.uuid != newItem.uuid), newItem]));
                    }
                }
            }
                setToActionSide(false);
                setSubmitting(false);
        }
        // call the function
        fetchData().catch(console.error);

    }, [lastMessage]);


    React.useEffect(() => {

                       
           
         if(!interrupt){

            if (cue) {
                setCurrentCue(cue);
                setToActionSide(false);
                let items = feedbackItems.filter(item => item.key == (activeCueIndex + 1).toString());
                console.log(items);
                setCueItems(items);
            }
            else{
                setCurrentCue(null);
                setCueItems([]);
    
            }
            }
        

    }, [cue, feedbackItems, viewerId]);


    React.useEffect(() => {

        let userItems = cueItems.filter(item => item.ouuid == viewerId && item.language == language);
        setUserItems(userItems);
        let isItemOwner = userItems.length > 0;
        setIsItemOwner(isItemOwner);
        let upVotedItems = cueItems.filter(item => item.votes.filter(vote => (vote.ouuid == viewerId && vote.type == "up")).length > 0);
        setUpVotedItems(upVotedItems);
        let downVotedItems = cueItems.filter(item => item.votes.filter(vote => (vote.ouuid == viewerId && vote.type == "down")).length > 0);
        setDownVotedItems(downVotedItems);
        setHasVotedUp(upVotedItems.length > 0);
        setHasVotedDown(downVotedItems.length > 0);
        setHasVoted(upVotedItems.length > 0 || downVotedItems.length > 0);
        let openItems = cueItems.filter(item => (item.status == "open" && item.ouuid != viewerId && item.votes.filter(vote => vote.ouuid == viewerId).length == 0)).filter(item => item.change && item.change.text != "");
        let openFlags = cueItems.filter(item => (item.status == "open" && item.ouuid != viewerId && item.votes.filter(vote => vote.ouuid == viewerId).length == 0)).filter(item => item.type == "flag");
        setOpenFlags(openFlags);
        setItemsToVoteOn([...openItems,...openFlags]);
        setHasItemsToVoteOn(openItems.length > 0 || openFlags.length > 0);

        console.log("items to vote on", itemsToVoteOn);
        console.log("hasitems to vote on", hasItemsToVoteOn);

        if (isItemOwner && language == userItems[0].language){
            setActiveText(userItems[0].change.text);
            setUpdatedCue(true);
        }

        //merge accepted items with text track
        MergeAccepted();


        if(props.playing && (openItems.length > 0 || openFlags.length > 0) && viewer && viewer.settings && viewer.settings.allowPopUp){
                
            setInterrupt(true);
                setTimeout(() => {
                   setInterrupt(false);
                }, INTERRUPT_TIME);
            }
    

    }, [cueItems]);

    const MergeAccepted = () => {

        let acceptedItems = cueItems.filter(item => item.status == "accepted" && item.change && item.change.text != "");
        setAcceptedItems(cueItems.filter(item => item.status == "accepted"));

        for (let i = 0; i < acceptedItems.length; i++) {
            let item = acceptedItems[i];
            let tracks = [...textTracks];
            tracks[parseInt(item.key) - 1].text = item.change.text;
            console.log(item);
            setTextTracks(tracks);
        }
    }


    const handleCreateFlag = async (type) => {
        let iuuid = await Functions.item.createFlag((activeCueIndex + 1).toString(), viewerId, ccfId, type);
        return iuuid;
    }

    const handleAddChange = (item, text) => {
        Functions.item.addChange(item, viewerId, ccfId, text);
    }

    const handleCreateChange = (type, text) => {
        Functions.item.createChange((activeCueIndex + 1).toString(), viewerId, ccfId, type, text, props.language);
    }

    const handleUp = (item) => {
        Functions.vote.upVote(item, viewerId, ccfId);
    }

    const handleDown = (item) => {
        Functions.vote.downVote(item, viewerId, ccfId);
    }


    const pause = () => {
        player.pauseVideo();
    }

    const play = () => {
        player.playVideo();

    }

    const handleSeek = (event, newValue) => {
        // player.current.currentTime = newValue + .1;
        props.seekTo(newValue + .1);
    };

    const seekToCue = (cue) => {
        // player.current.pause();
        player.pauseVideo();
        handleSeek(null, cue.start + .1);
        console.log("seeking to cue", cue.start + .1);

        // player.current.currentTime = cue.startTime + .1;
    }

    const seekToLastCue = (cue) => {
        console.log(cue);
        player.pauseVideo();
        // let time = currentTime.toFixed(1).toString();
        let index = parseInt(textIndex[cue.start.toFixed(1).toString()])-1;
        console.log(index);
        if (index < textTracks.length - 1) {
            let newCue = textTracks[index - 1];
            console.log(newCue);
            seekToCue(newCue);
        }
        setInterrupt(false);
    }

    const seekToNextCue = (cue) => {
        player.pauseVideo();
        // let time = currentTime.toFixed(1).toString();
        let index = parseInt(textIndex[cue.start.toFixed(1).toString()])-1;
        if (index < textTracks.length - 1) {
            let newCue = textTracks[index + 1];
            seekToCue(newCue);
        }
        setInterrupt(false);

    }

    const playCue = (cue) => {

        handleSeek(null, cue.start);

        let duration = cue.duration;

        let id = textTracks.indexOf(cue);

        let nextCue = id < textTracks.length - 1 ? textTracks[id + 1] : null;
        if (nextCue) {
            duration = duration >= (nextCue.start - cue.start) ? (nextCue.start - cue.start) : duration;
        }

        play();

        setTimeout(() => {
            pause();
        }, ((duration * 1000) - 100));




    }

    console.log("Caption Feedback Data Provider Refresh");


    return (

        React.cloneElement(<CaptionFeedbackAuth />,
            {
                ...props,
                currentTime,
                replay,
                signedIn,
                setSignedIn,
                userInfo,
                setUserInfo,
                player,
                viewerId,
                setViewerId,
                ccfId,
                textTracks,
                duration,
                feedbackItems,
                handleSeek,
                playCue,
                seekToCue,
                userStats,
                loadFeedbackSession,
                seekToLastCue,
                seekToNextCue,
                currentCue,
                activeCueIndex,
                cueItems,
                // hasActiveCue,
                handleCreateFlag,
                handleAddChange,
                handleCreateChange,
                handleUp,
                handleDown,
                isItemOwner,
                hasVoted,
                hasVotedUp,
                hasVotedDown,
                upVotedItems,
                downVotedItems,
                userItems,
                itemsToVoteOn,
                demo,
                userItems,
                play,
                pause,
                findDiffs,
                feedbackMode,
                setFeedbackMode,
                hasItemsToVoteOn,
                openFlags,
                hidePanel,
                setHidePanel,
                showOnboarding,
                openOnboarding,
                acceptedItems,
                language,
                viewer,
                setViewer,
                loadViewer,
                takingAction,
                setToActionSide,
                submitting,
                setSubmitting,
                interrupt,
                setInterrupt
            })
    )
}

export function CaptionFeedbackAuth(props) {

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <CaptionFeedback {...props} />
        </GoogleOAuthProvider>
    )
}


export function CaptionFeedback(props) {

    const { player, feedbackMode, setViewerId, viewerId, signedIn, userInfo, setUserInfo, setSignedIn, showOnboarding, openOnboarding, setViewer } = props;

    const googleLogin = useGoogleLogin({
        onSuccess: async ({ code }) => {
            const tokens = await axios.post(config.GOOGLE_AUTH_URL, {
                code,
            });

            console.log(tokens);
            let userInfo = decodeToken(tokens.data.id_token);
            console.log(userInfo);
            setCookie(tokens.data);
            setUserInfo(userInfo);
            setSignedIn(true);
            openOnboarding(userInfo.onboarded);
            let viewer = await Functions.user.getUserByEmail(userInfo.name, userInfo.email, "Google");
            setViewerId(viewer.uuid);
            setViewer(viewer);

            let headers = new Headers();
            headers.append("Authorization", "Bearer " + tokens.data.access_token);
            headers.append("Content-Type", "application/json");

            let requestOptions = {
                method: 'GET',
                redirect: 'follow',
                headers: headers
            };

            const response = await fetch("https://youtube.googleapis.com/youtube/v3/channels?part=snippet,contentDetails&mine=true", requestOptions);
            const result = await response.json();
            const channels = result.items ? result.items : [];
            console.log(result);
            console.log(channels);
            if (channels.length > 0) {
                let channelIds = channels.map(channel => channel.id);
                let ids = channelIds.join(",");
                document.cookie = "viewerChannels=" + ids + "; path=/";
            }


        },
        flow: 'auth-code',
        // scope: "https://www.googleapis.com/auth/youtube.readonly"
    });

    const signOut = () => {
        googleLogout();
        deleteUserCookie();
        setUserInfo(null);
        setSignedIn(false);
        setViewerId(null);
    }

    return (


        (player) ?
            <ThemeProvider theme={theme}>
                {(feedbackMode) ?
                    <FeedbackManager player={player} feedbackMode={feedbackMode} {...props} viewerId={viewerId} setViewerId={setViewerId} signedIn={signedIn} userInfo={userInfo} googleLogin={googleLogin} signOut={signOut} />
                    : null}



                {showOnboarding ?
                    <OnboardingModal showOnboarding={showOnboarding} openOnboarding={openOnboarding} {...props} /> : <CaptionFeedBackSliderControlBar {...props} signedIn={signedIn} userInfo={userInfo} viewerId={viewerId} setViewerId={setViewerId} googleLogin={googleLogin} signOut={signOut} />}

            </ThemeProvider>
            : null



    );
}


function FeedbackManager(props) {

    const smallFrame = props.width < 650;
    const { currentCue } = props;

    return (
        <Box sx={{ display: "flex", height: "100%", width: "100%", flexDirection: "row", justifyContent: "flex-end", pt:2}}>

            {
            
            props.hidePanel ?

                    <Grow in mountOnEnter unmountOnExit>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexGrow: 1, mt: 5, opacity: !props.playing ? 1 : 0 }} onClick={props.pause} >
                            <HiddenCard {...props} />
                        </Box>
                    </Grow>

                    :
            
            (props.currentCue) ?

            <>
  

                <Grow timeout={{ enter: 250, exit: 0 }} in={(props.isHovered && props.playing) || (props.playing && props.interrupt && props.viewer && props.viewer.settings && props.viewer.settings.allowPopUp)} mountOnEnter unmountOnExit>
                   <Box>
                            <DubseePanel {...props} cue={currentCue}/>
                            </Box>
                   
                </Grow>
               
                <Grow 
                // appear={!props.isHovered} 
                timeout={{ enter: 1000, exit: 0 }} in={!props.interrupt && !props.playing} mountOnEnter unmountOnExit >
                 
                    <Box>
                                <DubseePanel {...props} cue={currentCue}/>
                                </Box>
                        </Grow>

                

                        </>

                        : null}

        </Box>

    );

}

function setCookie(data) {

    const d = new Date();
    d.setTime(data.expiry_date);
    let expires = "expires=" + d.toUTCString();

    document.cookie = "access_token=" + data.access_token.toString() + "; expires=" + expires + "; path=/";
    document.cookie = "refresh_token=" + data.refresh_token.toString() + "; path=/";
    document.cookie = "id_token=" + data.id_token.toString() + "; path=/";
}

function deleteUserCookie() {
    document.cookie = "id_token=" + "" + "; path=/";
    document.cookie = "refresh_token=" + "" + "; path=/";
    document.cookie = "access_token=" + "" + "; expires=" + "" + "; path=/";
    document.cookie = "viewerChannels=" + "" + "; path=/";
}


export function ViewerProfile(props) {

    const { width, height, userInfo, userStats } = props;

    const smallFrame = width < 700;
    const largeFrame = width > 1200;

    const [loading, setLoading] = React.useState(true);
    const [languageSettings, showLanguageSettings] = React.useState(false);
    const [viewerSettings, showViewerSettings] = React.useState(false);
    const [download, showDownloads] = React.useState(false);
    const [stats, showStats] = React.useState(false);
    const [viewerBadges, showBadges] = React.useState([]);
    const [panel, setPanel] = React.useState(0);

    React.useEffect(() => {
        if (panel == 0) {
            showLanguageSettings(false);
            showBadges(true);
            showViewerSettings(false);
            showStats(false);
        }
        else if (panel == 1) {
            showLanguageSettings(true);
            showBadges(false);
            showViewerSettings(false);
            showStats(false);
        }
        else if (panel == 2) {
            showLanguageSettings(false);
            showBadges(false);
            showViewerSettings(true);
            showStats(false);
        }
        else if (panel == 3) {
            showLanguageSettings(false);
            showBadges(false);
            showViewerSettings(false);
            showStats(true);
        }
    }, [panel]);

    console.log("userStats", userStats);

    const badges = userStats ? [
        { name: "First Time Fixer", category: "change", conditionThreshold: 1, conditionCurrent: (userStats.flags.open), description: "You updated your first caption!", icon: <NewReleases color="secondary" /> },
        { name: "Verified Voter", category: "vote", conditionThreshold: 1, conditionCurrent: (userStats.votes.up + userStats.votes.down), description: "You voted for the first time!", icon: <ThumbsUpDown color="primary" /> },
        { name: "Watchful Eye", category: "change", description: "You found 10 corrections!", icon: <LocalPolice color="error" />, conditionThreshold: 10, conditionCurrent: userStats.flags.open },
        { name: "Pro Poller", category: "vote", conditionThreshold: 10, conditionCurrent: (userStats.votes.up + userStats.votes.down), description: "You voted on 10 videos!", icon: <Verified color="success" /> },
        { name: "All-Star", category: "vote", description: "You voted on 1000 videos!", conditionThreshold: 1000, conditionCurrent: (userStats.votes.up + userStats.votes.down), icon: <School color="secondary" /> },
    ] : [];

    const badgesEarned = badges.filter(badge => badge.conditionCurrent >= badge.conditionThreshold);
    const nextBadge = badges.filter(badge => !(badge.conditionCurrent >= badge.conditionThreshold))[0];

    return (

        <Box >

            <Box className="barlessScroll" sx={{ display: "flex", flexDirection: "row", alignItems: "center", m: smallFrame ? 0 : 1, overflowX: "scroll", overflowY: "hidden", justifyContent: "flex-start" }}>

                <Avatar sx={{ height: smallFrame ? "40px" : "50px", width: smallFrame ? "40px" : "50px", m: 1 }} src={userInfo.picture} />

                <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "flex-start", m: 1 }}>

                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                        <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h6" : "h6"}>{userInfo.name} </Typography>


                    </Box>

                    <Typography variant={smallFrame ? "caption" : largeFrame ? "body1" : "body2"}>{userInfo.email}</Typography>

                </Box>




                <Box>
                    <IconButton onClick={() => setPanel(0)}><EmojiEvents sx={{ fill: panel == 0 ? "#ffbd58" : "#ffffff" }} /></IconButton>

                    <IconButton onClick={() => setPanel(3)}><BarChart sx={{ fill: panel == 3 ? "#ffbd58" : "#ffffff" }} /></IconButton>

                    <IconButton onClick={() => setPanel(1)}><Language sx={{ fill: panel == 1 ? "#ffbd58" : "#ffffff" }} /></IconButton>

                    <IconButton onClick={() => setPanel(2)}><Settings sx={{ fill: panel == 2 ? "#ffbd58" : "#ffffff" }} /></IconButton>
                </Box>

            </Box>

            {
                viewerSettings ?
                    <>
                        <ViewerSettings {...props} userInfo={userInfo} />

                        <Box sx={{ m: 1 }}>
                            <Button size="large" variant="contained" fullWidth color="error" onClick={() => { props.showViewerProfile(false); props.handleSignOut(); }}>Sign Out</Button>
                        </Box>

                    </>

                    :

                    languageSettings ?

                        <>

                            <ViewerLanguages {...props} />

                        </>


                        :

                        stats ?

                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", m:2 }}>

                                <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                                    <Box>
                                        <Typography variant="h6" align="center">{userStats.votes.up + userStats.votes.down}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption" align="center">votes</Typography>
                                    </Box>
                                </Box>

                                <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>

                                    <Box>
                                        <Typography variant="h6" align="center">{userStats.flags.open + userStats.flags.accepted + userStats.flags.rejected}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption" align="center">changes</Typography>
                                    </Box>
                                </Box>

                                <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                                    <Box>
                                        <Typography variant="h6" align="center">{userStats.changes.accepted}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption" align="center">accepted</Typography>
                                    </Box>


                                </Box>




                            </Box> :

                                viewerBadges ?

                                <>
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>

                                        {badges.map((badge, index) =>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", m: smallFrame ? 0 : 1, opacity: (badge.conditionCurrent >= badge.conditionThreshold) ? 1 : .4 }}>
                                                <Box>
                                                    <Tooltip title={badge.name + ": " + badge.description} placement="top" arrow>
                                                        {badge.icon}
                                                    </Tooltip>
                                                </Box>
                                                {smallFrame ? null :
                                                    <Box>
                                                        <Typography variant={smallFrame ? "caption" : "subtitle1"}> {badge.name}</Typography>
                                                    </Box>}
                                            </Box>

                                        )}

                                    </Box>

                                    {nextBadge ?
                                        <>
                                            <Box sx={{ ml: 1, mr: 1, mt: 1, mb: .5, display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-strech" }}>


                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography style={{ textTransform: "uppercase" }} variant="caption">{nextBadge.conditionCurrent} / {nextBadge.conditionThreshold} {nextBadge.category}S
                                                    </Typography>&nbsp;
                                                </Box>

                                                <Box sx={{ flexGrow: 1 }} />

                                                <Typography style={{ textTransform: "uppercase" }} variant="caption">{nextBadge.conditionThreshold - nextBadge.conditionCurrent} More {nextBadge.category}{(nextBadge.conditionThreshold - nextBadge.conditionCurrent) == 1 ? "" : "s"} to {nextBadge.name} </Typography>

                                            </Box>

                                            <LinearProgress sx={{ height: "8px" }} color="secondary" variant="determinate" value={nextBadge.conditionCurrent / nextBadge.conditionThreshold * 100} />

                                        </> : null}

                                </> : null}

            {/* </Card> */}

        </Box>

    );
}

export function ViewerSettings(props) {

    const { viewer, loadViewer, userInfo } = props;

    const smallFrame = useMediaQuery('(max-width:600px)');

    console.log(viewer.settings);

    const [allowPopUp, setAllowPopUp] = React.useState(viewer.settings ? viewer.settings.allowPopUp : false);
    const [allowPause, setAllowPause] = React.useState(viewer.settings ? viewer.settings.allowPause : false);

    const viewerSettings = viewer.settings ? viewer.settings : { allowPopUp: false, allowPause: false };

    const handleUpdateViewer = async (keyValue) => {

        await Functions.user.updateUserSettings(props.viewerId, { ...viewerSettings, ...keyValue });
        loadViewer();

    }



    return (
        <Box sx={{ height: smallFrame ? 70 : "100%", overflowY: smallFrame ? "scroll" : "hidden" }}>
            <Box sx={{ m: 1, display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
                <Box>
                    <Typography variant="h6"> <Typography variant={"h6"} letterSpacing={-1}
                        fontWeight="600" fontFamily="Inter, sans-serif" component={"span"}
                        color="#ffbd58">dubsee</Typography> while video is playing</Typography>
                    <Typography variant="caption">dubsee will occasionally pop up to confirm caption accuracy</Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="body1">{allowPopUp ? "ON" : "OFF"}</Typography>
                <Switch color="success" checked={allowPopUp} onChange={(event) => { setAllowPopUp(!allowPopUp); handleUpdateViewer({ allowPopUp: !allowPopUp }); }} />


            </Box>

            <Box sx={{ m: 1, display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
                <Box>
                    <Typography variant="h6">allow <Typography variant={"h6"} letterSpacing={-1}
                        fontWeight="600" fontFamily="Inter, sans-serif" component={"span"}
                        color="#ffbd58">dubsee</Typography> to pause the video</Typography>
                    <Typography variant="caption">dubsee will occasionally pause the video to confirm caption accuracy</Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="body1">{allowPause ? "ON" : "OFF"}</Typography>
                <Switch color="success" checked={allowPause} onChange={(event) => { setAllowPause(!allowPause); handleUpdateViewer({ allowPause: !allowPause }); }} />


            </Box>

        </Box>
    )
}

export function ViewerLanguages(props) {

    const { viewer, loadViewer } = props;

    const smallFrame = useMediaQuery('(max-width:600px)');

    const knownLanguages = viewer.language ? viewer.language.known ? viewer.language.known : [] : [];
    const [preferredLanguage, setPreffered] = React.useState(viewer.language ? viewer.language.preferred : "en");
    const [langs, setLangs] = React.useState(knownLanguages);


    const handleLanguageUpdate = async (preferred, known) => {
        console.log(preferred, known);


        if (known.includes(preferred)) {
            //remove preferred from known
            known = known.filter((language) => language != preferred);
            //put preferred at the beggining of the array
            known = [preferred, ...known];
        }
        else {
            known = [preferred, ...known];
        }

        if (!known || known.length == 0) {
            known = [preferred];
        }
        setPreffered(preferred);
        setLangs(known);
        await Functions.user.updateUserLanguages(props.viewerId, preferred, known);
        loadViewer();


    }

    return (
        <Box sx={{ height: smallFrame ? 70 : "100%", overflowY: smallFrame ? "scroll" : "hidden", m: 1 }}>

            <Box sx={{ m: 1, display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>

                <Box>
                    <Typography variant="h6">Preferred Language</Typography>
                    <Typography variant="caption">your default caption language for watching videos</Typography>
                </Box>

                <Box sx={{ flexGrow: 1 }} />
                <Select MenuProps={MenuProps} autoWidth SelectDisplayProps={{ style: { color: "white", border: "2px solid white", background: "rgba(0,0,0,.8)" } }} value={preferredLanguage} onChange={async (event) => handleLanguageUpdate(event.target.value, knownLanguages)}>
                    {Object.keys(supportedLanguages).map((key) =>
                        <MenuItem value={supportedLanguages[key].code}>{supportedLanguages[key].flag} {supportedLanguages[key].label}</MenuItem>
                    )}
                </Select>

            </Box>

            <Box sx={{ m: 1, display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
                <Box>
                    <Typography variant="h6">Languages You Know</Typography>
                    <Typography variant="caption">the languages you know + are willing to translate to & from</Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} />

                <MultipleLangSelect langs={langs} setLangs={setLangs} viewer={viewer} handleLanguageUpdate={handleLanguageUpdate} />


            </Box>
        </Box>
    )
}

export function MultipleLangSelect(props) {

    const { langs, setLangs, viewer, handleLanguageUpdate } = props;

    const handleChange = (event: SelectChangeEvent<typeof lang>) => {
        const {
            target: { value },
        } = event;
        handleLanguageUpdate(
            viewer.language.preferred ? viewer.language.preferred : "en",
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    return (
        <FormControl>
            {/* <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel> */}
            <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={langs}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps} autoWidth SelectDisplayProps={{ style: { color: "white", border: "2px solid white", background: "rgba(0,0,0,.8)" } }}
            >
                {Object.keys(supportedLanguages).map((key) => (
                    <MenuItem key={supportedLanguages[key].code} value={supportedLanguages[key].code}>
                        <Checkbox checked={langs.indexOf(supportedLanguages[key].code) > -1} />
                        <ListItemText primary={`${supportedLanguages[key].flag}  ${supportedLanguages[key].label}`} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export function DownloadPanel(props) {

    const { width, height, userInfo, videoData, viewerId, viewer } = props;

    const smallFrame = width < 700;
    const largeFrame = width > 1200;

    let channelIds = Functions.auth.getCookie("viewerChannels").split(",");
    let matches = channelIds.filter(id => id == videoData.channelId);
    let ownsChannel = matches.length > 0;

    const [exportEnabled, setExportEnabled] = React.useState(ownsChannel);

    const [subscriptionEnabled, setSubscriptionEnabled] = React.useState(userInfo ? userInfo.settings ? userInfo.settings.subscriptions ? userInfo.settings.subscriptions.includes(videoData.channelId) : false : false : false);

    const [emailModalOpen, setEmailModalOpen] = React.useState(false);

    const fileTypes = [
        { name: ".VTT", type: "vtt" },
        { name: ".SRT", type: "srt" },
        { name: ".TXT", type: "txt" }
    ];

    const [fileType, setFileType] = React.useState(fileTypes[0].type);

    console.log(videoData);

    const defaultLanguage = viewer.language ? Object.keys(supportedLanguages).map((key) => supportedLanguages[key]).find(lang => lang.code == viewer.language.preferred) :

        videoData.defaultAudioLanguage ? Object.keys(supportedLanguages).map((key) => supportedLanguages[key]).find(lang => lang.code == videoData.defaultAudioLanguage) : supportedLanguages["1"];

    const [language, setLanguage] = React.useState(defaultLanguage);

    const handleExport = async () => {
        let fileUrl = await Functions.data.exportCaptions(props.YouTubeVideoId, fileType, language.code);
        console.log(fileUrl);
        window.open(fileUrl);
    }

    const handleSubscribe = () => {
        setEmailModalOpen(true);
    }

    console.log(userInfo);

    return (

        <Box >
            <Box sx={{ ml: 1, display: "flex", alignItems: "center" }}>
                <Info sx={{ color: "white", width: "20px", height: "20px", mr: 1 }} />
                <Typography variant={smallFrame ? "caption" : "subtitle1"}>Owners/Admins of <i>{videoData.channelTitle}</i> can export the latest captions.</Typography>
            </Box>

            {smallFrame ?

                <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
                    <Info sx={{ color: "white", width: "20px", height: "20px", mr: 1 }} />
                    <Typography variant={smallFrame ? "caption" : "subtitle1"}>Open on tablet or desktop to export.</Typography>
                </Box>

                :

                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mr: 1, ml: 1, opacity: exportEnabled ? 1 : .5 }}>


                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", flexGrow: 1 }}>

                        {/* <Box sx={{width: smallFrame ? width * .2 : width * .3, height: "auto", overflow: "hidden" }} >
                                <img
                                    src={videoData.thumbnails ? videoData.thumbnails.high ? videoData.thumbnails.high.url : videoData.thumbnails.default.url : null}
                                    style={{ width: "100%", height: "auto", marginBottom: "-11%", marginTop: "-11%", borderRadius: "5px" }}
                                />
                                  </Box> */}

                        <Box sx={{ m: 2, width: "100%", height: "auto", display: "flex", flexDirection: "column", alignItems: "flex-start", flexGrow: 1, backdropFilter: "blur(2px)" }}>
                            <Box sx={{ display: "flex", background: "rgba(0,0,0,.6)", width: "60%", overflow: "hidden", textOverflow: "ellipsis" }}>
                                <Typography noWrap variant={smallFrame ? "caption" : largeFrame ? "h5" : "body1"} >{videoData.title}
                                    <Typography noWrap variant={smallFrame ? "caption" : largeFrame ? "h6" : "body2"}>{videoData.channelTitle}</Typography></Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>

                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography align="left" variant={smallFrame ? "caption" : largeFrame ? "h6" : "body1"}>File Type:</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", ml: 1 }}>
                                        <Select size="small" MenuProps={MenuProps} autoWidth SelectDisplayProps={{ style: { color: "white", border: "2px solid white", background: "rgba(0,0,0,.8)" } }} disabled={!exportEnabled} value={fileType} onChange={(e) => setFileType(e.target.value)} sx={{ m: 1 }}>
                                            {fileTypes.map((type) => (
                                                <MenuItem value={type.type} style={{ background: "transparent", color: "#ffffff" }}>{type.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                </Box>

                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                    <Box sx={{ display: "flex", }}>
                                        <Typography align="left" variant={smallFrame ? "caption" : largeFrame ? "h6" : "body1"}>Language: </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", }}>
                                        <Select size="small" MenuProps={MenuProps} autoWidth SelectDisplayProps={{ style: { color: "white", border: "2px solid white", background: "rgba(0,0,0,.8)" } }} disabled={!exportEnabled} value={language} onChange={(e) => setLanguage(e.target.value)} sx={{ m: 1 }}>
                                            {Object.keys(supportedLanguages).map(
                                                (key) =>
                                                    <MenuItem value={supportedLanguages[key]} style={{ background: "transparent", color: "#ffffff" }}>{supportedLanguages[key].label} ({supportedLanguages[key].code})</MenuItem>
                                            )}
                                        </Select>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }

            <Box sx={{ m: 1 }}>
                <Button size={smallFrame ? "small" : "large"} endIcon={<CloudDownload />} variant="contained" color="secondary" fullWidth onClick={() => exportEnabled ? ((language == defaultLanguage) || subscriptionEnabled) ? handleExport(fileType) : handleSubscribe() : null}>
                    {exportEnabled ? ((language == defaultLanguage) || subscriptionEnabled) ?

                        `Download ${fileType} in ${language.label}`

                        : `Upgrade to export in ${language.label}`

                        : "Export for Channel Owners/Admins Only"}
                </Button>
            </Box>

            <EmailModal open={emailModalOpen} handleClose={() => setEmailModalOpen(false)}
                messageInfo={{
                    "Channel": videoData.channelTitle,
                    "Channel ID": videoData.channelId,
                    "Owner": userInfo.email,
                    "Owner Viewer ID": viewerId
                }} />

        </Box>

    )
}

const EmailModal = ({ open, handleClose, messageInfo }) => {


    const [copied, setCopied] = React.useState(false);


    const handleCopyMessage = () => {
        setCopied(true);
        navigator.clipboard.writeText(
            `Upgrade to dubsee global: \n Channel: ${messageInfo["Channel"]}\nChannel ID: ${messageInfo["Channel ID"]}\nOwner: ${messageInfo["Owner"]}\nOwner ID: ${messageInfo["Owner ID"]}`);
        setTimeout(() => {
            setCopied(false);
        }
            , 3000);
    };




    const body = (
        <Box sx={{ background: "(25,25,25,.8)", p: 4, display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Typography variant="h5" id="email-modal-title">
                Upgrade to <Typography variant="h5" letterSpacing={-1}
                    fontWeight="600" component="span" fontFamily="Inter, sans-serif"
                    color="#ffbd58">dubsee</Typography> global
            </Typography><br />
            <Typography variant="h6" id="email-modal-title">
                Send us a message at  <Link href="mailto:team@dubsee.cc">
                    team@dubsee.cc</Link> to upgrade.
            </Typography>
            <Typography align="center" variant="body1" id="email-modal-description">
                Just copy the details below and paste them in the email.
            </Typography>
            <Box sx={{ m: 2 }}>
                <Typography variant="body1" id="email-modal-description">
                    Upgrade me to dubsee global:
                </Typography>
                {Object.keys(messageInfo).map((key) =>
                    <Typography variant="body1" id="email-modal-description">
                        {key}: {messageInfo[key]}
                    </Typography>
                )}
            </Box>
            <Button variant="contained" endIcon={copied ? <CheckCircle /> : <CopyAll />} color={copied ? "success" : "primary"} onClick={handleCopyMessage}>
                {copied ? "Copied!" : "Copy"}
            </Button>
        </Box>

    );

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="email-modal-title"
                aria-describedby="email-modal-description"
                PaperProps={{
                    style: {
                        background: "rgba(25,25,25,.8)",
                        color: "white",
                    },
                }}
            >
                {body}
            </Dialog>
        </ThemeProvider>
    );
};

export default EmailModal;

export const CaptionFeedbackButton = (props) => {

    const { height, setFeedbackMode } = props;

    return (

        <ButtonBase
            sx={{
                background: "rgba(20,20,20, 0.7)", height: height, width: height * 3, display: "flex", alignItems: "center", justifyContent: "center"
                , borderBottom: props.feedbackMode ? "2px solid white" : ""
            }}


            onClick={() => {
                props.setFeedbackMode(!props.feedbackMode);
            }
            }>


            <img style={{ height: height * .8, width: height * .8 * 2.5 }} src={props.feedbackMode ? "/selected-logo.png" : "/unselected-logo.svg"} />

            <Error sx={{ fill: props.feedbackMode ? "#ffbd58" : "white", ml: -.8, mt: -1, height: "13px", width: "13px" }} />


        </ButtonBase>

    )
}

export function DubseePanel(props) {


    const { player, cue, cueItems, hasActiveCue, width, height, handleCreateFlag, handleCreateChange, handleUp, handleDown, isItemOwner, hasVoted, upVotedItems, downVotedItems, hasVotedUp, hasVotedDown, userItems, itemsToVoteOn, demo, handleAddChange, seekToNextCue, seekToLastCue, play, pause, findDiffs, hasItemsToVoteOn, setHidePanel, viewer, takingAction, setToActionSide, submitting, setSubmitting, interrupt } = props;

    const [show, showPanel] = React.useState(false);
    const [originalText, setOriginalText] = React.useState("");
    const [text, setText] = React.useState(cue.text);

    const [loading, setLoading] = React.useState(true);
    const [flagType, setFlagType] = React.useState(flagTypes[0].type);
    const [revert, setRevert] = React.useState(false);
    const [viewerProfile, setViewerProfile] = React.useState(false);
    const [download, setDownload] = React.useState(false);

    React.useEffect(() => {
        player.addEventListener("onStateChange", hideViewerProfile);

        return () => {
            player.removeEventListener("onStateChange", hideViewerProfile);
        }
    }, []);

    React.useEffect(() => {
        
        showPanel(false);
        setLoading(true);
        setText(cue.text);
        setOriginalText(cue.text);

        setTimeout(() => {
            showPanel(true);
        }, 250);
        setTimeout(() => {
            setLoading(false);

        }, 300);

     

        if (hasItemsToVoteOn){                
                setToActionSide(true);
            if (viewer.settings && viewer.settings.allowPause) {            
                setTimeout(() => {
                    pause();
                }, 500);
            }
        }
        else {
            setToActionSide(false);
        }

    

    }, [cue]);


    const handleCreateNewChange = (text) => {
        setSubmitting(true);
        handleCreateChange(flagTypes[0].type, text);
    }

    const handleCorrectFlag = (e) => {
        e.stopPropagation();
        // handleCreateFlag(flagType)
        props.play();
        // setToActionSide(true);
        // setFlagType(flagTypes[0].type);
    }

    const handleUpVote = (item) => {
        setSubmitting(true);
        handleUp(item);
        setTimeout(() => {
            setSubmitting(false);
            setToActionSide(false);

        }, 2500);

        setTimeout(() => {
            play();

        }, 3500);


    }

    const handleNeitherVote = (item) => {
        setSubmitting(true);
        handleUp(item);
        setTimeout(() => {
            setSubmitting(false);
            setToActionSide(false);

        }, 2500);
    }

    const handleDownVote = (item) => {
        setSubmitting(true);
        handleDown(item);
        setTimeout(() => {
            setSubmitting(false);
            // setToActionSide(true);
        }, 2500);
        // setTimeout(() => {
        //     play();

        // }, 3500);
    }

    const showViewerProfile = (show) => {
        setLoading(true);
        setToActionSide(show);


        setTimeout(() => {
            setDownload(false);
            setViewerProfile(show);
            setLoading(false);
        }, 250);
    }


    const hideViewerProfile = () => {
        setLoading(true);
        if (!hasItemsToVoteOn) {
            setToActionSide(false);
        }

        setTimeout(() => {
            setViewerProfile(false);
            setLoading(false);
        }, 100);
    }

    const smallFrame = width < 650;
    const largeFrame = width > 1200;

    const handleDownloadPanel = (show) => {
        setLoading(true);
        setToActionSide(show);
        setDownload(show);
        setTimeout(() => {
            setLoading(false);
        }, 250);

    }

    const handleSignOut = () => {
        setLoading(true);
        setToActionSide(false);

        props.signOut();

        setTimeout(() => {
            setLoading(false);
        }, 250);
    }

    let cardWidth = smallFrame ? width * .9 : largeFrame ? width * .5 : width * .7;
    // let cardHeight = smallFrame ? height * .8 : largeFrame ? height * .3 : height * .4;

    let cardHeight = document.getElementById("panel") ? document.getElementById("panel").clientHeight : null;

    return (
        // <Grow direction="down" in={show} mountOnEnter unmountOnExit container={player.current}>
            <Box sx={{ display: "flex", width: cardWidth, height: cardHeight, transform: smallFrame ? "scale(.85)" : null, mt: smallFrame ? -3 : 0, mr: smallFrame ? -4 : 0 }} onClick={() => { props.pause(); props.setInterrupt(false); props.seekToCue(props.cue)}}>

                {!isMobile ?

                    <ReactCardFlip isFlipped={takingAction && props.signedIn} flipDirection="horizontal">
                        
                        <DisplaySide {...props} {...{ setToActionSide, takingAction, demo, setSubmitting, userItems, upVotedItems, downVotedItems, revert, hasVotedUp, play, handleCorrectFlag, setHidePanel, hideViewerProfile, handleCreateNewChange, handleCorrectFlag, handleUpVote, handleNeitherVote, handleDownVote, handleDownloadPanel, handleSignOut, text, originalText, submitting, loading, smallFrame, largeFrame, cardWidth, cardHeight, demo, hasItemsToVoteOn, hasVotedDown, isItemOwner, viewerProfile, download, showViewerProfile, hideViewerProfile, setText }} />
                       
                        <ActionSide {...props} {...{ setToActionSide, takingAction, demo, setSubmitting, userItems, upVotedItems, downVotedItems, revert, hasVotedUp, play, handleCorrectFlag, setHidePanel, hideViewerProfile, handleCreateNewChange, handleCorrectFlag, handleUpVote, handleNeitherVote, handleDownVote, handleDownloadPanel, handleSignOut, text, originalText, submitting, loading, smallFrame, largeFrame, cardWidth, cardHeight, demo, hasItemsToVoteOn, hasVotedDown, isItemOwner, viewerProfile, download, showViewerProfile, hideViewerProfile, setText, interrupt }} />

                    </ReactCardFlip>

                    :

                    (takingAction && props.signedIn) ?

                        <ActionSide {...props} {...{ setToActionSide, takingAction, demo, setSubmitting, userItems, upVotedItems, downVotedItems, revert, hasVotedUp, play, handleCorrectFlag, setHidePanel, hideViewerProfile, handleCreateNewChange, handleCorrectFlag, handleUpVote, handleNeitherVote, handleDownVote, handleDownloadPanel, handleSignOut, text, originalText, submitting, loading, smallFrame, largeFrame, cardWidth, cardHeight, demo, hasItemsToVoteOn, hasVotedDown, isItemOwner, viewerProfile, download, showViewerProfile, hideViewerProfile, setText, interrupt }} />

                        :

                        <DisplaySide {...props} {...{ setToActionSide, takingAction, demo, setSubmitting, userItems, upVotedItems, downVotedItems, revert, hasVotedUp, play, handleCorrectFlag, setHidePanel, hideViewerProfile, handleCreateNewChange, handleCorrectFlag, handleUpVote, handleNeitherVote, handleDownVote, handleDownloadPanel, handleSignOut, text, originalText, submitting, loading, smallFrame, largeFrame, cardWidth, cardHeight, demo, hasItemsToVoteOn, hasVotedDown, isItemOwner, viewerProfile, download, showViewerProfile, hideViewerProfile, setText  }} />

                }

                    </Box>
    
        // </Grow>
    );
}

function DisplaySide(props) {

    const { setToActionSide, takingAction, width, height, smallFrame, userItems, upVotedItems, downVotedItems, revert, hasVotedUp, play, handleCorrectFlag, setHidePanel, viewerProfile, handleSignOut, hasItemsToVoteOn, handleDownVote, handleUpVote, hideViewerProfile, handleDownloadPanel, showViewerProfile, download, largeFrame, cardWidth, cardHeight, itemsToVoteOn, handleVote, handleNeitherVote, handleCreateNewChange, loading, message, text, setText, setSubmitting, demo, originalText, submitting } = props;

    return (

        <Box sx={{ backdropFilter: "blur(8px)", height:"fit-content", display:"flex"}}>            


            {loading ?

                <Card id="panel" elevation={0} style={{ background: "rgba(0,0,0,0.7)", height: cardHeight, width: cardWidth }}>
                    <Skeleton variant="rectangular" animation="wave" width={cardWidth} height={height * .3} />
                </Card>
                :


                <Card elevation={0} id="panel" style={{ background: "rgba(0,0,0,0.7)", pointerEvents: props.playing ? "none" : "auto", width: cardWidth }}>


                    <Box>
                       <Box>
                    <Box sx={{ display: "flex", m: 1, mb: 0, cursor: "pointer", alignItems: "top", justifyContent: "flex-end" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                            <Box sx={{ display: "flex", m: 1 }}>
                                <Typography variant={smallFrame ? "h6" : largeFrame ? "h4" : "h5"} letterSpacing={-1}
                                    fontWeight="600" fontFamily="Inter, sans-serif"
                                    color="#ffbd58">dubsee</Typography>
                            </Box>


                            <Box>
                                <IconButton color="primary" onClick={() => props.openOnboarding(true)}>
                                    <HelpOutline />
                                </IconButton>
                            </Box>

                        </Box>

                        <Box sx={{ flexGrow: 1 }} />

                        <Box sx={{ m: 1 }}>

                            {props.signedIn ?
                                <ButtonBase sx={{ width: "50px" }} onClick={() => handleDownloadPanel(!download)}>
                                    <CloudDownload sx={{ fill: "#999999" }} />
                                </ButtonBase> : null}

                            <ButtonBase sx={{ width: "30px" }} disabled={!props.signedIn} onClick={() => showViewerProfile(true)}>
                                {
                                    props.signedIn ? <Avatar sx={{ width: "30px", height: "30px" }} src={props.userInfo.picture} />

                                        : <Person sx={{ fill: "#999999" }} />}
                            </ButtonBase>
                        </Box>

                        <ButtonBase sx={{ width: "30px" }} onClick={() => setHidePanel(true)}>
                            <Close sx={{ fill: "#999999" }} />
                        </ButtonBase>

                    </Box>

            
            {/* ACTIONS TAKEN */}
                            
                                    {userItems.filter(item => (item.change && item.change.text != "" && item.status == "accepted")).length > 0 ?
                                        <>
                                        <Grow in={true} unmountOnExit timeout={1000}>

                                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                            <Typography variant="h6">
                                                        +1 
                                                    </Typography>
                                                <EmojiEvents sx={{ color: "white", width: "20px", height: "20px", mr: 1, ml:1 }} />
                                                <Typography variant="caption">
                                                    Your change was accepted by other viewers!
                                                </Typography>
                                            </Box>
                                            </Grow>
                                        </>
                                        :

                                        userItems.filter(item => (item.change && item.change.text != "")).length > 0 ?
                                            <>
                                            {/* <Grow in={true} unmountOnExit timeout={1000}> */}
                                                <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", ml:2 }}>
                                            
                                                    <RateReview sx={{ color: "white", width: "20px", height: "20px", mr: 1, ml:1}} />
                                                 
                                                    <Typography variant="h6">
                                                        You updated this caption. + 1
                                                    </Typography>
                                                </Box>
                                                {/* </Grow> */}
                                            </> :

                                            userItems.filter(item => (item.change && item.change.text == "")).length > 0 ?

                                                <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                    <Flag sx={{ color: "white", width: "16px", height: "16px", mr: 1 }} />
                                                    <Typography variant="caption">
                                                        You flagged this caption.
                                                    </Typography>
                                                </Box>
                                                : null

                                    }

                                    {upVotedItems.filter(item => (item.change && item.change.text != "")).length > 0 ?
                                        <>
                                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                <ThumbUp sx={{ color: "white", width: "20px", height: "20px", mr: 1 }} />
                                                <Typography variant="caption">
                                                    Thank you for selecting the better caption.
                                                </Typography>
                                            </Box>
                                        </> : null}

                                    {downVotedItems.filter(item => (item.change && item.change.text != "")).length > 0 ?
                                        <>
                                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                <ThumbUp sx={{ color: "white", width: "20px", height: "20px", mr: 1 }} />
                                                <Typography variant="caption">
                                                    Thank you for selecting the better caption.
                                                </Typography>
                                            </Box>
                                        </> : null}

                                        {downVotedItems.filter(item => (item.type == "flag")).length > 0 ?
                                        <>
                                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", ml : 2 }}>
                                                <ThumbDown sx={{ color: "white", width: "20px", height: "20px", mr: 1 }} />
                                                <Typography variant="h6">
                                                    You said this caption is not accurate.
                                                </Typography>
                                            </Box>
                                        </> : null}

                                        {upVotedItems.filter(item => (item.type == "flag")).length > 0 ?
                                        <>
                                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", ml : 2 }}>
                                                <ThumbUp sx={{ color: "white", width: "20px", height: "20px", mr: 1 }} />
                                                <Typography variant="h6">
                                                    You said this caption is accurate.
                                                </Typography>
                                            </Box>
                                        </> : null}
                               
                            
                        </Box>
                        <>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", m: largeFrame ? 2 : 1 }}>

                                {/* {!hasItemsToVoteOn  ?
                                        <ButtonBase sx={{ display: "flex", m: 1, mb:0, height: "25px", width: "25px", cursor: "pointer", alignItems: "center" }} disabled={!props.signedIn} onClick={() => setToActionSide(true)}>
                                            <CaptionEditIcon size={15} />
                                        </ButtonBase> : null} */}

                                {upVotedItems.filter(item => (item.change && item.change.text != "")).length > 0 ?

                                    upVotedItems.filter(item => (item.change && item.change.text != "")).map((item, index) => (
                                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", m: 2, mb: 0 }}>

                                            {/* <Box sx={{ display: "flex", justifyContent: "flex-end", mt: -4, mr: -5, mb: -2 }}>
                                                <IconButton size="small" onClick={() => setRevert(!revert)} >{revert ? <Update sx={{ fill: "#999999" }} /> : <Restore sx={{ fill: "#999999" }} />}</IconButton>
                                            </Box> */}

                                            <Card elevation={0} sx={{background:"transparent", width: "100%", justifyContent: "center" }}>
                                                <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h5" : "h6"} align="center">
                                                    {revert ?

                                                        findDiffs(item.change.text, originalText).map(diff =>

                                                            (diff.change == "") ?
                                                                <>{diff.original + " "}</> :
                                                                <mark style={{borderRadius:3,backgroundColor:"#f69421", p:1}}>{diff.change + " "}</mark>
                                                        )

                                                        :

                                                        findDiffs(originalText, item.change.text).map(diff =>

                                                            (diff.change == "") ?
                                                                <>{diff.original + " "}</> :
                                                                <mark style={{borderRadius:3,backgroundColor:"#f69421", p:1}}>{diff.change + " "}</mark>

                                                        )}
                                                </Typography>
                                            </Card>
                                        </Box>
                                    ))

                                    :

                                    userItems.filter(item => (item.change && item.change.text != "")).length > 0 ?
                                        userItems.filter(item => (item.change && item.change.text != "")).map((item, index) => (
                                            <>
                                                <Box sx={{ display: "flex", flexDirection: "column", width: "100%", m: 2, mb: 0 }}>

                                                    {/* <Box sx={{ display: "flex", justifyContent: "flex-end", mt: -2, mr: -3, mb: -2 }}>
                                                        <IconButton size="small" onClick={() => setRevert(!revert)} >{revert ? <Update sx={{ fill: "#999999" }} /> : <Restore sx={{ fill: "#999999" }} />}</IconButton>
                                                    </Box> */}

                                                    <Box>
                                                        <Card elevation={0} sx={{background:"transparent", width: "100%" }}>

                                                            <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h5" : "h6"} align="center">

                                                                {revert ?

                                                                    findDiffs(item.change.text, originalText).map(diff =>
                                                                        (diff.change == "") ? <>{diff.original + " "}</> :  <mark style={{borderRadius:3,backgroundColor:"#f69421", p:1}}>{diff.change + " "}</mark>

                                                                    )

                                                                    :

                                                                    findDiffs(originalText, item.change.text).map(diff =>
                                                                        (diff.change == "") ? <>{diff.original + " "}</> :  <mark style={{borderRadius:3,backgroundColor:"#f69421", p:1}}>{diff.change + " "}</mark>
                                                                    )

                                                                }

                                                            </Typography>
                                                        </Card>
                                                    </Box>


                                                </Box>
                                            </>
                                        ))

                                        :

                                        <Card variant="outlined" sx={{margin:"0 auto", p: 2, cursor: "pointer", background: "transparent", pointerEvents:"auto" }} onClick={() => setToActionSide(true)}>
                                            <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h5" : "h6"} align="center">
                                                {originalText}
                                            </Typography>
                                        </Card>
                                }
                            </Box>


                          
                            {  props.signedIn 
                            ?

                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                    <Box sx={{ height: "45px", display: "flex", alignItems: "center", justifyContent: "center", pointerEvents: props.playing ? "none" : "auto" }} >
                                        <ButtonBase sx={{ height: "10%", width: "10%", m: 1 }} onClick={() => props.seekToLastCue(props.cue)}>
                                            <FirstPage sx={{ fill: "#999999" }} /></ButtonBase>
                                        <ButtonBase sx={{ m: 1 }}>
                                            {props.cue ? <CaptionReplayIcon replay={() => props.playCue(props.cue)} size={25} /> : <CaptionAddIcon size={25} />}
                                        </ButtonBase>
                                        <ButtonBase sx={{ height: "10%", width: "10%", m: 1 }} onClick={() => props.seekToNextCue(props.cue)}><FirstPage sx={{ transform: "rotateY(180deg)", fill: "#999999" }} /></ButtonBase>
                                    </Box>


                                    <Box sx={{ flexGrow: 1 }} />

                                    {(userItems.length > 0 || hasVotedUp) ?

                                    <Box sx={{ display: "flex", justifyContent: "flex-end", m: largeFrame ? 2 : 1 }}>
                                        <Button
                                            size="small"
                                            color="success"
                                            variant="contained"
                                            endIcon={<PlayArrow />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                play();
                                            }}
                                        >
                                            Resume
                                        </Button>
                                    </Box>

                                    :

                                    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "row", m: largeFrame ? 2 : 1, mt: smallFrame ? 0 : 1, justifyContent: "flex-end" }}>

                                    <Box sx={{ flexGrow: .3, display: "flex", maxWidth: "300px" }}>
                                        <Button endIcon={smallFrame ? null : <Check />} variant="outlined" color="success" fullWidth onClick={(e) => handleCorrectFlag(e)}>
                                            Correct
                                        </Button>
                                    </Box>

                                    <Box sx={{ flexGrow: 1, ml: 1, display: "flex", alignItems: "center", maxWidth: "400px" }}>
                                        <Button
                                            fullWidth
                                            highlight
                                            startIcon={<Hearing />}
                                            onClick={() => setToActionSide(true)}
                                        >
                                            {smallFrame ? "Not Spoken" : "This Is Not Spoken"}
                                        </Button>


                                    </Box>
                                </Box>
                                        }


                                </Box>
                                :

                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", m: largeFrame ? 2 : 1, mt: largeFrame ? 1 : 0 }}>
                                        <Button size="large" onClick={() => props.googleLogin()} startIcon={<img style={{ height: "30px" }} src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" />}>
                                            Sign in with Google
                                        </Button>
                                    </Box>
                            }

                        </>
                    </Box>
                </Card>}
        </Box>


    )

}

function ActionSide(props) {

    const { setToActionSide, takingAction, width, height, smallFrame, userItems, upVotedItems, downVotedItems, revert, hasVotedUp, play, handleCorrectFlag, setHidePanel, viewerProfile, handleSignOut, hasItemsToVoteOn, handleDownVote, handleUpVote, hideViewerProfile, handleDownloadPanel, showViewerProfile, download, largeFrame, cardWidth, cardHeight, itemsToVoteOn, handleVote, handleNeitherVote, handleCreateNewChange, loading, message, text, setText, setSubmitting, demo, originalText, submitting, interrupt } = props;

    const [secondsInterrupt, setSecondsInterrupt] = React.useState(0);

    React.useEffect(() => {
        let interval = null;
        if (interrupt) {
            clearInterval(interval);
            interval = setInterval(() => {
                setSecondsInterrupt(secondsInterrupt => secondsInterrupt + 1);
            }, 1000);
        } else if (!interrupt && secondsInterrupt >= (INTERRUPT_TIME)/1000) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [interrupt, secondsInterrupt]);

    return (
        <Box sx={{ backdropFilter: "blur(8px)", height:"fit-content", display:"flex"}}>


            {loading ?

                <Card elevation={0} id="panel" style={{ background: "rgba(0,0,0,0.7)", height: height * .4, width: cardWidth }}>
                    <Skeleton variant="rectangular" animation="wave" width={smallFrame ? width * .9 : largeFrame ? width * .5 : width * .75} height={height * .3} />
                </Card>

                :

                <>

                    <Card elevation={0} id="panel" style={{ background: "rgba(0,0,0,0.7)", pointerEvents: props.playing ? "none" : "auto", width: cardWidth }}>                   

                    {interrupt ? <LinearProgress variant="determinate" value={secondsInterrupt/(INTERRUPT_TIME/1000) * 100} color="secondary" style={{ height: "10px" }} /> : null}

                        {submitting ? <LinearProgress color="secondary" style={{ height: "10px" }} /> : null}

                        <Box sx={{ display: "flex", m: 1, mb: 0, cursor: "pointer", alignItems: "top", justifyContent: "flex-end" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                                <Box sx={{ display: "flex", m: 1 }}>
                                    <Typography variant={smallFrame ? "h6" : largeFrame ? "h4" : "h5"} letterSpacing={-1}
                                        fontWeight="600" fontFamily="Inter, sans-serif"
                                        color="#ffbd58">dubsee</Typography>
                                </Box>


                                <Box>
                                    <IconButton color="primary" onClick={() => props.openOnboarding(true)}>
                                        <HelpOutline />
                                    </IconButton>
                                </Box>

                            </Box>

                            <Box sx={{ flexGrow: 1 }} />

                            <Box sx={{ m: 1 }}>


                                <ButtonBase sx={{ width: "50px" }} onClick={() => handleDownloadPanel(!download)}>
                                    {download ? <Reply sx={{ fill: "#999999" }} /> : <CloudDownload sx={{ fill: "#999999" }} />}
                                </ButtonBase>

                                <ButtonBase sx={{ width: "30px" }} disabled={!props.signedIn} onClick={() => showViewerProfile(!viewerProfile)}>
                                    {
                                        props.signedIn ? <Avatar sx={{ width: "30px", height: "30px" }} src={props.userInfo.picture} />

                                            : <Person sx={{ fill: "#999999" }} />}
                                </ButtonBase>
                            </Box>

                            <ButtonBase sx={{ width: "30px" }} onClick={() => setHidePanel(true)}>
                                <Close sx={{ fill: "#999999" }} />
                            </ButtonBase>

                        </Box>



                        {
                            download ?

                                <DownloadPanel {...props} width={width} height={height} handleDownloadPanel={handleDownloadPanel} />

                                :

                                viewerProfile ?

                                    <ViewerProfile {...props} setHidePanel={setHidePanel} handleSignOut={handleSignOut} showViewerProfile={showViewerProfile} />

                                    :


                                    hasItemsToVoteOn ?
                                        <>

                                            <Box sx={{ m: 1, p: 1 }}>
                                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>


                                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        
                                                    <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                                                        <Hearing sx={{ width: "20px", height: "20px", fill: "white" }} />
                                                        <Typography variant="h6" sx={{ color: "white", ml: 1, mr: 1 }}>
                                                            {itemsToVoteOn[0].type == "flag" ?  "Is this spoken?" :
                                                            "Select the spoken captioon."}
                                                        </Typography>
                                                    </Box>
                                                    
                                                            <Box sx={{ flexGrow: 1 }} />

                                                            {/* <Box>
                                                           
                                                            <Typography variant="caption" sx={{ color: "white", mr: 1 }}>
                                                               {`Cue ID: ${itemsToVoteOn[0].key}  Cue Index: ${itemsToVoteOn[0].key-1}  `}

                                                 
                                                                {props.entities && Object.keys(props.entities).includes((itemsToVoteOn[0].key-1).toString()) ?
                                                                    `ENTITY FOUND: ${props.entities[itemsToVoteOn[0].key-1][0].type} "${props.entities[itemsToVoteOn[0].key-1][0].text}"
                                                                ` : null}

                                        
                                                                </Typography>
                                                                </Box> */}
                                                       
                                                        </Box>

                                                    
                                              
                                                </Box>
                                                
                                                { itemsToVoteOn[0].type != "flag" ?

                                                <>

                                                <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center", width: "100%", mt: 1, mb: 2 }}>

                                                    <Box sx={{ mr: 1 }}>
                                                        <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h5" : "h6"}>A: </Typography>
                                                    </Box>

                                                    <Card variant="outlined" sx={{ flexGrow: 1, p: 1, cursor: "pointer", background: "#373737" }} onClick={() => handleDownVote(itemsToVoteOn[0])}>
                                                        <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h5" : "h6"} align="center">
                                                            {findDiffs(itemsToVoteOn[0].change.text, originalText).map(diff =>


                                                                (diff.change == "") ?
                                                                    <>{diff.original + " "}</> :
                                                                     <mark style={{borderRadius:3,backgroundColor:"#f69421", p:1}}>{diff.change + " "}</mark>

                                                            )}
                                                        </Typography>
                                                    </Card>

                                                </Box>

                                                <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center", width: "100%", mt: 1, mb: 1 }}>

                                                    <Box sx={{ mr: 1 }}>
                                                        <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h5" : "h6"}>B: </Typography>
                                                    </Box>
                                                    <Card variant="outlined" sx={{ flexGrow: 1, p: 1, cursor: "pointer", background: "#373737" }} onClick={() => handleUpVote(itemsToVoteOn[0])}>
                                                        <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h5" : "h6"} align="center">

                                                            {findDiffs(originalText, itemsToVoteOn[0].change.text).map(diff =>


                                                                (diff.change == "") ?
                                                                    <>{diff.original + " "}</> :
                                                                     <mark style={{borderRadius:3,backgroundColor:"#f69421", p:1}}>{diff.change + " "}</mark>

                                                            )}

                                                        </Typography>
                                                    </Card>
                                                </Box>

                                                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                                        <Box sx={{ height: "45px", display: "flex", alignItems: "center", justifyContent: "center", pointerEvents: props.playing ? "none" : "auto" }} >
                                                            <ButtonBase sx={{ height: "10%", width: "10%", m: 1 }} onClick={props.seekToLastCue}>
                                                                <FirstPage sx={{ fill: "#999999" }} /></ButtonBase>
                                                            <ButtonBase sx={{ m: 1 }}>
                                                                {props.cue ? <CaptionReplayIcon replay={() => props.playCue(props.cue)} size={25} /> : <CaptionAddIcon size={25} />}
                                                            </ButtonBase>
                                                            <ButtonBase sx={{ height: "10%", width: "10%", m: 1 }} onClick={() => props.seekToNextCue(props.cue)}><FirstPage sx={{ transform: "rotateY(180deg)", fill: "#999999" }} /></ButtonBase>
                                                        </Box>
                                                    </Box>


                                                    <Box sx={{ flexGrow: 1 }} />
                                                    <Button onClick={() => handleNeitherVote(itemsToVoteOn[0])}
                                                        variant="outlined" endIcon={<ArrowRight />}>Neither</Button>
                                                </Box>
                                               
                                               </>

                                               :
                                               <>

                                                <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center", width: "100%", mt: 1, mb: 2 }}>

                                
                                                    <Typography sx={{ flexGrow: 1, p: 1, cursor: "pointer", background: "transparent" }} variant={smallFrame ? "subtitle2" : largeFrame ? "h5" : "h6"} align="center">
                                                        {props.cue.text}
                                                    </Typography>
                                              
                                                </Box>

                                                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                                    <Box sx={{ height: "45px", display: "flex", alignItems: "center", justifyContent: "center", pointerEvents: props.playing ? "none" : "auto" }} >
                                                        <ButtonBase sx={{ height: "10%", width: "10%", m: 1 }} onClick={props.seekToLastCue}>
                                                            <FirstPage sx={{ fill: "#999999" }} /></ButtonBase>
                                                        <ButtonBase sx={{ m: 1 }}>
                                                            {props.cue ? <CaptionReplayIcon replay={() => props.playCue(props.cue)} size={25} /> : <CaptionAddIcon size={25} />}
                                                        </ButtonBase>
                                                        <ButtonBase sx={{ height: "10%", width: "10%", m: 1 }} onClick={() => props.seekToNextCue(props.cue)}><FirstPage sx={{ transform: "rotateY(180deg)", fill: "#999999" }} /></ButtonBase>
                                                    </Box>
                                                </Box>


                                                <Box sx={{ flexGrow: 1 }} />
                                                <Box sx={{m:1}}>
                                                <Button onClick={() => handleUpVote(itemsToVoteOn[0])}
                                                    variant="contained" color="success" endIcon={<CheckCircle />}>Yes</Button>
                                                        </Box>

                                                <Button onClick={() => handleDownVote(itemsToVoteOn[0])}
                                                    variant="outlined" highlight endIcon={<Close />}>No</Button>
                                                </Box>

                                               
                                               </>}
                                                                
                                        </Box>
                                        </>
                                        :
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                                    {/* <Box sx={{ mr: 3, ml: 3 }}>
                                            <CaptionEditIcon size={width * .03} />
                                        </Box> */}
                                                    <Box sx={{ml:3, display:"flex", alignItems:"center"}}>
                                                        <Hearing sx={{ height:20, width:20, fill:"white", mr:1 }} />

                                                        <Typography variant="h6">Update what is spoken in this caption.</Typography>

                                                    </Box>
                                                </Box>
                                                <Box sx={{ m: largeFrame ? 2 : 1 }}>
                                                    <TextField
                                                        InputProps={{
                                                            inputProps: {
                                                                style: { textAlign: "center", }
                                                            },
                                                            style: {

                                                                fontSize: smallFrame ? ".9rem" : largeFrame ? "1.5rem" : "1.1rem",
                                                                color: "white",
                                                                background: "rgba(246,148,33,.2)",
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        multiline
                                                        rows={2}
                                                        fullWidth
                                                        value={text}
                                                        onKeyDownCapture={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                        onChange={(e) => {
                                                            setText(e.target.value)
                                                        }} />
                                                </Box>

                                                <Box sx={{ display: "flex", flexDirection: "row", m: largeFrame ? 2 : 1, mt: 0, justifyContent: "flex-start" }}>
                                                    <Box sx={{ display: "flex", flexGrow: .2, mr: 1 }}>
                                                        <Button
                                                            onClick={() => setToActionSide(false)}
                                                            fullWidth
                                                            size="small"
                                                            startIcon={<PlayArrow sx={{ transform: "rotate(180deg)" }} />}
                                                            variant="outlined" >Back</Button>
                                                    </Box>
                                                    <Box sx={{ display: "flex", flexGrow: 1 }}>
                                                        <Button
                                                            fullWidth
                                                            highlight={!((text == originalText) || submitting)}
                                                            disabled={(text == originalText) || submitting}
                                                            size="small"
                                                            onClick={() => {
                                                                handleCreateNewChange(text);
                                                            }}
                                                        >Submit</Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                     

                        }

                    


                </Card>
                </>}
                
    
        
        </Box>

    );

}

function HiddenCard(props) {


    return (
        <Box sx={{ m: 1 }}>
            {/* <Card elevation={0} > */}
            {/* style={{ background: "rgba(0,0,0,0.7)" }}> */}
            <Box sx={{ m: 2, pointerEvents: props.playing ? "none" : "auto" }}>
                <ButtonBase onClick={() => props.setHidePanel(false)}>
                    <CaptionVisibilityIcon size={30} />
                </ButtonBase>
            </Box>

            {/* </Card> */}
        </Box>

    )
}


function LinearCountdown(props) {

    const { time, stopped } = props;

    const [progress, setProgress] = React.useState(100);

    React.useEffect(() => {

        if (progress <= 0) {
            props.handleOpen();
            setProgress(100);
        }

    }, [progress]);

    React.useEffect(() => {

        setProgress(100);

    }, [props.open]);


    React.useEffect(() => {


        const timer =
            !stopped ?
                setInterval(() => {
                    setProgress((prevProgress) => (prevProgress - (10 / time)));
                }, (100)) : null;

        if (!stopped) {
            setTimeout(() => {
                clearInterval(timer);
            }, ((time * 1000) + 100));
        }


        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress color="secondary" style={{ height: "10px", transform: "rotate(180deg)" }} variant="determinate" value={progress} />
        </Box>
    );
}

export function CaptionFeedBackSliderControlBar(props) {

    const { feedbackMode, height, setFeedbackMode, width } = props;
    const smallFrame = width < 500;
    
    return (

        <div style={{ position: "absolute", width: width, bottom:0, left:0}}>

            {!props.playing ?
                <Box sx={{ position: "absolute", bottom: 50, left: 0, height: smallFrame ? "0px" : "10vw", display: "flex", flexDirection: "row", width: width, pointerEvents: "auto", alignItems: "center", background: "rgba(25,25,25,.3)" }} />
                : null}

            <Box sx={{ position: "absolute",bottom: props.isHovered ? 60 : (props.playing || smallFrame) ? 12 : 60, left: 0, display: "flex", flexDirection: "row", width: width, pointerEvents: "auto", alignItems: "center" }}>
                <div style={{ position: "relative", width: width }} id="slider">
                    <SeekSlider {...props} height={height * .1} width={width} />
                </div>
            </Box>

            <YTControls {...props} />


        </div>



    );
}

function SeekSlider(props) {

    const { player, cue, viewerId, ccfId, textTracks, feedbackItems, duration, handleSeek, seekToCue, currentTime, demo, seekToLastCue, language, seekToNextCue, playing, height, width, isHovered } = props;

    // const [sliderWidth, setSliderWidth] = React.useState(props.width);
    // const [currentTime, setCurrentTime] = React.useState(player.current.currentTime);

    // console.log("CAPTION FEEDBACK SLIDER CURRENT TIME", currentTime);
    // console.log("Slider Width", sliderWidth);
    // console.log("Duration", duration);


    // const handleSliderWidth = () => {
    //     setSliderWidth(document.getElementById("slider").getBoundingClientRect().width);
    // }

    // React.useEffect(() => {
    //     setSliderWidth(document.getElementById("slider").getBoundingClientRect().width);

    //     window.addEventListener("resize", handleSliderWidth, true);


    //     return () => {

    //         window.removeEventListener("resize", handleSliderWidth, true);

    //     }


    // }, []);

    const itemsTimeline = feedbackItems.filter(item => item.language == language);
    return (
        <Box sx={{ width: "100%" }}>
            <VideoSeekSlider
                max={duration}
                currentTime={currentTime}
                bufferTime={3000}
                onChange={handleSeek}
                secondsPrefix="00:00:"
                minutesPrefix="00:"
                hideThumbTooltip
            />

            {textTracks && textTracks.length > 0 ?
                itemsTimeline.map((item, index) =>

                textTracks[item.key - 1] ? 

                    <div onClick={() => seekToCue(textTracks[item.key - 1])}
                        style={{ display: (playing && !isHovered) ? "none" : "block", position: "absolute", cursor: "pointer", top: -22, left: -15 + (textTracks[item.key-1].start / duration) * width }}>

                        <CaptionIconMarker size={30} />

                    </div>

                    : null
                )

                : null}

        </Box>

    );
}

export const IframePlayButton = (props) => {

    const { player, playing, height } = props;

    return (

        <ButtonBase
            sx={{
                background: "rgba(20,20,20, 0.7)", height: height, width: height * 1.2, display: "flex", alignItems: "center", justifyContent: "center"
            }}


            onClick={() => {
                if (playing) {
                    player.pauseVideo();
                }
                else {
                    player.playVideo();
                }
            }
            }>

            {playing ?
                <Pause sx={{ fill: "white", height: height * .7, width: height * .7 }} />
                :
                <PlayArrow sx={{ fill: "white", height: height * .7, width: height * .7 }} />
            }

        </ButtonBase>

    )
}


export function CaptionAddIcon(props) {


    return (

        <Box sx={{ position: "relative" }}>

            <ClosedCaptionOutlined sx={{ color: "#ffbd58", width: props.size, height: props.size }} />

            <Box sx={{ position: "absolute", top: -props.size / 5, left: props.size / 1.8 }}>
                <AddCircle sx={{ color: "#ffbd58", height: props.size / 1.5, width: props.size / 1.5 }} />
            </Box>

            {props.children}

        </Box>

    );
}

export function CaptionFlagIcon(props) {


    return (

        <Box sx={{ position: "relative" }}>

            <ClosedCaptionOutlined sx={{ color: "#ffbd58", width: props.size, height: props.size }} />

            <Box sx={{ position: "absolute", top: -props.size / 5, left: props.size / 1.8 }}>
                <Error sx={{ color: "#ffbd58", height: props.size / 1.5, width: props.size / 1.5 }} />
            </Box>

            {props.children}

        </Box>

    );
}

export function CaptionReplayIcon(props) {


    return (

        <ButtonBase sx={{ position: "relative" }} onClick={(e) => { e.stopPropagation(); props.replay() }}>

            <Replay sx={{ color: "#ffbd58", width: props.size, height: props.size }} />

            {/* <Box sx={{ position: "absolute", top: -props.size / 5, left: props.size / 1.8 }}>
                <Replay sx={{ color: "#ffbd58", height: props.size / 1.5, width: props.size / 1.5 }} />
            </Box> */}

            {props.children}

        </ButtonBase>

    );
}

export function CaptionVisibilityIcon(props) {


    return (

        <Box sx={{ position: "relative" }}>

            <ClosedCaptionOutlined sx={{ color: "#ffbd58", width: props.size, height: props.size }} />

            <Box sx={{ position: "absolute", top: props.size / 2, left: props.size / 1.8 }}>
                <Visibility sx={{ color: "#ffbd58", height: props.size / 1.5, width: props.size / 1.5 }} />
            </Box>

            {props.children}

        </Box>

    );
}


export function CaptionFlagIconMarker(props) {
    return (

        <Box sx={{ position: "relative" }}>

            {/* <ClosedCaption sx={{color:"#ffbd58", width: props.size, height: props.size }}  /> */}

            <Box sx={{ position: "absolute", left: props.size / 6 }}>
                <Error sx={{ color: "#ffbd58", height: props.size, width: props.size }} />
            </Box>

            <Box sx={{ position: "absolute", top: 16, left: 0, zIndex: 10 }}>
                <VerticalAlignBottom sx={{ color: "#ffbd58", height: props.size, width: props.size }} />
            </Box>

        </Box>

    );
}

export function CaptionIconMarker(props) {


    return (

        <Box sx={{ position: "relative" }}>

            <img src={"./marker.png"} style={{ width: props.size, height: props.size }} />
        </Box>

    );
}


export function CaptionEditIcon(props) {


    return (

        <Box sx={{ position: "relative" }}>

            <ClosedCaption sx={{ color: "#ffbd58", width: props.size, height: props.size }} />

            <Box sx={{ position: "absolute", top: props.size / 6, left: props.size / 3 }}>
                <Edit sx={{ color: "#ffbd58", height: props.size, width: props.size }} />
            </Box>

        </Box>

    );
}

export function CaptionEditIconMarker(props) {
    return (

        <Box sx={{ position: "relative" }}>

            <Edit sx={{ color: "#ffbd58", height: props.size, width: props.size }} />

            <Box sx={{ position: "absolute", top: 16, left: 0, zIndex: 10 }}>
                <VerticalAlignBottom sx={{ color: "#ffbd58", height: props.size, width: props.size }} />
            </Box>

        </Box>

    );
}

export function CaptionVoteIcon(props) {
    return (

        <Box sx={{ position: "relative" }}>

            <ClosedCaptionOutlined sx={{ color: "#ffbd58", width: props.size, height: props.size }} />


            <Box sx={{ position: "absolute", top: -props.size / 3, left: props.size / 2 }}>
                <CheckBox sx={{ color: "#ffbd58", height: props.size / 1.4, width: props.size / 1.4 }} />
            </Box>

            {props.children}

        </Box>
    );
}

export function CaptionVoteIconMarker(props) {
    return (

        <Box sx={{ position: "relative" }}>
            <CheckBox sx={{ color: "#ffbd58", width: props.size, height: props.size }} />

            <Box sx={{ position: "absolute", top: 16, left: 0, zIndex: 10 }}>
                <VerticalAlignBottom sx={{ color: "#ffbd58", height: props.size, width: props.size }} />
            </Box>

        </Box>

    );
}


function YTControls(props) {

    const { handleSetVolume, handlePlayPause, playing, volume, handleFullScreen, fullScreen, language, setLanguage, isHovered } = props;

    const handlePlayPauseClick = () => {
        handlePlayPause();
    };

    const handleVolumeChange = (event, value) => {
        handleSetVolume(value);
    };

    return (

        <Box sx={{ position: "absolute", bottom: 0, left: 0, display: "flex", flexDirection: "row", width: props.width, pointerEvents: "auto" }} id="yt-controls">

            {isHovered || !playing ?
                <ThemeProvider theme={theme}>
                    <Box sx={{ display: "flex", pl: 1, pr: 1, alignItems: "center", justifyContent: "flex-start", width: "100%", height: "50px", background: "linear-gradient(0deg, rgba(25,25,25,0.72) 0%, rgba(0,0,0,0.8) 40%, rgba(0,0,0,0.8) 60%, rgba(25,25,25,0.72) 100%)" }}>
                        <Box>
                            <ButtonBase onClick={handlePlayPauseClick}>
                                {playing ? <Pause color="primary" /> : <PlayArrow color="primary" />}
                            </ButtonBase>
                        </Box>

                        <Box sx={{ flexGrow: 1 }} />

                        <Box>
                            <Language sx={{ width: 15, height: 15, color: "white" }} />
                        </Box>

                        <Box sx={{ width: "140px", m: 1 }}>
                            <TextField
                                select
                                // IconComponent={Language} 
                                InputProps={{
                                    sx: {
                                        color: "white",
                                        fill: "white",
                                        width: "100px",
                                        height: "40px",
                                        border: "0px",
                                        borderRadius: "0px",
                                    }
                                }}
                                SelectProps={{
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        ...MenuProps,

                                    }
                                }}
                                MenuProps={MenuProps}
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                            >

                                {Object.keys(supportedLanguages).map((key) =>
                                    <MenuItem value={supportedLanguages[key].code}>{supportedLanguages[key].label}</MenuItem>
                                )}

                            </TextField>
                        </Box>

                        <Box sx={{ width: "100px" }}>
                            <Slider
                                size="small"
                                value={volume}
                                onChange={handleVolumeChange}
                                min={0}
                                max={100}
                                aria-labelledby="volume-slider"
                            />
                        </Box>
                        <Box sx={{ m: 2 }}>
                            {volume == 0 ? <VolumeOff onClick={(e) => handleVolumeChange(e, 50)} color="primary" /> : volume < 50 ? <VolumeDown onClick={(e) => handleVolumeChange(e, 0)} color="primary" /> : <VolumeUp onClick={(e) => handleVolumeChange(e, 0)} color="primary" />}
                        </Box>

                        {(isSafari || isMobile) ? null :
                            <ButtonBase onClick={() => handleFullScreen(!fullScreen)} sx={{ m: 2 }}>
                                {fullScreen ? <FullscreenExit color="primary" /> : <Fullscreen color="primary" />}
                            </ButtonBase>}

                    </Box>
                </ThemeProvider>

                : null}

        </Box>


    );

}

export function OnboardingPanel(props) {

    const { width, activeStep, originalText, interText, finalText } = props;

    const largeFrame = window.innerWidth > 1200;
    const smallFrame = window.innerWidth < 650;
    let cardWidth = smallFrame ? width * .9 : largeFrame ? width * .5 : width * .75;

    const badges = [
        { name: "First Time Fixer", category: "change", conditionThreshold: 1, conditionCurrent: 10, description: "You updated your first caption!", icon: <NewReleases color="secondary" /> },
        { name: "Verified Voter", category: "vote", conditionThreshold: 1, conditionCurrent: 10, description: "You voted for the first time!", icon: <ThumbsUpDown color="primary" /> },
        { name: "Watchful Eye", category: "change", description: "You found 10 corrections!", icon: <LocalPolice color="error" />, conditionThreshold: 10, conditionCurrent: 10 },
        { name: "Pro Poller", category: "vote", conditionThreshold: 10, conditionCurrent: 5, description: "You voted on 10 videos!", icon: <Verified color="success" /> },
        { name: "All-Star", category: "vote", description: "You voted on 1000 videos!", conditionThreshold: 1000, conditionCurrent: 10, icon: <School color="secondary" /> },
    ];

    const badgesEarned = badges.filter(badge => badge.conditionCurrent >= badge.conditionThreshold);
    const nextBadge = badges.filter(badge => !(badge.conditionCurrent >= badge.conditionThreshold))[0];

    return (
        <ThemeProvider theme={theme}>
            <Box>
                <Grow in={true} timeout={1000}>
                    <Card elevation={0} style={{ background: "rgba(0,0,0,0.7)", width: cardWidth }}>

                        <Box sx={{ display: "flex", m: 1, mb: 0, cursor: "pointer", alignItems: "top", justifyContent: "flex-end" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                                <Box sx={{ display: "flex" }}>

                                    <Box sx={{ display: "flex" }}>
                                        <Typography variant={smallFrame ? "h6" : largeFrame ? "h4" : "h5"} letterSpacing={-1}
                                            fontWeight="600" fontFamily="Inter, sans-serif"
                                            color="#ffbd58">dubsee</Typography>


                                    </Box>

                                </Box>
                            </Box>

                            <Box sx={{ flexGrow: 1 }} />

                            <Box sx={{ m: 1 }}>


                                <ButtonBase sx={{ width: "50px" }} >
                                    <CloudDownload sx={{ fill: "#999999" }} />
                                </ButtonBase>

                                <ButtonBase sx={{ width: "30px" }} >
                                    <Avatar sx={{ width: "30px", height: "30px", boxShadow: activeStep == 8 ? "0px 0px 30px 8px #ffbd58" : "" }} src={"/Leon.jpg"} />
                                </ButtonBase>
                            </Box>

                            <ButtonBase sx={{ width: "30px" }} >
                                <Close sx={{ fill: "#999999" }} />
                            </ButtonBase>

                        </Box>
                        <>
                            {activeStep < 6 ?
                                <Box sx={{ display: "flex", justifyContent: "flex-end", m: largeFrame ? 2 : 1 }}>
                                    <Grow in={true} timeout={2000}>
                                        <Card variant="outlined" sx={{ flexGrow: 1, p: 1, cursor: "pointer", border: activeStep == 5 ? "2px solid white" : "", background: activeStep == 5 ? "#000000" : "#373737", boxShadow: activeStep == 2 ? "0px 0px 30px 8px #ffbd58" : null }}>

                                            <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h5" : "h6"} align="center">
                                                {activeStep == 5 ?
                                                    <TypeAnimation
                                                        deletionSpeed={20}
                                                        sequence={[
                                                            originalText, // Types 'One'
                                                            3000, // Waits 1s
                                                            finalText, // Deletes 'One' and types 'Two'
                                                            3000
                                                        ]}
                                                        wrapper="div"
                                                        cursor={true}
                                                        repeat={Infinity}
                                                    /> : originalText}

                                            </Typography>
                                        </Card>
                                    </Grow>
                                </Box> : null}

                            {activeStep == 2 || activeStep == 3 ?
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", m: largeFrame ? 2 : 1, mt: largeFrame ? 1 : 0 }}>

                                    <Grow in={true} timeout={2000}>
                                        <Button sx={{
                                            boxShadow: activeStep == 3 ? "0px 0px 30px 8px #ffbd58" : "",
                                        }} size="large" startIcon={<img style={{ height: "30px" }} src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" />}>
                                            Sign in with Google
                                        </Button>
                                    </Grow>
                                </Box>
                                : null}


                            {activeStep == 6 ?
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", m: largeFrame ? 2 : 0 }}>

                                    <Box sx={{ display: "flex", alignItems: "center", ml: 4, mr: 1 }}>
                                        <Info sx={{ width: "16px", height: "16px", fill: "white" }} />

                                        <Typography variant="body1" sx={{ color: "white", ml: 1, mr: 1 }}>
                                            Select the better caption.
                                        </Typography>

                                    </Box>

                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", m: largeFrame ? 2 : 1 }}>
                                        <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h5" : "h6"}>A: </Typography>
                                        <Card variant="outlined" sx={{ flexGrow: 1, p: 1, ml: 1, cursor: "pointer", border: activeStep == 5 ? "2px solid white" : "", background: activeStep == 5 ? "#000000" : "#373737" }}>

                                            <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h5" : "h6"} align="center">

                                                {findDiffs(finalText, originalText).map(diff =>

                                                    (diff.change == "") ?
                                                        <>{diff.original + " "}</> :
                                                         <mark style={{borderRadius:3,backgroundColor:"#f69421", p:1}}>{diff.change + " "}</mark>
                                                )}
                                            </Typography>
                                        </Card>


                                    </Box>

                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", m: largeFrame ? 2 : 1 }}>
                                        <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h5" : "h6"}>B: </Typography>
                                        <Card variant="outlined" sx={{ flexGrow: 1, p: 1, ml: 1, cursor: "pointer", border: activeStep == 5 ? "2px solid white" : "", background: activeStep == 5 ? "#000000" : "#373737", boxShadow: "0px 0px 30px 8px #ffbd58" }}>

                                            <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h5" : "h6"} align="center">
                                                {findDiffs(originalText, finalText).map(diff =>

                                                    (diff.change == "") ?
                                                        <>{diff.original + " "}</> :
                                                         <mark style={{borderRadius:3,backgroundColor:"#f69421", p:1}}>{diff.change + " "}</mark>
                                                )}

                                            </Typography>
                                        </Card>


                                    </Box>
                                </Box>
                                : null}

                            {activeStep == 4 ?

                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", m: largeFrame ? 2 : 1, mt: largeFrame ? 1 : 0 }}>

                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                        <Box sx={{ height: "45px", display: "flex", alignItems: "center", justifyContent: "center", pointerEvents: "none" }} >
                                            <ButtonBase sx={{ height: "10%", width: "10%", m: 1 }}>
                                                <FirstPage sx={{ fill: "#999999" }} /></ButtonBase>
                                            <ButtonBase sx={{ m: 1 }}>
                                                <CaptionReplayIcon size={25} />
                                            </ButtonBase>
                                            <ButtonBase sx={{ height: "10%", width: "10%", m: 1 }} ><FirstPage sx={{ transform: "rotateY(180deg)", fill: "#999999" }} /></ButtonBase>
                                        </Box>
                                    </Box>
                                    <Box sx={{ flexGrow: 1 }} />
                                    <Box sx={{ flexGrow: .3, display: "flex", maxWidth: "300px" }}>
                                        <Grow in={true} timeout={2000}>
                                            <Button endIcon={smallFrame ? null : <Check />} variant="outlined" color="success" fullWidth
                                            //   onClick={(e) => handleCorrectFlag(e)}
                                            >
                                                Correct
                                            </Button></Grow>
                                    </Box>
                                    <Grow in={true} timeout={2000}>
                                        <Box sx={{ flexGrow: 1, ml: 1, display: "flex", alignItems: "center", maxWidth: "400px" }}>

                                            <Button
                                                sx={{ boxShadow: activeStep == 4 ? "0px 0px 30px 8px #ffbd58" : null }}
                                                color={flagTypes[0].color}
                                                fullWidth
                                                highlight
                                                endIcon={<Flag />}
                                            //  onClick={() => setToActionSide(true)}
                                            >
                                                {smallFrame ? "Wrong" : "This Is Wrong"}
                                            </Button>
                                        </Box></Grow>

                                </Box>
                                : null}


                            {activeStep == 5 ?
                                <Box sx={{ display: "flex", flexDirection: "row", m: largeFrame ? 2 : 1, mt: 0, justifyContent: "flex-start" }}>
                                    <Box sx={{ display: "flex", flexGrow: .2, mr: 1 }}>
                                        <Grow in={true} timeout={2000}>
                                            <Button
                                                fullWidth
                                                size="small"
                                                startIcon={<PlayArrow sx={{ transform: "rotate(180deg)" }} />}
                                                variant="outlined" >Back</Button>
                                        </Grow>
                                    </Box>
                                    <Box sx={{ display: "flex", flexGrow: 1 }}>
                                        <Grow in={true} timeout={2000}>
                                            <Button
                                                sx={{ boxShadow: activeStep == 5 ? "0px 0px 30px 8px #ffbd58" : null }}
                                                fullWidth
                                                size="small"
                                                highlight

                                            >Submit</Button>
                                        </Grow>
                                    </Box>
                                </Box>
                                : null}

                            {activeStep == 6 ?
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", m: smallFrame ? 1 : 2 }}>


                                    <Button variant="outlined" color="primary"
                                        endIcon={<ArrowForwardIos />}>
                                        Neither
                                    </Button>

                                </Box>
                                : null}
                        </>

                        {activeStep == 8 ?

                            <Box >

                                <Box className="barlessScroll" sx={{ display: "flex", flexDirection: "row", alignItems: "center", m: smallFrame ? 0 : 1, overflowX: "scroll", overflowY: "hidden", justifyContent: "flex-stretch" }}>

                                    <Avatar sx={{ height: smallFrame ? "40px" : "50px", width: smallFrame ? "40px" : "50px", m: 1 }} src="/Leon.jpg" />

                                    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "flex-start", m: 1 }}>

                                        <Typography variant={smallFrame ? "subtitle2" : largeFrame ? "h6" : "h6"}>leon from dubsee<IconButton size="small"
                                        ><Settings sx={{ fill: "#ffffff" }} /></IconButton> </Typography>
                                        <Typography variant={smallFrame ? "caption" : largeFrame ? "body1" : "body2"}>leon@dubsee.cc</Typography>
                                    </Box>


                                    <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                                        <Box>
                                            <Typography variant="h6" align="center">20</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="caption" align="center">votes</Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>

                                        <Box>
                                            <Typography variant="h6" align="center">50</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="caption" align="center">changes</Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                                        <Box>
                                            <Typography variant="h6" align="center">10</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="caption" align="center">accepted</Typography>
                                        </Box>
                                    </Box>

                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>

                                    {badges.map((badge, index) =>
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", m: smallFrame ? 0 : 1, opacity: (badge.conditionCurrent >= badge.conditionThreshold) ? 1 : .4 }}>
                                            <Box>
                                                <Tooltip title={badge.name + ": " + badge.description} placement="top" arrow>
                                                    {badge.icon}
                                                </Tooltip>
                                            </Box>
                                            {smallFrame ? null :
                                                <Box>
                                                    <Typography variant={smallFrame ? "caption" : "subtitle1"}> {badge.name}</Typography>
                                                </Box>}
                                        </Box>
                                    )}
                                </Box>

                                <Box sx={{ ml: 1, mr: 1, mt: 1, mb: .5, display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-strech" }}>

                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography style={{ textTransform: "uppercase" }} variant="caption">{nextBadge.conditionCurrent} / {nextBadge.conditionThreshold} {nextBadge.category}S
                                        </Typography>&nbsp;
                                    </Box>

                                    <Box sx={{ flexGrow: 1 }} />

                                    <Typography style={{ textTransform: "uppercase" }} variant="caption">{nextBadge.conditionThreshold - nextBadge.conditionCurrent} More {nextBadge.category}{(nextBadge.conditionThreshold - nextBadge.conditionCurrent) == 1 ? "" : "s"} to {nextBadge.name} </Typography>

                                </Box>

                                <LinearProgress sx={{ height: "12px" }} color="secondary" variant="determinate" value={nextBadge.conditionCurrent / nextBadge.conditionThreshold * 100} />
                                {/* </Card> */}

                            </Box>

                            : null}

                    </Card>
                </Grow>
            </Box>
        </ThemeProvider>
    )
}


{/* <Box sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }} slot="top-chrome" onClick={props.pause} >
                    <Box sx={{ m: 1 }}>
                        <Card elevation={0} style={{ background: "rgba(0,0,0,0.7)", width: "700px" }}>

                            <Box sx={{ display: "flex", m: 1, cursor: "pointer", alignItems: "top", justifyContent: "flex-stretch", }}
                            >

                                <img src={"/selected-logo.png"} style={{ height: "20px", width: "55px" }} />

                                <Box sx={{ flexGrow: 1 }} />

                                <Close sx={{ height: "25px", width: "25px", fill: "#999999" }} />

                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", m: 1 }}>

                                <Avatar sx={{ height: "50px", width: "50px", m: 1 }} src={"/Leon.jpg"} />


                                <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "flex-start", m: 1 }}>

                                    <Typography variant="h6">Leon Lyakovetsky</Typography>

                                    <Box sx={{ display: "flex", flexDirection: "column", mt: .2, mb: 1 }}>

                                        <Box>
                                            <Typography variant="caption">Your dubsee Profile</Typography>
                                        </Box>

                                    </Box>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "column", m:1}}>
                                    <Box>
                                        <Typography variant="h6" align="center">72</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption" align="center">updates submitted</Typography>
                                    </Box>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "column", m:1}}>
                                    <Box>
                                        <Typography variant="h6" align="center">63</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption" align="center">viewer accepted</Typography>
                                    </Box>
                                    </Box>
                
                                </Box>
                            
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center"}}>

                                    
                                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center"}}>

                                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", m:1}}>
                                                <LocalPolice sx={{height:"50px", width:"50px", m:1}} color="success"/> &nbsp;
                                                <Typography variant="body1"> Watchful Eye</Typography>
                                            </Box>

                                            <Box sx={{display:"flex", flexDirection:"column",alignItems:"center", m:1}}>
                                                <Verified sx={{height:"50px", width:"50px", m:1}} color="primary"/> &nbsp;
                                                <Typography variant="body1">Punctuation Pro</Typography>
                                            </Box>
                                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", m:1}}>
                                                <Loyalty sx={{height:"50px", width:"50px", m:1, fill:"rgba(255,255,255,.2)"}}/> &nbsp;
                                                <Typography sx={{color:"rgba(255,255,255,.2)"}} variant="body1">Spelling Superstar</Typography>
                                            </Box>

                                        
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", m:1}}>
                                                <School sx={{height:"50px", width:"50px", m:1}} color="secondary"/> &nbsp;
                                                <Typography variant="body1">Grammar Guru</Typography>
                                            </Box>
                                    
                                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", m:1}}>
                                                <MilitaryTech sx={{height:"50px", width:"50px", m:1, fill:"rgba(255,255,255,.2)"}}/> &nbsp;
                                                <Typography sx={{color:"rgba(255,255,255,.2)"}} variant="body1">Editor In Chief</Typography>
                                            </Box>
                                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", m:1}}>
                                                <Language sx={{height:"50px", width:"50px", m:1, fill:"rgba(255,255,255,.2)"}}/> &nbsp;
                                                <Typography sx={{color:"rgba(255,255,255,.2)"}} variant="body1">Translation Titan</Typography>
                                            </Box>
                                            </Box>

                                            <Box sx={{ flexGrow: 1 }} />

                                        </Box>            
                                    

                            <Box sx={{ml:1, mr: 1, mt:2, mb:.5, display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-strech" }}>
                            <Typography variant="caption">28 More Changes to Next Level </Typography>

                            <Box sx={{flexGrow:1}}/>
                            
                            <Box sx={{display:"flex", alignItems:"center"}}>
                            <Typography variant="caption">72/100
                            </Typography>&nbsp;
                            <MilitaryTech sx={{height:"15px", width:"15px"}} color="primary" />
                            </Box>
                            
                            </Box>

                            <LinearProgress sx={{height:"10px"}} color="secondary" variant="determinate" value={80} />

                        </Card>
                    </Box>

                </Box> */}


const MenuProps = {
    PaperProps: {
        style: {
            color: "white",
            background: "rgba(0,0,0,.8)",
        },

    }
};