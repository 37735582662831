import React, { useState } from 'react';
import { Modal, Button, Typography, TextField, Stepper, Step, StepLabel, ButtonBase, Dialog, Grow, Slide, Card, IconButton } from "@mui/material";
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/system';
import { ArrowBackIos, ArrowForward, ArrowForwardIos, Close, CloudDownload, Pause, PlayArrow } from '@mui/icons-material';

export function ErrorPanel(props) {

    const { height, width, YoutubeVideoId } = props;

    const [open, setOpen] = useState(true);

    const smallFrame = window.innerWidth < 650;
    const largeFrame = window.innerWidth > 1200;

    const themeOptions = {
        palette: {
            // mode: 'dark',
            primary: {
                main: '#edf9f9',
            },
            secondary: {
                main: '#ffbd58',
            },
            contrastThreshold: 4.5,
    
    
        },
        typography: {
            fontFamily: 'Inter, sans-serif',
            body1: {
                color: "white",
                fontSize: '.9rem',
            },
            h6: {
                color: "white",
                fontSize: '1.1rem'
            },
            h5: {
                color: "white",
                fontSize: '1.5rem'
            },
            caption: {
                color: "white",
            },
            body2: {
                color: "white",
            },
            subtitle1: {
                color: "white",
            },
            subtitle2: {
                color: "white",
            },
            h2: {
                color: "white",
                fontWeight: '400',
                fontSize: '1rem',
                '@media (min-width:750px)': {
                    fontSize: '1rem',
                }
            }
        },
        contrastThreshold: 4.5,
        contrastText: '#fff',
        overrides: {
            MuiButton: {
                raisedPrimary: {
                    color: 'white',
                },
            },
        }
    
    };
    
    const theme = responsiveFontSizes(createTheme(themeOptions));

    return (
        open ? 
        <ThemeProvider theme={theme}>
        
        <Box sx={{backdropFilter:"blur(8px)", width:width, height:height, cursor:"none", pointerEvents:"none"}}>

            <Box sx={{ m: 2, background: "rgba(25,25,25,.8)", borderRadius: 1, position: "absolute", bottom: 0, left: 0, display: "flex", flexDirection: "row", height: height * .15, maxHeight: "125px" }}>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", m: 2, flexGrow: 1 }}>
                    <Typography variant={smallFrame ? "caption" : "h6"} sx={{ color: "white", textAlign: "center" }}>
                    Sorry, there was a problem loading this video with its captions. 
                    </Typography>
                </Box>
                <Box sx={{flexGrow:1}}/>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", m: 2 }}>
                    <Box sx={{ flexGrow: .2 }}>
                        {/* <IconButton
                            size={largeFrame ? "large" : "small"}
                            color="secondary"
                            variant='outlined'
                            disabled={activeStep === 0}
                            onClick={handleBack}>
                            <ArrowBackIos sx={{ m: 2 }} />
                        </IconButton> */}
                    </Box>
                    <Box sx={{ flexGrow: 1,  pointerEvents:"auto" }}>
                        <Button 
                            size={largeFrame ? "large" : "small"}
                            endIcon={<PlayArrow/>}
                            variant="contained" color="error" onClick={() => window.open("https://youtu.be/" + YoutubeVideoId, "_blank")}>
                            Watch On Youtube
                        </Button>
                    </Box>
                </Box>
            </Box>
            </Box>
        </ThemeProvider> : null
    );
};

export default ErrorPanel;


