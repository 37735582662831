import React, { useEffect } from 'react';
import { Functions } from './Functions.js';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { CircularProgress, Divider, InputLabel, LinearProgress, TextField, Dialog, DialogTitle, DialogContent, CardActionArea, CardActions, CardMedia, Grid, CardContent, Link, Slider, ButtonBase, Skeleton } from '@mui/material';
import { ClosedCaption, Flag, Feedback, Edit, Check, AssistantPhotoTwoTone, ThumbUp, ThumbDown, ArrowUpward, ArrowDownward, Refresh, TheatersOutlined, Settings, Close, Pause, PlayArrow, VolumeOff, VolumeDown, VolumeUp, Fullscreen, FullscreenExit, LinkOff, Share, CheckCircle, Iron, YouTube } from '@mui/icons-material';
import moment from 'moment';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar } from '@mui/material';
import { Badge, IconButton, Select, MenuItem, Tab, Tabs, Tooltip } from '@mui/material';
import './Demo.css';
import TabPanel, { a11yProps } from './TabPanel';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import "@fontsource/electrolize"; // Defaults to weight 400.
import { ReactCardFlip } from 'react-card-flip';
import QuickModal from './QuickModal.js';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { GoogleOAuthProvider, GoogleLogin, googleLogout } from '@react-oauth/google';
import { useParams } from 'react-router';
import { gapi, loadGapiInsideDOM, loadAuth2, loadAuth2WithProps, loadClientAuth2, useGoogleLogin } from 'gapi-script';
import axios from 'axios';
import { CaptionFeedback, CaptionFeedbackButton, CaptionFeedBackSliderControlBar, CaptionFeedbackProvider, FeedbackChange, FeedbackFlag, FeedbackBallot, FlagTypeLabel, findDiffs } from './CaptionFeedbackforIframe';
import { Stage, Layer, Star, Text, Rect } from 'react-konva';
import { round } from 'lodash';
import Konva from 'konva';
import ErrorPanel from './ErrorPanel.js';
import config from '../config.js';
import ReactGA from 'react-ga4';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';

const ROOT = config.ROOT;
const version = config.VERSION;

const themeOptions = {
    palette: {
      mode: 'dark',
      primary: {
        main: '#edf9f9',
      },
      secondary: {
        main: '#f50057',
      },
      contrastThreshold: 4.5,

    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        button: {
        fontSize: "1rem",
      },
      h4: {
        color: "rgba(255,255,255,1)",
    },
    h5: {
        color: "rgba(255,255,255,1)",
    },
    h6: {
        color: "rgba(255,255,255,1)",
    },
    body1: {
        color: "rgba(255,255,255,1)",
    },
    body2: {
        color: "rgba(255,255,255,1)",
    },
    },
   
  };

  let theme = createTheme(themeOptions);
  theme = responsiveFontSizes(theme);

export function ChannelPage(props){


    return(
        <>

        <ThemeProvider theme={theme}>
            <Box sx={{overflowX:"hidden", height:"100vh", background:"rgba(25,25,25,1)" }}>

        <AppBar position="fixed" sx={{ background: "rgba(25,25,25,1)" }}>
            <Toolbar>
            <Typography
            variant="h4"
              letterSpacing={-1}
              fontWeight="600"
              fontFamily="Inter, sans-serif"
              color="#ffbd58"
            >
              dubsee
            </Typography>
            </Toolbar>
        </AppBar>

            <Box sx={{ display: "flex", flexDirection: "column"}}>
                    <Box sx={{p:1, mt:6}}>
                    <ChannelVideoProvider header footer/>
                    </Box>
           
            </Box>
            </Box>
        </ThemeProvider>
        </>
    )


}

export function ChannelVideoProvider(props) {

    const [videos, setVideos] = React.useState([]);
    const [channelData, setChannelData] = React.useState(null);

    const { chName } = useParams();

    const { num, header, footer } = props;

    const limit = num ? num : 50;

    const smallFrame = window.innerWidth < 600;

    React.useEffect(() => {

        async function getVideos() {
            let response = await Functions.channel.GetChannelVideosByURL(chName);
            setVideos(response.videos);
            setChannelData(response.channel);
        }

        getVideos();

    }   , []);


    return (
        <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>

            <Helmet>
                <meta name="description" content={channelData ? 
                `${channelData.snippet.title} is on dubsee - support your favorite creators with viewer-improved captioning for global accessibility and reach on YouTube`:
                "dubsee - viewer-improved captioning for global accessibility and reach - support your favorite YouTubers in just a click"}/> 
                <title>
                dubsee - 
                {channelData ? 
                ` ${channelData.snippet.title} on dubsee - ` : " "}
                support your favorite creators with viewer improved subtitles for global reach and accessibility on YouTube
                </title>
            </Helmet>


        {header && channelData ?
                <>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", m: 5, overflow: "hidden" }}>
                    <Avatar sx={{ width: 100, height: 100 }} alt={channelData.snippet.channelTitle} src={channelData.snippet.thumbnails.high.url} />
                    <Box sx={{ display: "flex", flexDirection:"column", justifyContent: "center", m: 2, alignItems:"flex-start" }}>
                
                        <ButtonBase onClick={() => window.open(`https://www.youtube.com/@${chName}`)}>
                        <Typography variant="h5" component="div" gutterBottom sx={{zIndex:2}}>
                            {channelData.snippet.title}
                        </Typography>
                        <Box sx={{ml: .5, pb:1, display:"flex", alignItems:"center"}}>
                        <YouTube sx={{height:45, width:45, fill:"#ff0000"}}/>
                        </Box>
                        </ButtonBase>     

                        <Typography variant="body1" component="div" gutterBottom sx={{zIndex:2, mt:-1}}>
                        Watch {channelData.snippet.customUrl} and improve captions & translations with      <Typography
            variant="body1"
              letterSpacing={-1}
              fontWeight="600"
              fontFamily="Inter, sans-serif"
              color="#ffbd58"
              component="span"
            >
              dubsee
            </Typography>
                        </Typography>       

                    </Box>
                </Box>
                 </>
       
        : null}

            <Box sx={{display:"flex", maxWidth:"1300px", margin:"0 auto"}}>
          <Grid container spacing={1} sx={{background:"rgba(25,25,25,1)"}}>
            {videos.length > 1 ?

            <>

            <Grid item sm={6} xs={12}>
                            <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-start", width: "100%", height: "auto", overflow: "hidden" }} >
                            <embed style={{width: "100%", height: smallFrame ? window.innerWidth * .56 : window.innerWidth/2 * .56, display: "block", margin: "0px", borderRadius:"10px"}} src={`${ROOT}/${videos[0].id}?embed=true`}/>

                        </Box>
          
            </Grid>
             <Grid item sm={6} xs={12}>
                 <CardActionArea onClick={() => window.open(ROOT + "/" + videos[0].id, "_blank")}> 

                             <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-start", width: "100%", height: "100%", overflow: "hidden" }} >
                         <Box sx={{ m:3 }}>
                         <Typography gutterBottom variant="h5" component="div">
                             Latest Video
                             </Typography>
                             <Typography  variant="h6" component="div">
                             {replaceHtmlEntites(videos[0].snippet.title)}
                             </Typography>
                             <Typography variant="body1" color="text.secondary">
                             {videos[0].snippet.channelTitle}
                             </Typography>
                         </Box>
                         </Box>

                         </CardActionArea>
                  
             </Grid>
             </>
               
            : null}

            {videos.slice(1).map((video) =>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <VideoCard video={video.snippet} id={video.id}/>
              </Grid>
            )}
          </Grid>
          </Box>
  
          {footer ?
          <Footer /> : null}

</Box>
  
  
    );
  }
  

  function VideoCard(props) {

    const { video, id } = props;
  
    return (
      <Card elevation={0} sx={{ width: "100%", height: "100%", background: "transparent" }}>
        <CardActionArea onClick={() => window.open(ROOT + "/" + id, "_blank")}>
          <Box sx={{ width: "100%", height: "auto", overflow: "hidden", borderRadius:"10px"  }} >
            <img
              src={video.thumbnails.high.url}
              alt={replaceHtmlEntites(video.title)}
              style={{ width: "100%", height: "auto", marginBottom: "-11%", marginTop: "-11%" }}
            />
          </Box>
          <Box sx={{ mt: 1, mb: 1 }}>
  
            <Typography gutterBottom variant="body1" component="div">
              {replaceHtmlEntites(video.title)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {video.channelTitle}
            </Typography>
          </Box>
        </CardActionArea>
      </Card>
    );
  }
  
  function Footer(props) {
  
    const smallFrame = window.innerWidth < 700;
  
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="xxl">
            <Box sx={{mt:2, mb:2, display:"flex"}}>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }} />
            <Box>
              <Typography variant="body2"> <Link sx={{ cursor: "pointer" }} onClick={() => window.open("https://dubsee.cc", "_blank")}>
                dubsee</Link> {!smallFrame ? "for Youtube" : ""} | v{version} alpha | © {new Date().getFullYear()} Podium Media </Typography>
            </Box>
            </Box>
       
        </Container>
      </ThemeProvider>
    );
  }
  

  const replaceHtmlEntites = (function() {
    var translate_re = /&(nbsp|amp|quot|lt|gt|#39);/g,
        translate = {
            'nbsp': String.fromCharCode(160), 
            'amp' : '&', 
            'quot': '"',
            'lt'  : '<', 
            'gt'  : '>',
            '#39' : "'"
        },
        translator = function($0, $1) { 
            return translate[$1]; 
        };

    return function(s) {
        return s.replace(translate_re, translator);
    };
})();