import React, { useEffect } from 'react';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { TextField, Button, Typography, IconButton, Link, LinearProgress, ButtonBase } from '@mui/material';
import { CheckCircle, LinkSharp, PlayArrow, PlayArrowOutlined, PlayCircle, Redo, Share, Undo } from '@mui/icons-material';
import config from '../config';
import { useParams } from 'react-router';
import { Functions } from './Functions.js';

// add channel name
const LINK_MESSAGE = "Help the channel by watching with dubsee: " ;

const themeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#edf9f9',
    },
    secondary: {
      main: '#ffbd58',
    },
    contrastThreshold: 4.5,

  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    button: {
      fontSize: "1.2rem",
    }
  },
};

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

export function YTChannelLinkGenerator() {

    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth * .8);
    const handleResize = () => setWindowWidth(window.innerWidth * .8);

    const { mode } = useParams();

    const oneLine = mode == "oneLine";

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", overflow:"hidden", background: "rgb(20,20,21)" }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "top", justifyContent: "center"}}>
                  <VideoLinkForm oneLine={oneLine} />
          </Box>
      </Box>
      </ThemeProvider>


  );
}

function VideoLinkForm(props) {
    const [link, setLink] = React.useState('');
    const [id, setId] = React.useState('');
    const [submitted, setSubmitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [url, setURL] = React.useState('');
    const { oneLine } = props;

    React.useEffect(() => {
        const videoId = extractVideoId(link);
        setId(videoId);
    }, [link]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      setSubmitted(true);
      setLoading(true);
      const videoId = extractVideoId(link);
      let url = await getURL(videoId);
      setURL(url);
      setLoading(false);
    };
  
    const extractVideoId = (url) => {
      const regex = /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:\S+)?/;
      const match = url.match(regex);
      if (match) {
        return match[1];
      }
      return '';
    };

    const width = window.innerWidth;
    const smallFrame = width < 600;

    const label = <Typography 
        variant={smallFrame ? "caption" : "h6"}
        color="primary"  sx={{background:"transparent"}}>
         
          Enter any video link from your YouTube channel
          
        </Typography>
  
    return (
      loading ?

      <Box sx={{ display: "flex", flexDirection:"column", alignItems: "top", justifyContent: "center", m: 2}}>

        <LinearProgress color="secondary" sx={{width:"100%", height:40}}/>

        <Typography variant="h6" color="primary" align="center" sx={{fontWeight:600, background:"transparent", m:2}}>
          Generating...
        </Typography>
      </Box>

      :

      submitted ? 
      <Box sx={{ display: "flex", flexDirection:"column", alignItems: "top", justifyContent: "center", m: 2}}>
         <Typography variant="body2" align="center" color="primary"  sx={{background:"transparent", m:2}}>
        Paste this link into your YouTube channel + video descriptions for your viewers!
        </Typography>

      <Box sx={{borderRadius: 1, border: 2, borderColor: "secondary.main"}}>
     
      <Typography variant={smallFrame ? "body1" : "h6"} align="center" color="primary"  sx={{background:"transparent", m:2}}>
       {LINK_MESSAGE}: <Link sx={{cursor:"pointer"}} onClick={() => window.open(`${config.ROOT}/@${url}`)}>
       <Typography variant={smallFrame ? "caption" : "h6"} color="primary" component="span"  sx={{fontWeight:600, color:"#ffbd58", background:"transparent"}}>
        {`${config.ROOT.replace("https://", "")}/@${url}`}
        </Typography>
        </Link>
        </Typography>

        </Box>

        <Box sx={{ display: "flex", flexDirection:"row", alignItems: "top", justifyContent: "center", m: 2}}>
          <Box sx={{m:1}}>
          <Button endIcon={<Undo/>} onClick={() => setSubmitted(false)}>Redo</Button>
          </Box>
          <Box sx={{m:1}}>
          <CopyLink link={`${LINK_MESSAGE}: ${config.ROOT}/@${url}`}/>
          </Box>
        </Box>
        </Box>

      :
        <form onSubmit={handleSubmit} style={{width:"100%"}}>
          {oneLine ? 
            
            <Box sx={{ display: "flex", flexDirection:"row", alignItems: "top", justifyContent: "center", m: 2}}>
                <Box sx={{flexGrow:1}}>
                    <TextField
                        label={label}
                        fullWidth
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    />
                </Box>
                <Box sx={{ml:1}}>
                  {smallFrame ?

                  <IconButton size="large" disabled={id == ""} variant="contained" color="secondary" type="submit">
                      <PlayCircle />
                  </IconButton>

                  :
                    <Button size="large" endIcon={<PlayArrow />} disabled={id == ""} fullWidth variant="contained" color="secondary" type="submit">
                        Go
                    </Button>}
                </Box>
            </Box>


          :
            <Box sx={{ display: "flex", flexDirection:"column", alignItems: "top", justifyContent: "center", m: 2}}>
                <Box sx={{width: "100%"}}>
                    <TextField
                        label={label}
                        fullWidth
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    />
                </Box>
                <Box sx={{ mt:1, width: "100%"}}>
                    <Button disabled={id == ""} fullWidth variant="contained" color="primary" type="submit">
                       Get Your Link
                    </Button>
                </Box>
            </Box>}
      </form>

   
    );
  }
  

  async function getURL(videoId){

    let host = config.CCC_URL;

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", config.CCC_API_KEY);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let endpoint = "/ccc?index=true&optimize=false&merge=true&vid=" + videoId + "&key=" + config.YOUTUBE_KEY_ROOT + "/" + videoId;

    let response = await fetch(host + endpoint, requestOptions);
    let res = await response.json();
    let videoData = res.metadata.snippet;
    let channelId = videoData.channelId; 

    let channelVideos = await Functions.channel.GetChannelVideosById(channelId);
    let channelURL = channelVideos.channel.snippet.customUrl.replace("@", "");

    await Functions.channel.SetChannelInfo(channelId, channelURL);

    return channelURL;
  
  }


  function CopyLink(props){
  
    const [copied, setCopied] = React.useState(false);
  
    const copyToClipboard = () => {
      navigator.clipboard.writeText(props.link);
      handleCopied();
    };
  
    const handleCopied = () => {
      setCopied(true);
      setTimeout(() => {
      setCopied(false);
      }, 3000);
    };
  
    return (
      <ThemeProvider theme={theme}>
        <Button variant="contained" color={copied ? "success" : "primary"} endIcon={copied ? <CheckCircle/> : <LinkSharp/>} onClick={copyToClipboard}>
          {copied ? "Link Copied" : "Copy"}
        </Button>
      </ThemeProvider>
    );
  };
  