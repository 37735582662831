import React, { useEffect } from 'react';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { TextField, Button } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import { VideoRecommendationProvider } from './DubseeLayer';
import { useParams } from 'react-router-dom';

export function VideoRecommender(props) {

    const { num } = useParams();

  return (

      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", background: "rgb(20,20,21)" }}>
          <Box sx={{ display: "flex", flexDirection: "column", height:"100%", alignItems: "center", justifyContent: "center", m: 2, overflow: "hidden" }}>
                  <VideoRecommendationProvider num={num}/>
          </Box>
      </Box>
  );
}