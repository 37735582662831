import React, { useEffect } from 'react';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { TextField, Button, Typography, IconButton } from '@mui/material';
import { PlayArrow, PlayArrowOutlined, PlayCircle } from '@mui/icons-material';
import config from '../config';
import { useParams } from 'react-router';

const themeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#edf9f9',
    },
    secondary: {
      main: '#ffbd58',
    },
    contrastThreshold: 4.5,

  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    button: {
      fontSize: "1.2rem",
    }
  },
};

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

export function YTLink() {

    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth * .8);
    const handleResize = () => setWindowWidth(window.innerWidth * .8);

    const { mode } = useParams();

    const oneLine = mode == "oneLine";

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", overflow:"hidden", background: "rgb(20,20,21)" }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "top", justifyContent: "center"}}>
                  <YoutubeForm oneLine={oneLine} />
          </Box>
      </Box>
      </ThemeProvider>


  );
}

function YoutubeForm(props) {
    const [link, setLink] = React.useState('');
    const [id, setId] = React.useState('');
    const { oneLine } = props;

    React.useEffect(() => {
        const videoId = extractVideoId(link);
        setId(videoId);
    }, [link]);

    const handleSubmit = (e) => {
      e.preventDefault();
      const videoId = extractVideoId(link);
      let url = new URL(link);
      let timeStamp = new URLSearchParams(url.search).get("t");
      let urlValues = videoId;
      if(timeStamp) {
        urlValues += `?t=${timeStamp}`;
      }
      window.open(config.ROOT + `/${urlValues}`, '_blank');
    };
  
    const extractVideoId = (url) => {
      // const regex = /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:\S+)?/;
      const regex = /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:shorts\/)?(?:embed\/|v\/|watch\/?\?(?:.+&)?v=|shorts\/)?)([^\s&?]{11})/;
      const match = url.match(regex);
      if (match) {
        return match[1];
      }
      return '';
    };

    const width = window.innerWidth;
    const smallFrame = width < 600;

    const label = <Typography 
        variant={smallFrame ? "caption" : "h6"}
        color="primary"  sx={{background:"transparent"}}>
         
          Enter a YouTube Link to
           <Typography
                          variant={smallFrame ? "caption" : "h6"}
                          letterSpacing={-1}
                          fontWeight="600"
                          fontFamily="Inter, sans-serif"
                          color="#ffbd58"
                          component={"span"}
                        >
                          &nbsp;dubsee&nbsp;
                        </Typography>any YouTube Video 
        </Typography>
  
    return (
        <form onSubmit={handleSubmit} style={{width:"100%"}}>
          {oneLine ? 
            
            <Box sx={{ display: "flex", flexDirection:"row", alignItems: "top", justifyContent: "center", m: 2}}>
                <Box sx={{flexGrow:1}}>
                    <TextField
                        label={label}
                        fullWidth
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    />
                </Box>
                <Box sx={{ml:1}}>
                  {smallFrame ?

                  <IconButton size="large" disabled={id == ""} variant="contained" color="secondary" type="submit">
                      <PlayCircle />
                  </IconButton>

                  :
                    <Button size="large" endIcon={<PlayArrow />} disabled={id == ""} fullWidth variant="contained" color="secondary" type="submit">
                        Go
                    </Button>}
                </Box>
            </Box>


          :
            <Box sx={{ display: "flex", flexDirection:"column", alignItems: "top", justifyContent: "center", m: 2}}>
                <Box sx={{width: "100%"}}>
                    <TextField
                        label={label}
                        fullWidth
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    />
                </Box>
                <Box sx={{ m: 1, width: "100%"}}>
                    <Button endIcon={<PlayArrow />} disabled={id == ""} fullWidth variant="contained" color="primary" type="submit">
                        Go To Video
                    </Button>
                </Box>
            </Box>}
      </form>
   
    );
  }
  
  