import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:{xs: 400, sm: 500, md: 600, lg: 700, xl: 800},
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    justifyContent:"center"
  };

export default function QuickModal(props) {
  const handleOpen = () => props.setOpen(true);
  const handleClose = () => props.setOpen(false);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
      >
        <Box sx={style}>
          <Box sx={{width:"100%"}}>
          {props.children}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}