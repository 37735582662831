import config from '../config';


var myHeaders = new Headers();
myHeaders.append("x-api-key", config.CCF_API_KEY);
myHeaders.append("Content-Type", "text/plain");

const GETrequestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

const POSTrequestOptions = (req) => { 
  return {
  method: 'POST',
  headers: myHeaders,
  body: req,
  redirect: 'follow'
  }
};

  export const Functions = {
    auth : {
      updateTokens: function (tokens) { return updateTokens(tokens) },
      getCookie: function (cname) { return getCookie(cname) },
    },
    data:{
      createCCF: function (key, type, language, publishUrl, ownerUuid, chId) { return createCCF(key, type, language, publishUrl, ownerUuid, chId) },
      getCCFByKey: function (key, chId) { return getCCFByKey(key, chId) },
      getCCFData: function(ccfId){ return getCCFData(ccfId); },
      updateCCFSettings: function(ccfId, rules){ return updateCCFSettings(ccfId, rules); 
      },
      getFeedbackItem: function(ccfId, itemId){ return getFeedbackItem(ccfId, itemId); },
      exportCaptions: function(id, type, lang){ return exportCaptions(id, type, lang); },
      getChannelData: function(id){ return getChannelData(id); },
    },
    item: {
        createFlag: function (cueId, userId, ccfId, language, type) { return createFlag(cueId, userId, ccfId, language, type) },
        getFeedbackItems: function (ccfId, langauge) { return getFeedbackItems(ccfId, langauge) },
        addChange: function (item, userId, ccfId, text) { return addChange(item, userId, ccfId, text) },
        createChange : function (cueId, userId, ccfId, type, text, lang) { return createChange(cueId, userId, ccfId, type, text, lang) },
    },
    vote: {
        upVote: function (feedbackItem, userId, ccfId) { return upVote(feedbackItem, userId, ccfId) },
        downVote: function (feedbackItem, userId, ccfId) { return downVote(feedbackItem, userId, ccfId) },
    },
    user: {
        getUser: function (uuid) { return getUser(uuid) },
        getUserStats: function (uuid) { return getUserStats(uuid) },
        createUser: function (name, email, provider) { return createUser(name, email, provider) },
        updateUserSettings: function (uuid, settings) { return updateUserSettings(uuid, settings) },
        updateUserLanguages: function (uuid, preferred, known) { return updateUserLanguages(uuid, preferred, known) },
        getUserByEmail: function (name, email, provider) { return getUserByEmail(name, email, provider) },
        setUserOnboarded: function (uuid) { return setUserOnboarded(uuid) },
    },
    channel:{
      GetChannelVideosByUser: function(user){ return GetChannelVideosByUser(user); },
      GetChannelVideosById: function(cid){ return GetChannelVideosById(cid); },
      SetChannelInfo: function(cid, user){ return SetChannelInfo(cid, user); },
      GetChannelVideosByURL: function(url){ return GetChannelVideosByURL(url); },
    }


}

async function SetChannelInfo(cid, user){

  var myHeaders = new Headers();
  myHeaders.append("x-api-key", config.CCC_API_KEY);


  var requestOptions = {
    method: 'PUT',
    redirect: 'follow',
    headers: myHeaders

  };

  const response = await fetch(config.CCC_URL +  "/cch" + "?cid=" + cid + "&url=" + user, requestOptions);
  const result = await response.json();
  console.log(result);
  return result;

}

async function GetChannelVideosById(cid){

  var myHeaders = new Headers();
  myHeaders.append("x-api-key", config.CCC_API_KEY);


  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: myHeaders

  };

  const response = await fetch(config.CCC_URL +  "/cch" + "?cid=" + cid, requestOptions);
  const result = await response.json();
  console.log(result);
  return result;
}

async function GetChannelVideosByUser(user){

  var myHeaders = new Headers();
  myHeaders.append("x-api-key", config.CCC_API_KEY);


  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: myHeaders

  };

  const response = await fetch(config.CCC_URL +  "/cch" + "?user=" + user, requestOptions);
  const result = await response.json();
  console.log(result);
  return result;
}

async function GetChannelVideosByURL(url){

  var myHeaders = new Headers();
  myHeaders.append("x-api-key", config.CCC_API_KEY);


  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: myHeaders

  };

  const response = await fetch(config.CCC_URL +  "/cch" + "?url=" + url, requestOptions);
  const result = await response.json();
  console.log(result);
  return result;
}

async function updateTokens(tokens){
 return (
  null
 ) 
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

//create new ccf file id
//refresh ccf version with new file id 

async function getChannelData(id){
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };


  // let accessToken = await getGoogleAccessToken(getCookie("refresh_token"));

  // const d = new Date();
  //   d.setTime(accessToken.expiry_date);
  //   let expires = "expires=" + d.toUTCString();
  // document.cookie = "access_token=" + accessToken.access_token.toString() + "; expires=" + expires + "; path=/";


  // const response = await fetch("https://www.googleapis.com/youtube/v3/channels?part=snippet,contentOwnerDetails,contentDetails&id=" + id + "&key=" + GOOGLE_API_KEY, requestOptions);

  // //need to refresh google access token and then get channel data
   
  // const response = await fetch("https://www.googleapis.com/youtube/v3/channels?managedByMe=true&part=snippet,contentOwnerDetails,contentDetails&access_token=" + getCookie("access_token"), requestOptions);

  // const data = await response.json();
  // console.log(data);
  // return data;
}

async function exportCaptions(id, type,language){
  console.log("EXPORTING CAPTIONS");

  var myHeaders = new Headers();
  myHeaders.append("x-api-key", config.CCC_API_KEY);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  const response = await fetch(config.CCC_URL +  "/ccc/" + "?vid=" + id + "&key=" + config.YOUTUBE_KEY_ROOT + "/" + id + "&format=" + type + "&optimize=false&store=true&merge=true&target=" + language , requestOptions);
  console.log(response);
  const data = await response.json();
  console.log(data);
  return data.url;
}

async function createUser(name, email, provider) {

  var req = {
    key: email,
    name: name,
    provider: provider
  }

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(req),
    redirect: 'follow'
  };

  const response = await fetch(config.CCF_URL + "/user", requestOptions);
  const res = await response.json();
  return res;

}

async function setUserOnboarded(uuid) {

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    redirect: 'follow'
  };

  const response = await fetch(config.CCF_URL + "/user/" + uuid + "?onboarded=true", requestOptions);
  const res = await response.json();
  return res.uuid;

}

async function updateUserLanguages(uuid, preferred, known) {

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify({preferredLanguage : preferred, knownLanguages : known})
  };

  const response = await fetch(config.CCF_URL + "/user/" + uuid, requestOptions);
  const res = await response.json();
  console.log(res);
  return res;

}

async function updateUserSettings(uuid, settings) {

  const requestOptions = { 
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify({settings : settings}),
    redirect: 'follow'
  };

  const response = await fetch(config.CCF_URL + "/user/" + uuid, requestOptions);
  const res = await response.json();
  return res.uuid;

}


async function createCCF(key, type, language, publishUrl, ownerUuid, chId) {

  let req = {
    key: key,
    type: type,
    language: language,
    publishUrl: key,
    ownerUuid: ownerUuid,
    settings : {automation : {state : "off"}},
    account: chId
  };

  console.log(req);

  const request = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(req),
    redirect: 'follow'
  };

  const response = await fetch(config.CCF_URL +  "/ccf"
  // ?key=" + key + "&type=" + type + "&language=" + language + "&publishUrl=" + publishUrl + "&ownerUuid=" + ownerUuid 
  , request);
  const data = await response.json();
  console.log("ccf created", data.uuid);
  return data.uuid;

}

async function getCCFByKey(key, chId, initalizeFunction) {
  console.log("GETTING ALL CCF DATA FOR KEY", key);
  // console.log("OWNER UUID", ownerUuid);
  const response = await fetch(config.CCF_URL +  "/ccf" + "?key=" + key, GETrequestOptions);
  console.log(response);
  const data = await response.json();
  
  if (data.length == 0){
    let res = await createCCF(key, "vtt", "en_us", key, config.CCF_ADMIN_ID, chId);
    console.log(res);
    await initalizeFunction();
    return res;
  }
  else{
  return data[0];
  }
}

async function getCCFData(ccfId) {
  console.log("GETTING ALL CCF DATA FOR FILE", ccfId);

  const response = await fetch(config.CCF_URL +  "/ccf/" + ccfId  + "?mode=detail", GETrequestOptions);
  const data = await response.json();
  return data;

}

async function updateCCFSettings(ccfId, rules) {

  console.log("UPDATING CCF SETTINGS TO FILE", ccfId);


  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(rules),
    redirect: 'follow'
  };

  const response = await fetch(config.CCF_URL +  "/ccf/" + ccfId, requestOptions);
  const data = await response.json();
  console.log("ccf updated");
  return data;

}

async function createFlag(cueId, userId, ccfId, language, type) {

  console.log("CREATING FLAG TO FEEDBACK ITEM TO FILE", ccfId);

  var req = {
    key: cueId,
    flag: {
      type: type
    },
    language: language,
    ownerUuid: userId ? userId : config.CCF_ADMIN_ID,
    type: "flag"
  }

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(req),
    redirect: 'follow'
  };

  const response = await fetch(config.CCF_URL + "/ccf/" + ccfId + "/item/", requestOptions);
  const res = await response.json();
  console.log(res.uuid);

}

async function createChange(cueId, userId, ccfId, type, text, lang) {

  console.log("CREATING FLAG TO FEEDBACK ITEM TO FILE", ccfId);

  var req = {
    key: cueId,
    flag: {
      type: type
    },
    change : {
      text : text,
      type: "update",
    },
    language:lang,
    ownerUuid: userId
  }

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(req),
    redirect: 'follow'
  };

  const response = await fetch(config.CCF_URL + "/ccf/" + ccfId + "/item/", requestOptions);
  const res = await response.json();
  console.log(res.uuid);

}

async function addChange(item, userId, ccfId, text) {

  console.log("ADDING CHANGE TO FEEDBACK ITEM", item.uuid);

  var req = {
    ownerUuid: "b063e147-953c-5a2a-b983-6f9f6e4dee46",
    change : {
            text : text,
            type: "update",
            // start: "00:00:04,509",
            // end: "00:00:04,509"
    }
}

  const requestOptions = { 
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(req),
    redirect: 'follow'
  };

  const response = await fetch(config.CCF_URL + "/ccf/" + ccfId + "/item/" + item.uuid, requestOptions);
  const res = await response.text();
  console.log(res);

}


async function getFeedbackItem(ccfId, itemId){
  ///will become array!

  console.log("GET FEEDBACK ITEM", itemId);
  const response = await fetch(config.CCF_URL + "/ccf/" + ccfId + "/item/" + itemId, GETrequestOptions);
  
  const res = await response.json();
  console.log(res);  
  return res; 
    
}


async function getFeedbackItems(ccfId, language, known){
  ///will become array!
  console.log("GETTING ALL FEEDBACK ITEMS" , ccfId);
  console.log("IN THE LANGUAGE", language);

  let endpoint = "/ccf/" + ccfId + "?mode=detail";
  // if (langauge){
  //   endpoint = endpoint + "&language=" + langauge;
  // }

  const response = await fetch(config.CCF_URL + endpoint, GETrequestOptions);
  const res = await response.json();
  const items = [];
  Object.values(res.items).forEach(item => items.push(item));
  if (!known){ known = [];}
  const filteredItems = items.filter(item => (item.language == language || known.includes(item.language)));
  return filteredItems; 
    
}


async function upVote(item, userId, ccfId){

var req = "";

  const response = await fetch(config.CCF_URL + "/ccf/" + ccfId + "/item/" + item.uuid + "/vote?vote=up&ouuid=" + userId , POSTrequestOptions(req));
  const res = await response.text();
  console.log(res.text);

}

async function downVote(item, userId, ccfId){
  var req = "";

  const response = await fetch(config.CCF_URL + "/ccf/" + ccfId + "/item/" + item.uuid + "/vote?vote=down&ouuid=" + userId , POSTrequestOptions(req));
  const res = await response.text();
  console.log(res.text);

}

async function getUser(uuid){

const response = await fetch(config.CCF_URL + "/user/" + uuid, GETrequestOptions);
const res = await response.json();
console.log(res);

return res;
}

async function getUserStats(uuid){

  const response = await fetch(config.CCF_URL + "/user/" + uuid, GETrequestOptions);
  const res = await response.json();
  return res.stats;
  }

async function getUserByEmail(name, email, provider){

  const response = await fetch(config.CCF_URL + "/user?key=" + email, GETrequestOptions);
  const res = await response.json();


  if (res.code && res.code == "not_found"){
    return await createUser(name, email, provider);
  }
  else{

  return await getUser(res.uuid);
  }
  }
    
  


// //PSEUDO CODE

// const { CaptionFeedBackState } = CaptionFeedBackStateObject;

// <MediaController>

//   <CaptionFeedback />

//   <video
//     slot="media"
//     preload="auto"
//     muted
//   >

//     <source src="{VIDEO_ID}" type="video/mp4" />
    
//     <track
//       label="English"
//       kind="captions"
//       srclang="en"
//       src="demo/{VIDEO_ID}.vtt"
//       default />
      
//   </video>

//   <MediaControlBar>
//     <MediaPlayButton />
//     <MediaTimeRange />
//     <MediaTimeDisplay showDuration></MediaTimeDisplay>
//     <MediaCaptionsButton />
//     <MediaMuteButton />
//     <MediaVolumeRange />

//     <CaptionFeedbackButton />

//   </MediaControlBar>

// </MediaController> 

