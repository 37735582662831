import React from 'react';
import {
  MediaController,
  MediaControlBar,
  MediaTimeRange,
  MediaTimeDisplay,
  MediaVolumeRange,
  MediaPlayButton,
  MediaSeekBackwardButton,
  MediaSeekForwardButton,
  MediaMuteButton,
  MediaCaptionsButton,
} from 'media-chrome/dist/react';
import { Functions } from './Functions.js';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Chip, CircularProgress, Divider, Grid, InputLabel, LinearProgress, TextField } from '@mui/material';
import { ClosedCaption, Flag, Feedback, Edit, Check, AssistantPhotoTwoTone, ThumbUp, ThumbDown, ArrowUpward, ArrowDownward, Refresh, TheatersOutlined, Settings, Close, ViewAgenda, RateReview, Pin, Place } from '@mui/icons-material';
import moment from 'moment';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar } from '@mui/material';
import { Badge, IconButton, Select, MenuItem, Tab, Tabs, Tooltip } from '@mui/material';
import './Demo.css';
import { CaptionFeedback, CaptionFeedbackButton, CaptionFeedBackSliderControlBar, CaptionFeedbackProvider, FeedbackChange, FeedbackFlag, FeedbackBallot, FlagTypeLabel, findDiffs } from './CaptionFeedback';
import TabPanel, { a11yProps } from './TabPanel';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import "@fontsource/electrolize"; // Defaults to weight 400.
import { ReactCardFlip } from 'react-card-flip';
import QuickModal from './QuickModal.js';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useParams } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import vttToJson from 'vtt-to-json';
import axios from 'axios';
const version = "1.0.8";

const themeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#edf9f9',
    },
    secondary: {
      main: '#f50057',
    },
    contrastThreshold: 4.5,

  },
  typography: {
    fontFamily: 'Electrolize, sans-serif',
    button: {
      fontSize: "1rem",
    },
    body1: {

      color: "#ffffff"
    }
  },
};

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

const viewers = [
  { uuid: "f4373b34-dc37-51ac-a80a-2995da873b35", picture: "/Kevin.jpg", name: "Kevin" },
  { uuid: "c6794804-3e6e-5764-9d86-e0a491e5384f", picture: "/Jane.jpg", name: "Jane" },
  { uuid: "d42e511a-0f9b-5eb2-8e7d-8a3c31b9bb3e", picture: "/Katie.jpg", name: "Katie" },
  { uuid: "b063e147-953c-5a2a-b983-6f9f6e4dee46", picture: "/Leon.jpg", name: "Leon" },
];

function DemoPlayer(props) {

  const { player, textTracks, ccfId, file, videoSrc } = props;
  const [loaded, setLoaded] = React.useState(false);

  return (
    <>
      {loaded ? null :
        <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "50%", background: "rgba(0,0,0,.6)", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Box>}

      <MediaController>

        <video
          disablePictureInPicture
          disableRemotePlayback
          id="player"
          style={{ display: loaded ? "inline" : "none", width: "100%", height: "56%", zIndex: 1 }}
          ref={player}
          slot="media"
          preload="auto"
          muted
          playsInline
          crossOrigin='anonymous'
          onLoadedMetadata={() => {
            setLoaded(true);
            props.handlePlayerLoad();
          }}
        >

          <source src={videoSrc} type="video/mp4" />

          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src={file}
            default
          />

        </video>

        {loaded ?
          <CaptionFeedbackProvider
            userId={props.userId}
            player={player}
            ccfId={ccfId}
            textTracks={textTracks}
          /> : null}

      </MediaController>
    </>
  );
}


export function Demo(props) {

  return (
    <DemoDataProvider {...props}>
      <DemoViewer />
    </DemoDataProvider>
  );
}

export function Log(props) {
  return (
    <DemoDataProvider {...props}>
      <DemoLogger />
    </DemoDataProvider>
  );
}

export function DemoDataProvider(props) {

  const { fileName } = useParams();

  const root = "https://dubsee-assets.s3.amazonaws.com/";

  const file = "https://dubsee-assets.s3.amazonaws.com/" + fileName + ".vtt";
  const videoSrc = "https://dubsee-assets.s3.amazonaws.com/" + fileName + ".mp4";

  const player = React.useRef(null);
  const [userId, setUserId] = React.useState(viewers[0].uuid);
  const [data, setData] = React.useState({ events: [], items: {} });
  const [events, setEvents] = React.useState([]);
  const [feedbackItems, setFeedbackItems] = React.useState([]);
  const [votes, setVotes] = React.useState([]);
  const [textTracks, setTextTracks] = React.useState({});
  const [userData, setUserData] = React.useState({});
  const [settings, openSettings] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [ccfId, setCCfId] = React.useState("");
  // const [version, setVersion] = React.useState(1);

  const API_KEY = "wjywIGFcLaaKISn8JPGER3oP5nSW3FqF9it4GYeK";

  const socketUrl = "wss://9bietn5lcf.execute-api.us-east-1.amazonaws.com/test";
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl,
    {
      queryParams: { "x-api-key": API_KEY, "fuuid": ccfId },
      onOpen: () => console.log('opened'),
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: 5,
    });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  React.useEffect(() => {
    console.log("Connection Status: " + connectionStatus);

  }, [connectionStatus]);

  React.useEffect(() => {
    loadViewingSession();
  }, []);

  React.useEffect(() => {

    const liveUpdate = async () => {
      if (lastMessage !== null) {
        let event = JSON.parse(lastMessage.data);
        console.log("Feedback Item Updated: " + event.uuid)
        if (event.fuuid == ccfId) {
          let items = {};
          items[event.iuuid] = await Functions.data.getFeedbackItem(ccfId, event.iuuid);
          event.uuid = event.iuuid;
          console.log(items);
          setFeedbackItems((prevState) => ({ ...prevState, ...items }));
          setEvents((prevState) => ([...prevState, event]));
          if (event.action === "vote") {
            setVotes((prevState) => ([...prevState, event]));
          }
          loadUser();
        }
      }
    }

    liveUpdate().catch(console.error);

  }, [lastMessage]);

  React.useEffect(() => {
    loadViewingSession();
  }, [userId])

  const loadViewingSession = React.useCallback(() => {

    const fetchData = async () => {
      setLoaded(false);

      console.log(userId);

      let ccfId = await Functions.data.getCCFByKey(root + fileName, userId);
      setCCfId(ccfId);

      const data = await Functions.data.getCCFData(ccfId.toString());
      console.log("Fetching Data");
      setData(data);
      console.log(data.items);
      setFeedbackItems(data.items);
      loadUser();
      setVotes(data.events.filter(event => event.action === "vote"));
      setEvents(data.events);

      setLoaded(true);

    }
    fetchData().catch(console.error);

  }, []);


  const loadUser = async () => {
    let user = await Functions.user.getUser(userId);
    setUserData(user);
  }

  const handleViewerChange = (event) => {
    console.log(event.target.value);
    setUserId(event.target.value);
  }

  const handlePlayerLoad = () => {
    console.log("LOADING");

    let textTrack = player.current.textTracks[0];
    setTextTracks(textTrack);

  };

  const handleSeek = (time) => {
    if (player) {
      player.current.currentTime = time + 0.1;
    }
  }

  console.log("DEMO DATA PROVIDER REFRESH");

  return (
    <ThemeProvider theme={theme}>

      <Box sx={{ height: "100vh", width: "100vw", background: "rgba(0,0,0,.9)", overflow: "hidden", justifyContent: "center", zIndex: 10 }}>

        <DemoAppBar />

        {React.cloneElement(props.children,
          {
            ...props,
            data,
            userId,
            loadViewingSession,
            handlePlayerLoad,
            textTracks,
            feedbackItems,
            events,
            votes,
            handleSeek,
            player,
            textTracks,
            viewers,
            userData,
            loaded,
            handleViewerChange,
            settings,
            openSettings,
            ccfId,
            file,
            videoSrc,
            fileName
          }
        )}
      </Box>
    </ThemeProvider>

  );
}

export function DemoViewer(props) {

  const { data, userId, loadViewingSession, handlePlayerLoad, loaded, handleViewerChange, fileName, settings, openSettings, player, textTracks, viewers, userData, ccfId, file, videoSrc } = props;

  return (
    <>
      <Card variant="outlined" sx={{ m: 2, zIndex: 10 }}>
        <>
          {!loaded ? <LinearProgress /> : null}

          <Box sx={{ mt: 2, ml: 3, mr: 3, display: "flex", flexDirection: "row", width: "100%" }}>
            {userId && Object.keys(userData).length > 0 ?
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Typography align="center"> Demo Controls
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", mb: 2 }}>
                  <Box>
                    <InputLabel id="demo-simple-select-label" variant="standard">Active Viewer</InputLabel>
                    <Select
                      value={userId}
                      label="Demo Users"
                      onChange={handleViewerChange}
                    >
                      {viewers.map((viewer, index) =>
                        <MenuItem value={viewer.uuid}>
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Avatar src={viewer.picture} sx={{ width: 40, height: 40 }} />
                            <Box sx={{ ml: 2 }}>{viewer.name}</Box>
                          </Box>
                        </MenuItem>)}
                    </Select>
                    <Box sx={{ mt: 1 }}>
                      {/* <Typography variant="h6">Stats</Typography> */}
                      <Typography>Feedback: {userData.history ? userData.history.filter((item) => item.file == ccfId).length : null}
                      </Typography>
                      <Typography>Votes: {userData.history ? userData.history.filter((item) => (item.file == ccfId && ["vote-up", "vote-down"].includes(item.action))).length : null}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ flexGrow: 1 }} />
                  <Box sx={{ mr: 6, display: "flex", alignItems: "center" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                      <Typography variant="body1">/{file.split("/")[file.split("/").length-1]}</Typography>
                      {/* <Button size="small" onClick={() => {setVersion(version+1); loadViewingSession();}} >CCF Version {version}</Button> */}
                      <QuickModal open={settings} setOpen={openSettings}>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", color: "white" }}>
                          <Settings /> &nbsp;&nbsp;
                          <Typography variant="h5">Settings</Typography>
                          <Box sx={{ flexGrow: 1 }} />
                          <IconButton onClick={() => openSettings(false)}><Close /></IconButton>
                        </Box>
                        <JSONPretty onJSONPrettyError={e => console.error(e)} id="json-pretty" data={data.settings} style={{ fontSize: "1.1rem" }} />
                        </QuickModal>
                      <Button onClick={() => openSettings(true)} endIcon={<Settings />}>Settings</Button>
                      <Button variant="outlined" color="primary" endIcon={<Refresh />} onClick={loadViewingSession}>Reload</Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              : null}
            <Box sx={{ flexGrow: 1 }} />
          </Box>
        </>
      </Card>

      <Box sx={{
        width: "100%", mt: 3, position: "relative", justifyContent: "center", display: "flex", p: 1, zIndex: 10, background: "#000000"

      }}>
        <div style={{ width: "100%", position: "relative", maxWidth: "1800px", zIndex: 10 }} >

          <img src="/laptop.png" style={{
            width: "98%", position: "relative",
            zIndex: 10, pointerEvents: "none"
          }} />

          <div style={{ position: "absolute", top: "10%", left: "13%", width: "72.5%", zIndex: 1 }}>
            {userId && Object.keys(userData).length > 0 ?
              <DemoPlayer videoSrc={videoSrc} file={file} player={player} userId={userId} data={data} loadViewingSession={loadViewingSession} handlePlayerLoad={handlePlayerLoad} textTracks={textTracks} ccfId={ccfId} />
              : null}
          </div>
        </div>
      </Box>

    </>
  )
}

function DemoLogger(props) {

  const { data, userId, loadViewingSession, handleSeek, feedbackItems, file, videoSrc } = props;
  const [statusView, setStatusView] = React.useState(0);
  const [textTracks, setTextTracks] = React.useState(null);
  const player = React.useRef(null);

  React.useEffect(() => {

    if (player) {
      setTextTracks(player.current.textTracks[0]);
    }

  }, [player]);

  const getAllItems = () => {
    return Object.entries(feedbackItems).sort(function (a, b) {
      return textTracks.cues[a[1].key - 1].startTime - textTracks.cues[b[1].key - 1].startTime
    });
  };


  const getItemsByStatus = (status) => {
    return Object.entries(feedbackItems).filter(([key, item]) => item.status === status).sort(function (a, b) {
      return textTracks.cues[a[1].key - 1].startTime - textTracks.cues[b[1].key - 1].startTime
    });
  };

  const getStatus = (index) => {
    return index == 0 ? "open" : index == 1 ? "rejected" : "accepted";
  };

  console.log("textTracks", textTracks);

  return (
    <>
      <div style={{ display: "none" }}>
        <video preload="auto"
          ref={player} crossOrigin='anonymous'>
          <source src={null} />
          <track kind="subtitles" srcLang="en" label="English" src={file} default />
        </video>
      </div>

      {(textTracks ? textTracks.cues ? textTracks.cues.length > 0 : false : false) ?
        <>
          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mt: 2 }}>
            <Box >
              <Typography sx={{ color: "#ffffff" }} variant="h6" align="center">Feedback Log</Typography>
            </Box>
          </Box>

          <Box sx={{ flex: 1, overflowY: "scroll", height: "100%", width: "100%", justifyContent: "center" }}>

            {/* {Array.from(new Set(getItemsByStatus(getStatus(statusView)).map(([key, item]) => item.key))).map((cueKey) => */}

            {Array.from(new Set(getAllItems().map(([key, item]) => item.key))).map((cueKey) =>
              <CueItem
                getAllItems={getAllItems}
                getItemsByStatus={getItemsByStatus}
                getStatus={getStatus}
                statusView={statusView}
                cueKey={cueKey}
                cue={textTracks.cues[cueKey - 1]}
                handleSeek={handleSeek}
                player={player}
                {...props}
              />

            )}
          </Box>
        </>
        : null}
    </>
  );
}

function CueItem(props) {

  const { cue, handleSeek, getItemsByStatus, getAllItems, getStatus, statusView, cueKey, events, votes } = props;
  const cueRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", width: "95%", mt: 1, justifyContent: "center" }}>
        <Box sx={{ m: 1, mr: 2, ml: 2, flexDirection: "row", display: "flex", borderRadius: "5px", cursor: "pointer" }} onClick={() => handleOpen()}>
          <div style={{ position: "relative", width: "100%" }}>
            <Box sx={{ m: 2, display: "flex", flexDirection: "column" }}>
              <Box sx={{
                p: 2,
                cursor: "pointer",
                background: open ? "rgba(155,155,155,.6)" : "rgba(0,0,0,.6)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                borderRadius: "5px",
                width: "100%"
              }}>
                <Typography variant="h6" color="primary" align="center">{cue.text}</Typography>
              </Box>
              {/* {Array.from(new Set(getItemsByStatus(getStatus(statusView)).filter(([key, item]) => item.key == cueKey))).map(([key, item], index) => */}
              {Array.from(new Set(getAllItems().filter(([key, item]) => item.key == cueKey))).map(([key, item], index) =>
                <Box sx={{ mt: 1 }}>
                  <FlagItems
                    flagIndex={index}
                    open={open}
                    key={key}
                    item={item}
                    events={events.filter(event => event.uuid == item.uuid)}
                    votes={votes.filter(vote => vote.uuid == item.uuid)}
                  />
                  {open ?

                    <Box sx={{ ml: 2, mr: 2 }}>
                      {events.filter(event => event.uuid == item.uuid).map((event) =>
                        <>
                          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mt: 1, mb: 1 }}>
                            <Typography color="primary" variant="caption">
                              {event.type ? event.type == "flag" ? "FLAG " : event.type.toUpperCase() + " " : ""}
                              {(event.action == "create" || event.action == "update") ? event.action.toUpperCase() + "D " : (event.action == "accept" || event.action == "reject") ? event.action.toUpperCase() + "ED " : event.action.toUpperCase() + " "}
                              {event.ouuid == "00000000-0000-0000-0000-000000000000" ? "" :
                                "by " + viewers.find((viewer) => viewer.uuid == event.ouuid).name}
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <Typography color="primary" variant="caption">{moment(event.date).format('MM/DD, h:mm:ss a')}</Typography>
                          </Box>

                          {event.action == "update" ?
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                              <Card sx={{ p: 2, background: "#373737" }}>
                                <Typography color="primary" variant="body1">
                                  {findDiffs(cue.text, item.change.text).map(diff =>


                                    (diff.change == "") ?
                                      <>{diff.original + " "}</> :
                                      <mark>{diff.change + " "}</mark>

                                  )}  </Typography>
                              </Card> </Box> : null
                          }
                        </>
                      )}
                    </Box> : null}
                </Box>
              )}
            </Box>
          </div>
        </Box>
      </Box>
    </>
  )
}


function FlagItems(props) {

  const { item, votes, events } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Box style={{ position: "absolute", top: -10, left: 10 }}>
        <IconButton color="success" disabled={!votes.filter(vote => vote.type === "up").length > 0}>
          <Badge badgeContent={votes.filter(vote => vote.type === "up").length} color="success">
            <ArrowUpward style={{ height: "40px", width: "40px" }} />
          </Badge>
        </IconButton>
      </Box>

      <Box style={{ position: "absolute", top: -10, left: 50 }}>
        <IconButton color="error" disabled={!votes.filter(vote => vote.type === "down").length > 0}>
          <Badge badgeContent={votes.filter(vote => vote.type === "down").length} color="error">
            <ArrowDownward style={{ height: "40px", width: "40px" }} /></Badge></IconButton>
      </Box>

      <Box style={{ position: "absolute", top: 0, right: -30 }}>
        <Chip icon={item.status == "accepted" ? <Check /> : <Place />} label={item.status.toUpperCase()} color={item.status == "accepted" ? "success" : item.status == "rejected" ? "error" : "primary"} />
      </Box>
    </Box>
  );
}


function DemoAppBar() {

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" sx={{ background: "transparent" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img src={"/favicon.ico"} style={{ height: "40px", width: "40px", padding: "5px" }} />
            <Typography
              variant="h5"
              letterSpacing={-1}
              fontWeight="600"
              fontFamily="Inter, sans-serif"
              color="#ffbd58"
            >
              dubsee
            </Typography>
            <Typography variant="h5" sx={{ textDecoration: 'underline', paddingLeft: "8px", fontWeight: 600 }}>
              Demo
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }} />
            <Box>
              <Typography variant="body2">v{version}</Typography>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}



