import React, { useEffect } from 'react';
import { Functions } from './Functions.js';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { CircularProgress, Divider, InputLabel, LinearProgress, TextField, Dialog, DialogTitle, DialogContent, CardActionArea, CardActions, CardMedia, Grid, CardContent, Link, Slider, ButtonBase, Skeleton } from '@mui/material';
import { ClosedCaption, Flag, Feedback, Edit, Check, AssistantPhotoTwoTone, ThumbUp, ThumbDown, ArrowUpward, ArrowDownward, Refresh, TheatersOutlined, Settings, Close, Pause, PlayArrow, VolumeOff, VolumeDown, VolumeUp, Fullscreen, FullscreenExit, LinkOff, Share, CheckCircle, Iron, RateReview } from '@mui/icons-material';
import moment from 'moment';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar } from '@mui/material';
import { Badge, IconButton, Select, MenuItem, Tab, Tabs, Tooltip } from '@mui/material';
import './Demo.css';
import TabPanel, { a11yProps } from './TabPanel.js';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import "@fontsource/electrolize"; // Defaults to weight 400.
import { ReactCardFlip } from 'react-card-flip';
import QuickModal from './QuickModal.js';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import YouTube, { YouTubeProps } from 'react-youtube';
import { GoogleOAuthProvider, GoogleLogin, googleLogout } from '@react-oauth/google';
import { useParams } from 'react-router';
import { gapi, loadGapiInsideDOM, loadAuth2, loadAuth2WithProps, loadClientAuth2, useGoogleLogin } from 'gapi-script';
import axios from 'axios';
import { CaptionFeedback, CaptionFeedbackButton, CaptionFeedBackSliderControlBar, CaptionFeedbackProvider, FeedbackChange, FeedbackFlag, FeedbackBallot, FlagTypeLabel, findDiffs } from './CaptionFeedbackforIframe.js';
import { Stage, Layer, Star, Text, Rect, Image } from 'react-konva';
import { round } from 'lodash';
import Konva from 'konva';
import ErrorPanel from './ErrorPanel.js';
import config from '../config.js';
import ReactGA from 'react-ga4';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { TrendingVideos } from './TrendingVideos.js';
import useImage from 'use-image';

const ROOT = config.ROOT;
const version = config.VERSION;
const DUBSEE_LANDING_PAGE = config.DUBSEE_LANDING_PAGE;
const YOUTUBE_KEY_ROOT = config.YOUTUBE_KEY_ROOT;

const themeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#edf9f9',
    },
    secondary: {
      main: '#f50057',
    },
    contrastThreshold: 4.5,

  },
  typography: {
    body2: {
      color: "white",
    },
    fontFamily: 'Inter, sans-serif',
    button: {
      fontSize: "1rem",
    }
  },
};

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

export default function DubseeLayerExtension() {

  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerWidth * .57);
  const [videoData, setVideoData] = React.useState(null);
  const [portraitOrientation, setOrientation] = React.useState(window.matchMedia("(orientation: portrait)").matches);
  const urlParams = new URLSearchParams(window.location.search);
  const [embed, setEmbed] = React.useState(urlParams.get('embed') == "true");
  const smallFrame = (width < 700);
  const { id } = useParams();

  ReactGA.event({
    name:"loaded_video",
    label: id 
  });

  React.useEffect(() => {


    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    
    window.matchMedia("(orientation: landscape)").addEventListener("change", updateDimensions);
    window.matchMedia("(orientation: portrait)").addEventListener("change", updateDimensions);
    return (() => {
      window.removeEventListener("resize", updateDimensions);
      window.matchMedia("(orientation:landscape)").removeEventListener("change", updateDimensions);
      window.matchMedia("(orientation:portrait)").removeEventListener("change", updateDimensions);

  });
}, []);


  const updateDimensions = () => {

    // setOrientation(window.matchMedia("(orientation: portrait)").matches);

    // if(embed){

    //   if ((window.innerHeight) < ((window.innerWidth * .57))) {
    //     setHeight(window.innerHeight);
    //     setWidth((window.innerHeight) / .57);
    //   }
    //   else{
    //   setWidth(window.innerWidth);
    //   setHeight(window.innerWidth * .57);
    //   window.scrollTo(0, 0);
    //   }
    // }

    // else if (isMobile) {
    //   if(window.matchMedia("(orientation: landscape)").matches){     
    //   setWidth((window.innerHeight) * 1.67);
    //     setHeight(window.innerHeight);
    //     window.scrollTo(0, 0);
    //   }
    //   else{
    //     setWidth(window.innerWidth);
    //     setHeight(window.innerWidth * .57);
    //   }
    // }
    // // else if ((window.innerHeight - 150) < window.innerWidth) {

    // else if ((window.innerHeight - 150) < ((window.innerWidth * .57))) {
    //     setHeight(window.innerHeight - 150);
    //     setWidth((window.innerHeight - 150) / .57);
    //   }
    
    // else if ((window.innerHeight - 400) < ((window.innerWidth * .57))) {

    //   setHeight(window.innerHeight - 400);
    //   setWidth((window.innerHeight - 400) / .57);

    // }


    // else {
    //   setWidth(window.innerWidth);
    //   setHeight(window.innerWidth * .57);
    // }
  }

  return (

    id ?
      <PlayerComponent id={id} width={width} height={height} setHeight={setHeight} setWidth={setWidth} videoData={videoData} setVideoData={setVideoData} />
      : null
  );
}

function PlayerComponent(props) {

  const { width, height, id, videoData, setVideoData } = props;

  const [textTracks, setTextTracks] = React.useState([]);
  const [textIndex, setTextIndex] = React.useState({});
  const [activeCue, setActiveCue] = React.useState(null);
  const [playing, setPlaying] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [transcript, setTranscript] = React.useState(null);
  const [duration, setDuration] = React.useState(0);
  const player = React.useRef();
  const frame = React.useRef();
  const screenViewer = React.useRef();
  const [activeCueIndex, setActiveCueIndex] = React.useState(0);
  const [ccfId, setCCFId] = React.useState(null);
  const [hoverState, setHoverState] = React.useState(false);
  const [timer, setTimer] = React.useState(null);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [YTloaded, setYTloaded] = React.useState(true);
  const [loadedTextTracks, setLoadedTextTracks] = React.useState(false);
  const [volume, setVolume] = React.useState(0);
  const [language, setLanguage] = React.useState(null);
  const [acceptedCue, setAcceptedCue] = React.useState(false);
  const [updatedCue, setUpdatedCue] = React.useState(false);
  const [activeText, setActiveText] = React.useState("");
  const [entities, setEntities] = React.useState(null);
  const [ acceptedIcon ] = useImage("/accepted.png");
  const [ updatedIcon ] = useImage("/updated.png");

  const handleFullScreen = (open) => {
    if (open) {
      setFullScreen(true);
      if (screenViewer.current.requestFullscreen) {
        screenViewer.current.requestFullscreen({ navigationUI: "hide" });
      } else if (screenViewer.current.mozRequestFullScreen) { /* Firefox */
      screenViewer.current.mozRequestFullScreen({ navigationUI: "hide" });
      } else if (screenViewer.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      screenViewer.current.webkitRequestFullscreen({ navigationUI: "hide" });
      } else if (screenViewer.current.msRequestFullscreen) { /* IE/Edge */
      screenViewer.current.msRequestFullscreen({ navigationUI: "hide" });
      }
    }
    else {
      setFullScreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      else if (screenViewer.current.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      }
      else if (screenViewer.current.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      }
      else if (screenViewer.current.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  }

  // chrome.runtime.sendMessage({ action: "getCurrentTime" }, function (response) {
  //   const currentTime = response.currentTime;
  //   setCurrentTime(currentTime);
  // });

  // const onPlayerReady: YouTubeProps['onReady'] = async (event) => {
  //   // access to player in all event handlers via event.target
  //   console.log(event);
  //   setDuration(await event.target.getDuration());
  //   setVolume(await event.target.getVolume());
  //   // window.addEventListener("resize", updateDimensions);
  //   setYTloaded(true);
  //   // fetchTranscript(language);

  //   document.addEventListener(
  //     "keydown",
  //     (e) => {
  //       if (e.key == "Escape") {
  //         e.preventDefault();
  //         e.stopPropagation();
  
  //       }
  //     },
  //     false
  //   );
  // }

  React.useEffect(() => {
    fetchTranscript(language);
  }, [language]);


  // const getCurrentTime = async () => {
  //   if (frame && frame.current) {
  //     const time = await frame.current.internalPlayer.getCurrentTime();
  //     setCurrentTime(time);
  //   }
  // }

  React.useEffect(() => {
    if(YTloaded){
    getActiveCue(currentTime);
    }
  }, [currentTime]);

  const fetchTranscript = async (lang) => {

    let host = config.CCC_URL;

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", config.CCC_API_KEY);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let endpoint = "/ccc?index=true&comprehend=true&optimize=false&merge=true&vid=" + id + "&key=" + config.YOUTUBE_KEY_ROOT + "/" + id;
    if (lang) {
      endpoint += "&provider=yt&target=" + lang;
    }

    fetch(host + endpoint, requestOptions)
      .then(response => {   
        console.log("RESPONSE", response);
        if(response.status !== 200){
            console.log("ERROR FETCHING", response.status);
            throw Error(response.json()); 
        }      
        return response.json(); 
      })
      .then(async(res) => { 
    
        setTextIndex(lang ? res.translation.index  : res.index);
        setTextTracks(lang ? res.translation.transcript : res.transcript);         
        setLoadedTextTracks(true);
        setLanguage(lang ? lang : res.language); 
        setEntities(lang ?  res.translation.entities  ? res.translation.entities : null : res.entities ? res.entities : null);
        console.log("ENTITIES", lang ?  res.translation.entities  ? res.translation.entities : null : res.entities ? res.entities : null);
        let videoData = res.metadata ? res.metadata.snippet : null;
        setVideoData(videoData); 
        let ccfId = await Functions.data.getCCFByKey(YOUTUBE_KEY_ROOT + "/" + id, videoData.channelId );
        console.log("ccfId: " + ccfId);
        setCCFId(ccfId);
        
       })

      .catch((error) => {
          console.log("ERROR FETCHING TRANSCRIPT"); 
          console.log(error); 
          setLoadedTextTracks(true); 
        });
  }

  // const onPlayerStateChange: YouTubeProps['onStateChange'] = (event) => {
  //   console.log(event);
  //   console.log(frame.current);

  //   if (event.data == 1) {
  //     setPlaying(true);
  //     setTimer(setInterval(() => {
  //       getCurrentTime();
  //     }, 100));

  //   } else {
  //     setPlaying(false);
  //     clearInterval(timer);
  //   }
  // }

  const seekTo = (time) => {
    frame.current.internalPlayer.seekTo(time);
    setCurrentTime(time);
  }

  const handleSetVolume = (volume) => {
    setVolume(volume);
    frame.current.internalPlayer.setVolume(volume);
    if (volume == 0) {
      frame.current.internalPlayer.mute();
    } else {
      frame.current.internalPlayer.unMute();
    }
  }

  const handlePlayPause = () => {
    if (playing) {
      frame.current.internalPlayer.pauseVideo();
    } else {
      frame.current.internalPlayer.playVideo();
    }
  }

  const getActiveCue = (time) => {

    if (loadedTextTracks && textIndex) {
      let index = parseInt(textIndex[time.toFixed(1).toString()] - 1);

      while (index > 0 && currentTime < textTracks[index].start.toFixed(1)) {
        index--;
        console.log("index: " + index);
        console.log("INDEX REDUCED");
      }

      while((index < textTracks.length - 2) && currentTime > textTracks[index+1].start.toFixed(1)){
        index++;
        console.log("index: " + index);
        console.log("INDEX INCREASED");
      }
      
      if (activeCue != textTracks[index]){
      setActiveCue(textTracks[index]);
      setActiveCueIndex(index);
      setActiveText(textTracks[index] ? textTracks[index].text : "");
      setUpdatedCue(false);
      setAcceptedCue(false);
      }
     
      
      }
  }

  var textObj = new Konva.Text({
    text: activeCue ? activeText : "",
    fontSize: width * .03,
    align: 'center',
    wrap: "word",
    width: width * .9,
  });

  const textWidth = textObj.textWidth;
  const textHeight = textObj.textHeight * textObj.textArr.length;
  const padding = width * .05;
  const smallFrame = width < 600;

  console.log("dubsee layer refresh");

  console.log("activeText", activeText);

  return (
      <div style={{ position: "relative", height: fullScreen ? "100vh" : "100%", width: fullScreen ? "100vw" : "100%", overflow:"hidden"}}>
            <div style={{ position: "absolute", left:0, top:0, width: "100%", height:"100%", pointerEvents: "none" }}>
              <Stage width={width} height={height} opacity={1}>
                <Layer width={width} height={height}>
                  {activeCue ?
                    <>

                      <Rect
                        x={((width - textWidth - padding)) / 2}
                        y={playing ? height * .75 : height * .6}
                        width={textWidth + padding}
                        height={textHeight + padding}
                        fill={updatedCue ? "#ef9000" : "black"}
                        opacity={updatedCue ? .8 : .6}
                        cornerRadius={width * .03 / 5}
                      />

                      <Text
                        text={activeText}
                        x={((width - textWidth - padding)) / 2}
                        y={playing ? (height * .75) : (height * .6)}
                        fontSize={width * .03}
                        padding={padding / 2}
                        align="center"
                        fill="#ffffff"
                        wrap="word"
                        width={textWidth + padding}
                      />

                      {updatedCue ?

                <Image image={updatedIcon} x={((width / 2 - textWidth / 2 - padding))}  y={playing ? (height * .75) - textWidth * .05 : (height * .6) - textWidth * .05} width={textWidth * .1} height={textWidth * .1} />
                      : null

                      }

                      {acceptedCue ? 
                      
                      <Image image={acceptedIcon} x={((width / 2 + textWidth / 2))}  y={playing ? (height * .75) - textWidth * .05: (height * .6) - textWidth * .05} width={textWidth * .1} height={textWidth * .1} />

                      : null}

                    </> : null}

                    
                </Layer>
              </Stage>
            </div>

            <div style={{ position: "absolute", left:0, top:0, width: "100%", height:"100%", pointerEvents: "none" }}>

              {loadedTextTracks ?

                (!textTracks || textTracks.length == 0)?

                <ErrorPanel YoutubeVideoId={id} width={width} height={height}/> :

                (frame && frame.current && ccfId) ?

                  <CaptionFeedbackProvider
                    videoData={videoData}
                    ccfId={ccfId}
                    currentTime={currentTime}
                    player={frame.current.internalPlayer}
                    textTracks={textTracks}
                    setTextTracks={setTextTracks}
                    cue={activeCue}
                    activeCueIndex={activeCueIndex}
                    textIndex={textIndex}
                    duration={duration}
                    playing={playing}
                    YouTubeVideoId={id}
                    width={width}
                    height={height}
                    hoverState={hoverState}
                    seekTo={seekTo}
                    fullScreen={fullScreen}
                    handleFullScreen={handleFullScreen}
                    handleSetVolume={handleSetVolume} volume={volume}
                    handlePlayPause={handlePlayPause}
                    language={language}
                    setLanguage={setLanguage}
                    setAcceptedCue={setAcceptedCue}
                    setUpdatedCue={setUpdatedCue}
                    setActiveText={setActiveText}
                    entities={entities}

                  /> 
              
              

                  : null

                : null}

            </div>

      </div>
  
  );
      
  

}
