import React from 'react';
import ReactDOM from 'react-dom';
// import { Router, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useLocation,  createBrowserRouter,
  RouterProvider,
  Router,
  Routes,
  Route,
  BrowserRouter,
  Link } from 'react-router-dom';
import { createBrowserHistory } from "history";
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { Demo, Log } from './views/Demo';
// import { Embedder } from './views/Embedder';
//import './App.css';
//import './assets/scss/style.scss';
// const history = createBrowserHistory();


// const router = createBrowserRouter([
//   {
//     path:"embedder",
//     element: <Embedder />,
//   },
//   {
//     path:"log",
//     element: <Log />,
//   },
//   {
//     path: "/",
//     element: <Demo/>,
//     // errorElement: <ErrorPage />,
//   },

// ]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    {/* <RouterProvider router={router} /> */}
    <BrowserRouter>
   <App/>
      </BrowserRouter>

  </React.StrictMode>
);

// ReactDOM.render(
//   <Router history={history}>
//     <App />
//   </Router>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
