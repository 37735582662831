import awsconfig from './aws-exports';

const ENV = process.env.REACT_APP_ENV;

console.log("ENV: ", ENV);

const VERSION = "1.2.02";

const dev = {
  ENV: "dev",
  VERSION: VERSION,
  ROOT: "https://yt-dev.dubsee.cc",
  CCF_URL: "https://hjriy71ccl.execute-api.us-east-1.amazonaws.com/dev",
  CCF_ADMIN_ID: "b063e147-953c-5a2a-b983-6f9f6e4dee46",
  CCF_API_KEY: "5PN4mYFSMR3iO2GvHk78O6gulcNyuw5Q44rw5GA7",
  CCF_SOCKET_URL: "wss://nayl523gi0.execute-api.us-east-1.amazonaws.com/dev",
  CCC_URL: "https://c8dqh17g02.execute-api.us-east-1.amazonaws.com/dev",
  CCC_API_KEY: "ndoMGI3fpf49Y5bv9dvth3ZGXweCATtG20ByJewf",
  GOOGLE_CLIENT_ID: "797278485384-jf0djemrrrgarna52r142t24bqucbi8t.apps.googleusercontent.com",
  GOOGLE_CLIENT_SECRET: "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",
  GOOGLE_AUTH_URL: "https://4wgp88dxhk.execute-api.us-east-1.amazonaws.com/auth/google",
  DUBSEE_LANDING_PAGE: "https://dubsee.cc",
  GA_TRACKING_ID:"G-MCCNPSRJ89",
  YOUTUBE_KEY_ROOT: "https://youtu.be",
  };


const test = {
  ENV: "test",
  VERSION: VERSION,
  ROOT: "https://yt-test.dubsee.cc",
  CCF_URL: "https://gcaobn75y0.execute-api.us-east-1.amazonaws.com/test",
  CCF_ADMIN_ID: "b063e147-953c-5a2a-b983-6f9f6e4dee46",
  CCF_API_KEY: "wjywIGFcLaaKISn8JPGER3oP5nSW3FqF9it4GYeK",
  CCF_SOCKET_URL: "wss://9bietn5lcf.execute-api.us-east-1.amazonaws.com/test",
  CCC_URL: "https://usy3bkvwel.execute-api.us-east-1.amazonaws.com/test",
  CCC_API_KEY: "zam5rtWEyAaGf36pgp0zp9AgXNVoqMRy3qP3Wqmf",
  GOOGLE_CLIENT_ID: "797278485384-jf0djemrrrgarna52r142t24bqucbi8t.apps.googleusercontent.com",
  GOOGLE_CLIENT_SECRET: "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",
  GOOGLE_AUTH_URL: "https://4wgp88dxhk.execute-api.us-east-1.amazonaws.com/auth/google",
  DUBSEE_LANDING_PAGE: "https://dubsee.cc",
  GA_TRACKING_ID: "G-QWWYS77HS0",
  YOUTUBE_KEY_ROOT: "https://youtu.be"
};

const prod = {
    ENV: "prod",
    VERSION: VERSION,
    ROOT: "https://yt.dubsee.cc",
    CCF_ADMIN_ID: "fabe7b61-b9df-5f0c-9813-93262848b54c",
    CCF_URL: "https://9qbkcdg4rf.execute-api.us-east-1.amazonaws.com/prod",
    CCF_API_KEY: "lcKweLqXPuaXONDWAHMOp1Aj9OisQoJa59GfV8tw",
    CCF_SOCKET_URL: "wss://7cj0r6fg9j.execute-api.us-east-1.amazonaws.com/prod",
    CCC_URL: "https://nbml3qu716.execute-api.us-east-1.amazonaws.com/prod",
    CCC_API_KEY: "MZKVNDtL3J3EQQvumzRiP9NwbqDWI3rVaZWNlamF",
    GOOGLE_CLIENT_ID: "650321320125-gjlacaspbgbmjbhbbc6vcne358t9eveg.apps.googleusercontent.com",
    GOOGLE_CLIENT_SECRET: "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",
    GOOGLE_AUTH_URL: "https://cfdglnp6ed.execute-api.us-east-1.amazonaws.com/auth/google",
    DUBSEE_LANDING_PAGE: "https://dubsee.cc",
    GA_TRACKING_ID: "G-GTBCHDXD0M",
    YOUTUBE_KEY_ROOT: "https://youtu.be",
};

// Default to dev if not set

const configs = {
  'test': test,
  'dev': dev,
  'prod': prod
}

const config = configs[ENV];

export default {
  ...config
};

