const supportedLanguages = {
    "1": {
      "label": "English",
      "code": "en",
      "flag": "🇺🇸"
    },
    "2": {
      "label": "Arabic",
      "code": "ar",
      "flag": "🇸🇦"
    },
    "3": {
      "label": "Bengali",
      "code": "bn",
      "flag": "🇧🇩"
    },
    // "4": {
    //   "label": "Chinese (including Mandarin and Cantonese)",
    //   "code": "zh",
    //   "flag": "🇨🇳"
    // },
    "5": {
      "label": "French",
      "code": "fr",
      "flag": "🇫🇷"
    },
    "6": {
      "label": "German",
      "code": "de",
      "flag": "🇩🇪"
    },
    "7": {
      "label": "Gujarati",
      "code": "gu",
      "flag": "🇮🇳"
    },
    "8": {
      "label": "Hindi",
      "code": "hi",
      "flag": "🇮🇳"
    },
    "9": {
      "label": "Italian",
      "code": "it",
      "flag": "🇮🇹"
    },
    "10": {
      "label": "Japanese",
      "code": "ja",
      "flag": "🇯🇵"
    },
    "11": {
      "label": "Korean",
      "code": "ko",
      "flag": "🇰🇷"
    },
    "12": {
      "label": "Persian",
      "code": "fa",
      "flag": "🇮🇷"
    },
    "13": {
      "label": "Polish",
      "code": "pl",
      "flag": "🇵🇱"
    },
    "14": {
      "label": "Portuguese",
      "code": "pt",
      "flag": "🇵🇹"
    },
    "15": {
      "label": "Russian",
      "code": "ru",
      "flag": "🇷🇺"
    },
    "16": {
      "label": "Spanish",
      "code": "es",
      "flag": "🇪🇸"
    },
    "17": {
      "label": "Tagalog",
      "code": "tl",
      "flag": "🇵🇭"
    },
    "18": {
      "label": "Telugu",
      "code": "te",
      "flag": "🇮🇳"
    },
    "19": {
      "label": "Urdu",
      "code": "ur",
      "flag": "🇵🇰"
    },
    "20": {
      "label": "Vietnamese",
      "code": "vi",
      "flag": "🇻🇳"
    }
  };
  
    export default supportedLanguages;