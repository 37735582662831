import React, { useRef, useEffect } from 'react';
import { useLocation,  createBrowserRouter,
  RouterProvider,
  Routes,
  Route,
  Link, 
  BrowserRouter} from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import { Demo, Log } from './views/Demo';
import { DubseeLayer } from './views/DubseeLayer';
import DubseeLayerExtension from './views/DubseeLayerExtension';
import { YTLink } from "./views/YTLink";
import { VideoRecommender } from "./views/VideoRecommender";
import ReactGA from "react-ga4";
import config from './config';
import { ChannelPage } from './views/ChannelPage';
import { YTChannelLinkGenerator } from './views/YTChannelLinkGenerator';
import AdminConsole from './views/AdminConsole';

ReactGA.initialize(config.GA_TRACKING_ID);

// import ScrollReveal from './utils/ScrollReveal';
// Views 
// import Home from './views/Home';
// import PrivacyPolicy from './views/PrivacyPolicy';
// import Embedder from './views/Embedder';
// import TermsOfUse from './views/TermsOfUse';
// const TRACKING_ID = "UA-12341234-1"; 
// Initialize Google Analytics
// ReactGA.initialize(TRACKING_ID);

// const trackPage = page => {
//   ReactGA.set({ page });
//   ReactGA.pageview(page);
// };

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  // useEffect(() => {
  //   // const page = location.pathname;
  //   document.body.classList.add('is-loaded')
  //   childRef.current.init();
  //   window.scrollTo({ top: 0 });

  //   // trackPage(page);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  return (
    // <ScrollReveal
    //   ref={childRef}
    //   children={() => (
    

      <Routes>      
      <Route path="/extension/:id" element={<DubseeLayerExtension/>}/>
      <Route path="/admin/:chId" element={<AdminConsole/>}/>
      <Route path="/log/:fileName" element={<Log/>}/>
      <Route path="/demo/:fileName" element={<Demo/>} />
      <Route path="/ytchannellink" element={<YTChannelLinkGenerator/>}/>
      <Route path="/@:chName" element={<ChannelPage/>}/>
      <Route path="/recommender/:num" element={<VideoRecommender/>}/>
      <Route path="/recommender" element={<VideoRecommender/>}/>
      <Route path="/ytlink/:mode" element={<YTLink/>}/>
      <Route path="/:id" element={<DubseeLayer/>}/>
      </Routes>
    
   
  );
}

export default App;

