import React, { useEffect } from 'react';
import { Functions } from './Functions.js';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { CircularProgress, Divider, InputLabel, LinearProgress, TextField, Dialog, DialogTitle, DialogContent, CardActionArea, CardActions, CardMedia, Grid, CardContent, Link, Slider, ButtonBase, Skeleton } from '@mui/material';
import { ClosedCaption, Flag, Feedback, Edit, Check, AssistantPhotoTwoTone, ThumbUp, ThumbDown, ArrowUpward, ArrowDownward, Refresh, TheatersOutlined, Settings, Close, Pause, PlayArrow, VolumeOff, VolumeDown, VolumeUp, Fullscreen, FullscreenExit, LinkOff, Share, CheckCircle, Iron, RateReview, ImageAspectRatio } from '@mui/icons-material';
import moment from 'moment';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar } from '@mui/material';
import { Badge, IconButton, Select, MenuItem, Tab, Tabs, Tooltip } from '@mui/material';
import './Demo.css';
import TabPanel, { a11yProps } from './TabPanel';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import "@fontsource/electrolize"; // Defaults to weight 400.
import { ReactCardFlip } from 'react-card-flip';
import QuickModal from './QuickModal.js';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import YouTube, { YouTubeProps } from 'react-youtube';
import { GoogleOAuthProvider, GoogleLogin, googleLogout } from '@react-oauth/google';
import { useParams } from 'react-router';
import { gapi, loadGapiInsideDOM, loadAuth2, loadAuth2WithProps, loadClientAuth2, useGoogleLogin } from 'gapi-script';
import axios from 'axios';
import { CaptionFeedback, CaptionFeedbackButton, CaptionFeedBackSliderControlBar, CaptionFeedbackProvider, FeedbackChange, FeedbackFlag, FeedbackBallot, FlagTypeLabel, findDiffs } from './CaptionFeedbackforIframe';
import { Stage, Layer, Star, Text, Rect, Image } from 'react-konva';
import { round, set } from 'lodash';
import Konva from 'konva';
import ErrorPanel from './ErrorPanel.js';
import config from '../config.js';
import ReactGA from 'react-ga4';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { TrendingVideos } from './TrendingVideos.js';
import useImage from 'use-image';
import html2canvas from 'html2canvas';
// import puppeteer from 'puppeteer-core';



const ROOT = config.ROOT;
const version = config.VERSION;
const DUBSEE_LANDING_PAGE = config.DUBSEE_LANDING_PAGE;
const YOUTUBE_KEY_ROOT = config.YOUTUBE_KEY_ROOT;

const themeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#edf9f9',
    },
    secondary: {
      main: '#f50057',
    },
    contrastThreshold: 4.5,

  },
  typography: {
    body2: {
      color: "white",
    },
    fontFamily: 'Inter, sans-serif',
    button: {
      fontSize: "1rem",
    }
  },
};

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

export function DubseeLayer() {

  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerWidth * .57);
  const [videoData, setVideoData] = React.useState(null);
  const [portraitOrientation, setOrientation] = React.useState(window.matchMedia("(orientation: portrait)").matches);
  const urlParams = new URLSearchParams(window.location.search);
  const [embed, setEmbed] = React.useState(urlParams.get('embed') == "true");
  const smallFrame = (width < 700);


  const { id } = useParams();

  ReactGA.event({
    name:"loaded_video",
    label: id 
  });

  React.useEffect(() => {

    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    
    window.matchMedia("(orientation: landscape)").addEventListener("change", updateDimensions);
    window.matchMedia("(orientation: portrait)").addEventListener("change", updateDimensions);
    return (() => {
      window.removeEventListener("resize", updateDimensions);
      window.matchMedia("(orientation:landscape)").removeEventListener("change", updateDimensions);
      window.matchMedia("(orientation:portrait)").removeEventListener("change", updateDimensions);

  });
}, []);


  const updateDimensions = () => {

    setOrientation(window.matchMedia("(orientation: portrait)").matches);

    if(embed){

      if ((window.innerHeight) < ((window.innerWidth * .57))) {
        setHeight(window.innerHeight);
        setWidth((window.innerHeight) / .57);
      }
      else{
      setWidth(window.innerWidth);
      setHeight(window.innerWidth * .57);
      window.scrollTo(0, 0);
      }
    }

    else if (isMobile) {
      if(window.matchMedia("(orientation: landscape)").matches){     
      setWidth((window.innerHeight) * 1.67);
        setHeight(window.innerHeight);
        window.scrollTo(0, 0);
      }
      else{
        setWidth(window.innerWidth);
        setHeight(window.innerWidth * .57);
      }
    }
    // else if ((window.innerHeight - 150) < window.innerWidth) {

    else if ((window.innerHeight - 150) < ((window.innerWidth * .57))) {
        setHeight(window.innerHeight - 150);
        setWidth((window.innerHeight - 150) / .57);
      }
    
    else if ((window.innerHeight - 400) < ((window.innerWidth * .57))) {

      setHeight(window.innerHeight - 400);
      setWidth((window.innerHeight - 400) / .57);

    }


    else {
      setWidth(window.innerWidth);
      setHeight(window.innerWidth * .57);
    }
  }

  const screenViewer = React.useRef();
  const frame = React.useRef();



  return (
    <>
    <Helmet>
    <meta property="og:image"  content="%PUBLIC_URL%/default-thumbnail.png"/>
    <meta name="twitter:image" content="%PUBLIC_URL%/default-thumbnail.png"/>
    <meta name="description" content={videoData ? 
    `dubsee - ${videoData.title} - ${videoData.channelTitle} is on dubsee - support your favorite creators with viewer-improved captioning for global accessibility and reach on YouTube`:
    "dubsee - viewer-improved captioning for global accessibility and reach - support your favorite YouTubers in just a click"}/> 
    <title>
      dubsee - 
      {videoData ? 
      ` ${videoData.title} - ${videoData.channelTitle}` : " "}
      viewer improved subtitles for global accessibility and reach on YouTube
    </title>
</Helmet>


    {embed ? 

    <Box sx={{ display: "flex", height: "100%", width: "100%", position: "relative", background: "#000000", overflow:"hidden"}}>
    <PlayerComponent id={id} width={width} height={height} setHeight={setHeight} setWidth={setWidth} videoData={videoData} setVideoData={setVideoData} screenViewer={screenViewer} frame={frame} />
  </Box>

      :
    <Box sx={{ display: "flex", flexDirection: "column", height: (isMobile && !window.matchMedia("(orientation: portrait)").matches) ? height : "100vh", width: "100vw", overflow: "hidden", background: "rgba(0,0,0,.9)" }}>

      <Box sx={{ display: "flex", height: height, width: "100%", position: "relative", background: "#000000" }}>
        <PlayerComponent screenViewer={screenViewer} id={id} width={width} height={height} setHeight={setHeight} setWidth={setWidth} videoData={videoData} setVideoData={setVideoData} frame={frame} />
      </Box>

      <Box sx={{ m: 2 }}>
        <Toolbar disableGutters>
          <Box>
            {videoData ?
          <Typography variant={smallFrame ? "body1" : "h6"} sx={{ color: "white" }}>
            {videoData.title}
          </Typography> :
          <Skeleton variant="text" width={200} height={30} />
            }
            {videoData ?
          <Typography variant={smallFrame ? "caption" : "body1"} sx={{ color: "white" }}>
            {videoData.channelTitle}
          </Typography>
          : <Skeleton variant="text" width={200} height={30} /> }
          </Box>

          <Box sx={{flexGrow: 1, display: { xs: 'flex', md: 'flex' } }} />

          <Box sx={{m:1}}>
            <ShareLinks id={id} frame={frame} screenViewer={screenViewer}/>
          </Box>

          {!smallFrame ? 
         <Box sx={{m:1}}>
          <ButtonBase onClick={() => window.open(DUBSEE_LANDING_PAGE, "_blank")}>
            {/* <img src={"/favicon.ico"} style={{ height: "40px", width: "40px", padding: "5px" }} /> */}
            <Typography
              variant={smallFrame ? "h6" : "h5"}
              letterSpacing={-1}
              fontWeight="600"
              fontFamily="Inter, sans-serif"
              color="#ffbd58"
            >
              dubsee
            </Typography>
          </ButtonBase>
          </Box> : null}

        </Toolbar>


      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%", overflow: "hidden" }}>
        {((window.innerHeight + 50) < window.innerWidth) ? null :
          <>
            <Box sx={{ ml: 2, mt: 2 }}>
              <Typography variant="body1" sx={{ color: "white", marginBottom: "20px", fontWeight:600 }}>
                Trending videos on <Typography
              variant="body1"
              component="span"
              letterSpacing={-1}
              fontWeight="600"
              fontFamily="Inter, sans-serif"
              color="#ffbd58"
            > dubsee </Typography>:
              </Typography>
            </Box>

            <VideoRecommendationProvider YouTubeVideoId={id} num={8} footer/>
         
          </>
        }
   
      </Box>

    

    </Box>}
    </>
  );
}

function PlayerComponent(props) {

  const { width, height, id, videoData, setVideoData, screenViewer, frame } = props;

  const [textTracks, setTextTracks] = React.useState([]);
  const [textIndex, setTextIndex] = React.useState({});
  const [activeCue, setActiveCue] = React.useState(null);
  const [playing, setPlaying] = React.useState(false);
  const [transcript, setTranscript] = React.useState(null);
  const [duration, setDuration] = React.useState(0);
  const player = React.useRef();
  const [activeCueIndex, setActiveCueIndex] = React.useState(0);
  const [ccfId, setCCFId] = React.useState(null);
  const [hoverState, setHoverState] = React.useState(false);
  const [timer, setTimer] = React.useState(null);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [YTloaded, setYTloaded] = React.useState(false);
  const [loadedTextTracks, setLoadedTextTracks] = React.useState(false);
  const [volume, setVolume] = React.useState(0);
  const [language, setLanguage] = React.useState(null);
  const [acceptedCue, setAcceptedCue] = React.useState(false);
  const [updatedCue, setUpdatedCue] = React.useState(false);
  const [activeText, setActiveText] = React.useState("");
  const [entities, setEntities] = React.useState(null);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [ acceptedIcon ] = useImage("/accepted.png");
  const [ updatedIcon ] = useImage("/updated.png");

  const handleFullScreen = (open) => {
    if (open) {
      setFullScreen(true);
      if (screenViewer.current.requestFullscreen) {
        screenViewer.current.requestFullscreen({ navigationUI: "hide" });
      } else if (screenViewer.current.mozRequestFullScreen) { /* Firefox */
      screenViewer.current.mozRequestFullScreen({ navigationUI: "hide" });
      } else if (screenViewer.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      screenViewer.current.webkitRequestFullscreen({ navigationUI: "hide" });
      } else if (screenViewer.current.msRequestFullscreen) { /* IE/Edge */
      screenViewer.current.msRequestFullscreen({ navigationUI: "hide" });
      }
    }
    else {
      setFullScreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      else if (screenViewer.current.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      }
      else if (screenViewer.current.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      }
      else if (screenViewer.current.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  }

  const onPlayerReady: YouTubeProps['onReady'] = async (event) => {
    // access to player in all event handlers via event.target
    console.log(event);
    setDuration(await event.target.getDuration());
    setVolume(await event.target.getVolume());
    // window.addEventListener("resize", updateDimensions);
    setYTloaded(true);
    // fetchTranscript(language);
    let timeStamp = new URLSearchParams(window.location.search).get("t");

    if(timeStamp){
      seekTo(parseInt(timeStamp));
    }

    document.addEventListener(
      "keydown",
      (e) => {
        if (e.key == "Escape") {
          e.preventDefault();
          e.stopPropagation();
  
        }
      },
      false
    );

  }

  React.useEffect(() => {
    setLoadedTextTracks(false);
    if(language){
    fetchTranscript(language);
    }
    else{
      fetchTranscript("en");
      setLanguage("en");
    }
  }, [language]);


  const getCurrentTime = async () => {

    if (frame && frame.current) {
      const time = await frame.current.internalPlayer.getCurrentTime();
      setCurrentTime(time);
    }
  }

  React.useEffect(() => {
    if(YTloaded){
    getActiveCue(currentTime);
    console.log("CURRENT TIME", currentTime);
    }
  }, [currentTime]);

  const fetchTranscript = async (lang) => {

    let host = config.CCC_URL;

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", config.CCC_API_KEY);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let endpoint = "/ccc?index=true&comprehend=true&optimize=false&merge=true&vid=" + id + "&key=" + config.YOUTUBE_KEY_ROOT + "/" + id;
    if (lang) {
      endpoint += "&provider=yt&target=" + lang;
    }

    fetch(host + endpoint, requestOptions)
      .then(response => {   
        console.log("RESPONSE", response);
        if(response.status !== 200){
            console.log("ERROR FETCHING", response.status);
            throw Error(response.json()); 
        }      
        return response.json(); 
      })
      .then(async(res) => { 
    
        setTextIndex(lang ? res.translation.index  : res.index);
        setTextTracks(lang ? res.translation.transcript : res.transcript);         
        // setLanguage(lang ? lang : res.language); 
        setEntities(lang ?  res.translation.entities  ? res.translation.entities : null : res.entities ? res.entities : null);
        console.log("ENTITIES", lang ?  res.translation.entities  ? res.translation.entities : null : res.entities ? res.entities : null);
        let videoData = res.metadata ? res.metadata.snippet : null;
        setVideoData(videoData); 
        let ccfId = await Functions.data.getCCFByKey(YOUTUBE_KEY_ROOT + "/" + id, videoData.channelId );
        console.log("ccfId: " + ccfId);
        setCCFId(ccfId);

        setLoadedTextTracks(true);
          
       })

      .catch((error) => {

          console.log("ERROR FETCHING TRANSCRIPT"); 
          console.log(error); 
          setLoadedTextTracks(true); 
        });
  }

  const onPlayerStateChange: YouTubeProps['onStateChange'] = (event) => {
    console.log(event);
    console.log(frame.current);

    if (event.data == 1) {
      setPlaying(true);
      setTimer(setInterval(() => {
        getCurrentTime();
      }, 100));

    } else {
      setPlaying(false);
      clearInterval(timer);
    }
  }

  const seekTo = (time) => {
    frame.current.internalPlayer.seekTo(time);
    setCurrentTime(time);
  }

  const handleSetVolume = (volume) => {
    setVolume(volume);
    frame.current.internalPlayer.setVolume(volume);
    if (volume == 0) {
      frame.current.internalPlayer.mute();
    } else {
      frame.current.internalPlayer.unMute();
    }
  }

  const handlePlayPause = () => {
    if (playing) {
      frame.current.internalPlayer.pauseVideo();
    } else {
      frame.current.internalPlayer.playVideo();
    }
  }

  const getActiveCue = (time) => {

    if (loadedTextTracks && textIndex) {
      let index = parseInt(textIndex[time.toFixed(1).toString()] - 1);

      while (index > 0 && currentTime < textTracks[index].start.toFixed(1)) {
        index--;
        console.log("index: " + index);
        console.log("INDEX REDUCED");
      }

      while((index < textTracks.length - 2) && currentTime > textTracks[index+1].start.toFixed(1)){
        index++;
        console.log("index: " + index);
        console.log("INDEX INCREASED");
      }
      
      if (activeCue != textTracks[index]){
      setActiveCue(textTracks[index]);
      setActiveCueIndex(index);
      setActiveText(textTracks[index] ? textTracks[index].text : "");
      setUpdatedCue(false);
      setAcceptedCue(false);
      }
     
      
      }
  }


  const opts: YouTubeProps['opts'] = {
    height: height,
    width: width,
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      disablekb: 1,
      controls: 0,
      // origin: ""
      rel: 0,
      iv_load_policy: 3,
      playsinline: 1,
      cc_load_policy: -1,
      // cc_lang_pref: "en",
      fs: 0,
      hl: "en",
      enablejsapi: 1,
      listType: "user_uploads",
      // widget_referrer: "https://www.youtube.com"
    },
  };

  var textObj = new Konva.Text({
    text: activeCue ? activeText : "",
    fontSize: width * .03,
    align: 'center',
    wrap: "word",
    width: width * .9,
  });

  const textWidth = textObj.textWidth;
  const textHeight = textObj.textHeight * textObj.textArr.length;
  const padding = width * .05;
  const smallFrame = width < 600;

  console.log("dubsee layer refresh");


  return (
    <>
      <div style={{ position: "relative", height: fullScreen ? "100vh" : height, width: fullScreen ? "100vw" : "100%" }} ref={screenViewer} onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>

        <div style={{ position: "absolute", top: fullScreen ? (window.innerHeight - height) / 2 : 0, left: (window.innerWidth - width) / 2, width: width, height: height }}>
          <YouTube
            videoId={id}
            opts={opts}
            onReady={onPlayerReady}
            onStateChange={onPlayerStateChange}
            ref={frame}
          />
        </div>

        {YTloaded ?

          <div id="dubsee-canvas" style={{ position: "absolute", left: (window.innerWidth - width) / 2, top: fullScreen ? (window.innerHeight - height) / 2 : 0, width: width, height: height, pointerEvents: "none"}}>

            <div style={{ position: "absolute", left: (window.innerWidth - width) / 2, top: fullScreen ? (window.innerHeight - height) / 2 : 0, width: width, height: height, pointerEvents: "none" }} >
              <Stage width={width} height={height} opacity={1}>
                <Layer width={width} height={height}>
                  {activeCue ?
                    <>

                      <Rect
                        x={((width - textWidth - padding)) / 2}
                        y={playing ? height * .75 : height * .6}
                        width={textWidth + padding}
                        height={textHeight + padding}
                        fill={updatedCue ? "#ef9000" : "black"}
                        opacity={updatedCue ? .8 : .6}
                        cornerRadius={width * .03 / 5}
                      />

                      <Text
                        text={activeText}
                        x={((width - textWidth - padding)) / 2}
                        y={playing ? (height * .75) : (height * .6)}
                        fontSize={width * .03}
                        padding={padding / 2}
                        align="center"
                        fill="#ffffff"
                        wrap="word"
                        width={textWidth + padding}
                      />

                      {updatedCue ?

                <Image image={updatedIcon} x={((width / 2 - textWidth / 2 - padding))}  y={playing ? (height * .75) - textWidth * .05 : (height * .6) - textWidth * .05} width={textWidth * .1} height={textWidth * .1} />
                      : null

                      }

                      {acceptedCue ? 
                      
                      <Image image={acceptedIcon} x={((width / 2 + textWidth / 2))}  y={playing ? (height * .75) - textWidth * .05: (height * .6) - textWidth * .05} width={textWidth * .1} height={textWidth * .1} />

                      : null}

                    </> : null}

                    
                </Layer>
              </Stage>
            </div>

            <div style={{ position: "absolute", left: 0, top: 0, width: width, height: height, pointerEvents: "none" }}>

              {loadedTextTracks ?

                (!textTracks || textTracks.length == 0)?

                <ErrorPanel YoutubeVideoId={id} width={width} height={height}/> :

                (frame && frame.current && ccfId) ?

                  <CaptionFeedbackProvider
                    videoData={videoData}
                    ccfId={ccfId}
                    currentTime={currentTime}
                    player={frame.current.internalPlayer}
                    textTracks={textTracks}
                    setTextTracks={setTextTracks}
                    cue={activeCue}
                    activeCueIndex={activeCueIndex}
                    textIndex={textIndex}
                    duration={duration}
                    playing={playing}
                    YouTubeVideoId={id}
                    width={width}
                    height={height}
                    hoverState={hoverState}
                    seekTo={seekTo}
                    fullScreen={fullScreen}
                    handleFullScreen={handleFullScreen}
                    handleSetVolume={handleSetVolume} volume={volume}
                    handlePlayPause={handlePlayPause}
                    language={language}
                    setLanguage={setLanguage}
                    setAcceptedCue={setAcceptedCue}
                    setUpdatedCue={setUpdatedCue}
                    setActiveText={setActiveText}
                    entities={entities}
                    isHovered={isHovered}

                  /> 
              
              

                  : null

                : null}

            </div>

          </div> : null}

      </div>
    </>
  );
}


export function VideoRecommendationProvider(props) {

  const themeOptions = {
    palette: {
      mode: 'dark',
      primary: {
        main: '#edf9f9',
      },
      secondary: {
        main: '#f50057',
      },
      contrastThreshold: 4.5,

    },
    typography: {
      fontFamily: 'Open Sans, sans-serif',
      button: {
        fontSize: "1rem",
      }
    },
  };

  let theme = createTheme(themeOptions);
  theme = responsiveFontSizes(theme);

  const { YouTubeVideoId, num, scroll } = props;

  const limit = num ? num : 8;

  let shuffledVideos = TrendingVideos.filter(video => video.id !== YouTubeVideoId)
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value).slice(0, limit);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ overflow: "hidden", mr: 2, ml: 2, height: "100%", overflowX: "hidden", overflowY: "scroll" }} >

        <Grid container spacing={1}>
          {shuffledVideos.map((video) =>
            <Grid item xs={12} sm={6} md={3} lg={limit < 8 ? 12 / limit : 2} xl={12 / limit}>
              <VideoCard video={video} />
            </Grid>
          )}
        </Grid>

        {props.footer ?
        <Footer /> : null}

      </Box>
    </ThemeProvider>

  );
}

function VideoCard(props) {

  const { video } = props;

  return (
    <Card elevation={0} sx={{ width: "100%", height: "100%", background: "transparent" }}>
      <CardActionArea onClick={() => window.open(ROOT + "/" + video.id, "_blank")}>
        <Box sx={{ width: "100%", height: "auto", overflow: "hidden", borderRadius:"10px" }} >
          <img
            src={"https://img.youtube.com/vi/" + video.id + "/hqdefault.jpg"}
            alt={video.title}
            style={{ width: "100%", height: "auto", marginBottom: "-11%", marginTop: "-11%"}}
          />
        </Box>
        <Box sx={{ mt: 1, mb: 1 }}>

          <Typography gutterBottom variant="body1" component="div">
            {video.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {video.channelTitle}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
}

function Footer(props) {

  const smallFrame = window.innerWidth < 700;

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xxl">
          <Box sx={{mt:2, mb:2, display:"flex"}}>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }} />
          <Box>
            <Typography variant="body2"> <Link sx={{ cursor: "pointer" }} onClick={() => window.open("https://dubsee.cc", "_blank")}>
              dubsee</Link> {!smallFrame ? "for Youtube" : ""} | v{version} alpha | © {new Date().getFullYear()} Podium Media </Typography>
          </Box>
          </Box>
     
      </Container>
    </ThemeProvider>
  );
}


function ShareLinks(props){
  
  const [copied, setCopied] = React.useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    handleCopied();
  };

  const handleCopied = () => {
    setCopied(true);
    setTimeout(() => {
    setCopied(false);
    }, 3000);
  };

  // const captureScreenshot = async() => {
  //   try {
  //     // Launch a headless browser instance
  //     const browser = await puppeteer.launch();
  
  //     // Open a new page
  //     const page = await browser.newPage();
  
  //     // Navigate to the webpage with the embedded YouTube video

  //     let url = "https://youtu.be/${props.id}"
  //     if(props.currentTime > 0){
  //       url = url + "?t=" + props.currentTime;
  //     }

  //     await page.goto(url); // Replace with your desired URL
  
  //     // Wait for the video player to load
  //     // await page.waitForSelector('YOUR_VIDEO_SELECTOR'); // Replace with the selector for the video player element
  
  //     // Capture a screenshot of the page
  //     const screenshot = await page.screenshot();
  //     await browser.close();

  //     // Create a temporary object URL for the screenshot
  //     const screenshotBlob = new Blob([screenshot], { type: 'image/png' });
  //     const screenshotUrl = URL.createObjectURL(screenshotBlob);

  //     // Set the screenshot URL in the state
  //     screenshotUrl.download = 'screenshot.png';

  // //     // Trigger a click event on the link element to initiate download
  //     screenshotUrl.click();

  //   } catch (error) {
  //     console.error('Error capturing screenshot:', error);
  //   }
  // }

  // const captureScreenshot = () => {
  //   const element = props.screenViewer.current;
  //   // const childElement = element.querySelector("#yt-controls");
  //   // element.removeChild(childElement);

  //   html2canvas(element).then(function(canvas) {
  //     // Convert the canvas to a data URL
  //     const dataURL = canvas.toDataURL();

  //     // Create a temporary link element
  //     const link = document.createElement('a');
  //     link.href = dataURL;
  //     link.download = 'screenshot.png';

  //     // Trigger a click event on the link element to initiate download
  //     link.click();

  //     // Clean up the link element
  //     document.body.removeChild(link);
  //   });
  // };

  

  // const captureScreenshot = async () => {

  //         let url = `https://youtu.be/${props.id}`
  //     if(props.currentTime > 0){
  //       url = url + "?t=" + props.currentTime;
  //     }

  //   try {
  //     const response = await axios.post('https://shot.screenshotapi.net/screenshot', {
  //       url: url, // Replace with the desired URL
  //       token: "0XWFDMM-EFAMRN0-JKQHG3X-YFEEJ56",
  //       file_type: 'png',
  //     });
  
  //     const screenshotUrl = response.data.screenshot;
  //     console.log(screenshotUrl); // Display the screenshot URL or use it as needed
  //   } catch (error) {
  //     console.error('Error capturing screenshot:', error);
  //   }
  // };


  // const handleScreenshot = () => {
  //   captureScreenshot();
  // };

  return (
    <ThemeProvider theme={theme}>
       {/* <Button size="small" variant="outlined" color={"primary"} onClick={handleScreenshot}>
        Screenshot
      </Button> */}
      <Button size="small" variant="outlined" color={copied ? "success" : "primary"} endIcon={copied ? <CheckCircle/> : <Share/>} onClick={copyToClipboard}>
        {copied ? "Link Copied" : "Share"}
      </Button>
    </ThemeProvider>
  );
};
