import React from 'react';
import { ClosedCaption, Flag, Feedback, Edit, Check, AssistantPhotoTwoTone, FastRewind, Replay, Forward, Forward5, NextPlan, LastPage, ArrowBack, ArrowForward, CheckCircleOutline, Clear, PunchClock, TextFields, SpaceBar, Spellcheck, Timer, Timer10, Info, Start, RateReview, TextFieldsOutlined, ThumbsUpDown, ThumbUp, ArrowRight, PlayArrow, Close, HideImage, HideSource, FirstPage, Restore, Update, Place, EditLocationAlt, EditLocation, VerticalAlignBottom, QuestionAnswer, Rule, ThumbDown, PriorityHigh, Error, SmsFailed, ErrorOutline, ClosedCaptionOutlined, Add, AddCircle, FilterNone, CheckCircle, CheckBox, LocalPolice, MilitaryTech, Verified, Loyalty, Token, Language, School, OpenInFull, Visibility } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Avatar, Button, ButtonBase, Checkbox, Divider, Grid, Skeleton, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Badge, Grow, IconButton, Slide, Tooltip, Chip } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { Functions } from './Functions.js';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';
import { VideoSeekSlider } from "react-video-seek-slider";
import "react-video-seek-slider/styles.css";
import "./CaptionFeedback.css";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import moment from 'moment';
import ReactCardFlip from 'react-card-flip';
import {
    MediaController,
    MediaControlBar,
    MediaTimeRange,
    MediaTimeDisplay,
    MediaVolumeRange,
    MediaPlayButton,
    MediaSeekBackwardButton,
    MediaSeekForwardButton,
    MediaMuteButton,
    MediaCaptionsButton,
} from 'media-chrome/dist/react';

const API_KEY = "wjywIGFcLaaKISn8JPGER3oP5nSW3FqF9it4GYeK";
const socketUrl = "wss://9bietn5lcf.execute-api.us-east-1.amazonaws.com/test";

const themeOptions = {
    palette: {
        // mode: 'dark',
        primary: {
            main: '#edf9f9',
        },
        secondary: {
            main: '#ffbd58',
        },

    },
    typography: {
        fontFamily: 'Electrolize, sans-serif',
        body1: {
            color: "white",
            fontSize: '.9rem',
        },
        h6: {
            color: "white",
            fontSize: '1.1rem',
        },
        caption: {
            color: "white",
        },
        body2: {
            color: "white",
        },
        h2: {
            color: "white",
            fontWeight: '400',
            fontSize: '1rem',
            '@media (min-width:750px)': {
                fontSize: '2rem',
            }
        }
    },
    contrastThreshold: 4.5,
    contrastText: '#fff',
    overrides: {
        MuiButton: {
            raisedPrimary: {
                color: 'white',
            },
        },
    }

};

const theme = responsiveFontSizes(createTheme(themeOptions));

export function FlagTypeLabel(type, confirm = true) {
    return confirm ? flagTypes.filter(flagType => flagType.type == type)[0].label : flagTypes.filter(flagType => flagType.type == type)[0].confirmation;
}

export const flagTypes = [
    { type: "text", icon: <PriorityHigh />, color: "error", label: "MISSPELLING(S)", confirmation: "CORRECT SPELLING" },
    { type: "name", icon: <TextFieldsOutlined />, color: "info", label: "WRONG NAME(S)", confirmation: "CORRECT NAMES" },
    { type: "missing", icon: <SpaceBar />, color: "primary", label: "MISSING WORD(S)", confirmation: "NO MISSING WORDS" },
    { type: "punctuation", icon: <Feedback />, color: "secondary", label: "BAD PUNCTUATION", confirmation: "CORRECT PUNCTUATION" },
    // { type: "timing", icon: <Timer />, color: "error", label: "WRONG TIMING", confirmation: "CORRECT TIMING" },
]


export function findDiffs(str1, str2) {

    let diffs = [];
    str2.split(' ').forEach((val, i) => {

        if (val != str1.split(" ")[i]) {
            diffs.push({ original: str1.split(" ")[i], change: val });
        }
        else {
            diffs.push({ original: val, change: "" });
        }

    });
    console.log(diffs);
    return diffs;
}


export function CaptionFeedbackProvider(props) {

    const { player, userId, ccfId, textTracks } = props;
    const captionsEnabled = player ? player.current ? player.current.textTracks.length > 0 ? player.current.textTracks[0].mode == "showing" : false : false : false;
    const [duration, setDuration] = React.useState(0);
    const [feedbackItems, setFeedbackItems] = React.useState([]);
    const [cue, setCue] = React.useState({ id: "0" });
    const [hasActiveCue, setHasActiveCue] = React.useState(false);
    const [cueItems, setCueItems] = React.useState([]);
    const [userItems, setUserItems] = React.useState([]);
    const [itemsToVoteOn, setItemsToVoteOn] = React.useState([]);
    const [hasItemsToVoteOn, setHasItemsToVoteOn] = React.useState(false);
    const [upVotedItems, setUpVotedItems] = React.useState([]);
    const [downVotedItems, setDownVotedItems] = React.useState([]);
    const demo = false;
    const [hidePanel, setHidePanel] = React.useState(false);
    const [times, setTimes] = React.useState([]);
    const [isItemOwner, setIsItemOwner] = React.useState(false);
    const [hasVoted, setHasVoted] = React.useState(false);
    const [hasVotedUp, setHasVotedUp] = React.useState(false);
    const [hasVotedDown, setHasVotedDown] = React.useState(false);
    const [feedbackMode, setFeedbackMode] = React.useState(false);

    React.useEffect(() => {
        loadFeedbackSession();
    }, [userId]);

    React.useEffect(() => {
        if (player) {
            player.current.volume = .5;
            setDuration(player.current.duration);
            console.log("SETTING DURATION", duration);
            // player.current.addEventListener("timeupdate", handleCurrentTime, true);
            // return () => {     
            //     player.current.removeEventListener("timeupdate", handleCurrentTime, true);  
            // } 
            //         Array.from(Array(Math.floor(player.current.duration)).keys()).map((time) => {

            //         let cues = player.current.textTracks[0].cues;
            //         let nearestCue = cues[0];
            //         let k = 0;
            //         for (let i = 0; i < cues.length; i++) {
            //             if (cues[i].startTime < time) {
            //                 nearestCue = cues[i];
            //                 k = i;
            //             }
            //         }

            //         if (k == cues.length - 2)
            //         {
            //             times[time] = cues[k + 1];
            //         }

            //         else{

            //             times[time] =  cues[k];

            //         }

            //         setTimes(times);

            // }) 
        }
    }, [player]);

    const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl,
        {
            queryParams: { "x-api-key": API_KEY, "fuuid": ccfId },
            onOpen: () => console.log('opened'),
            shouldReconnect: (closeEvent) => true,
            reconnectAttempts: 5,
        });

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    React.useEffect(() => {
        console.log("Connection Status: " + connectionStatus);
    }, [connectionStatus]);

    const loadFeedbackSession = React.useCallback(() => {


        player.current.textTracks[0].addEventListener("cuechange", (event) => {
            console.log("CUE CHANGE");
            setCue(event.currentTarget.activeCues.length > 0 ? event.currentTarget.activeCues[0] : { id: "0" });
            setHasActiveCue(event.currentTarget.activeCues.length > 0);

        });


        const fetchData = async () => {
            console.log("Fetching FLAGS");
            
            const flags = await Functions.item.getFeedbackItems(ccfId);
            console.log(flags);
            setFeedbackItems(flags);
            console.log(flags);
        }
        fetchData()
            .catch(console.error);
    }, []);



    React.useEffect(() => {
        const fetchData = async () => {
            if (lastMessage !== null) {
                let event = JSON.parse(lastMessage.data);
                console.log("CaptionFeedBack - Feedback Item Updated: " + event.uuid)
                if (event.fuuid == ccfId) {
                    let items = {};
                    let newItem = await Functions.data.getFeedbackItem(ccfId, event.iuuid);
                    if (feedbackItems.filter(item => item.iuuid == newItem.iuuid).length == 0) {
                        setFeedbackItems((prevState) => ([...prevState, newItem]));
                    }
                    else {
                        setFeedbackItems((prevState) => ([...prevState.filter(item => item.uuid != newItem.uuid), newItem]));
                    }
                }
            }
        }
        // call the function
        fetchData().catch(console.error);
    }, [lastMessage]);


    React.useEffect(() => {

        setCueItems([]);

        if (hasActiveCue) {
            let items = feedbackItems.filter(item => item.key == cue.id);
            console.log(items);
            setCueItems(items);
        }

    }, [cue, feedbackItems, userId]);


    React.useEffect(() => {

        setUserItems(cueItems.filter(item => item.ouuid == userId));
        setIsItemOwner(userItems.length > 0);
        let upVotedItems = cueItems.filter(item => item.votes.filter(vote => (vote.ouuid == userId && vote.type == "up")).length > 0);
        setUpVotedItems(upVotedItems);
        let downVotedItems = cueItems.filter(item => item.votes.filter(vote => (vote.ouuid == userId && vote.type == "down")).length > 0);
        setDownVotedItems(downVotedItems);
        setHasVotedUp(upVotedItems.length > 0);
        setHasVotedDown(downVotedItems.length > 0);
        setHasVoted(upVotedItems.length > 0 || downVotedItems.length > 0);
        let openItems = cueItems.filter(item => (item.ouuid != userId && item.votes.filter(vote => vote.ouuid == userId).length == 0)).filter(item => item.change && item.change.text != "");
        setItemsToVoteOn(openItems);
        setHasItemsToVoteOn(openItems.length > 0);

    }, [cueItems]);


    const handleCreateFlag = async (type) => {
        let iuuid = await Functions.item.createFlag(cue.id, userId, ccfId, type);
        return iuuid;
    }

    const handleAddChange = (item, text) => {
        Functions.item.addChange(item, userId, ccfId, text);
    }

    const handleUp = (item) => {
        Functions.vote.upVote(item, userId, ccfId);
    }

    const handleDown = (item) => {
        Functions.vote.downVote(item, userId, ccfId);
    }



    console.log("Caption Feedback Data Provider Refresh");
    console.log("FEEDBACK MODE", feedbackMode);

    const pause = () => {
        player.current.pause();
    }

    const play = () => {
        player.current.play();
    }

    const nextCue = (time) => {

        let cues = player.current.textTracks[0].cues;
        let id = (cue.id != "0" && player.current.currentTime < cues[0].startTime) ? parseInt(cue.id) + 1 : 0;

        return cues[id <= cues.length - 1 ? id : cues.length - 1]

    }

    const lastCue = (time) => {
        //finds the nearest cue to the current time
        let cues = player.current.textTracks[0].cues;
        let id = (cue.id != "0" && player.current.currentTime < cues[cues.length - 1].endTime) ? parseInt(cue.id) - 1 : cues.length;

        return cues[id >= 0 ? id - 1 : 0]
    }

    const handleSeek = (event, newValue) => {
        player.current.currentTime = newValue + .1;
    };

    const seekToCue = (cue) => {
        player.current.pause();
        player.current.currentTime = cue.startTime + .1;
    }

    const seekToLastCue = () => {
        player.current.pause();
        let currentTime = player.current.currentTime;
        let last = lastCue(currentTime);
        player.current.currentTime = last.startTime + .1;
        console.log("last", last);
    }

    const seekToNextCue = () => {
        player.current.pause();
        let currentTime = player.current.currentTime;
        let next = nextCue(currentTime);
        player.current.currentTime = next.startTime + .1;
        console.log("next", next);
    }

    return (

        React.cloneElement(<CaptionFeedback />,
            {
                ...props,
                player,
                userId,
                ccfId,
                textTracks,
                duration,
                feedbackItems,
                handleSeek,
                seekToCue,
                captionsEnabled,
                loadFeedbackSession,
                seekToLastCue,
                seekToNextCue,
                cue,
                cueItems,
                hasActiveCue,
                handleCreateFlag,
                handleAddChange,
                handleUp,
                handleDown,
                isItemOwner,
                hasVoted,
                hasVotedUp,
                hasVotedDown,
                upVotedItems,
                downVotedItems,
                userItems,
                itemsToVoteOn,
                demo,
                userItems,
                play,
                pause,
                findDiffs,
                feedbackMode,
                setFeedbackMode,
                hasItemsToVoteOn,
                hidePanel,
                setHidePanel,
            })
    )
}


export function CaptionFeedback(props) {

    const { player, feedbackMode, captionsEnabled } = props;
    console.log("CAPTION FEEDBACK PLAYER UPDATE");
    console.log("FEEDBACK MODE", feedbackMode);

    return (
        (player) ?
            <ThemeProvider theme={theme}>
                {(feedbackMode && captionsEnabled) ?
                    <FeedbackManager player={player} feedbackMode={feedbackMode} captionsEnabled={captionsEnabled} {...props} /> : null}
                <CaptionFeedBackSliderControlBar {...props} />
            </ThemeProvider>
            : null
    );
}

function FeedbackManager(props) {

    return (
        <Box sx={{ display: "flex", height: "100%", width: "100%", pointerEvents:"none" }}>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", pointerEvents:"auto" }} slot="top-chrome" onClick={props.pause} >
                <Grow in={true} mountOnEnter unmountOnExit container={props.player.current}>
                    <Box sx={{ m: 1 }}>
                        <Card elevation={0} style={{ background: "rgba(0,0,0,0.7)" }}>

                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Box sx={{ m: 1 }}>
                                    <Box sx={{ height: "45px", display: "flex", alignItems: "center", justifyContent: "center" }} >

                                        <IconButton size="small" onClick={props.seekToLastCue}><FirstPage sx={{ height: "30px", width: "30px", fill: "#999999" }} /></IconButton>

                                        <ButtonBase sx={{ m: 1 }}>
                                            {props.hasActiveCue ? <CaptionFlagIcon size={30} /> : <CaptionAddIcon size={30} />}
                                        </ButtonBase>

                                        <IconButton size="small" onClick={props.seekToNextCue} sx={{ transform: "rotateY(180deg)" }}><FirstPage sx={{ height: "30px", width: "30px", fill: "#999999" }} /></IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Grow>
            </Box>

            {(!props.hidePanel && props.cue && props.cue.id != "0" && props.hasItemsToVoteOn) ?

                <Grow in mountOnEnter unmountOnExit>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexGrow: 1, pointerEvents:"auto" }} slot="top-chrome" onClick={props.pause} >
                        <FeedbackFlag {...props} />
                    </Box>
                </Grow>

                :

                props.hidePanel ?
               
               <Grow in mountOnEnter unmountOnExit>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexGrow: 1, pointerEvents:"auto" }} slot="top-chrome" onClick={props.pause} >
                <HiddenCard {...props} />
                </Box>
                </Grow>

                :

                props.hasActiveCue ?

                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexGrow: 1, pointerEvents:"auto" }} slot="top-chrome" onClick={props.pause} >
                        <FeedbackFlag {...props} />
                    </Box>

                    : null}


               

            {/* <Box sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }} slot="top-chrome" onClick={props.pause} >
                    <Box sx={{ m: 1 }}>
                        <Card elevation={0} style={{ background: "rgba(0,0,0,0.7)", width: "700px" }}>

                            <Box sx={{ display: "flex", m: 1, cursor: "pointer", alignItems: "top", justifyContent: "flex-stretch", }}
                            >

                                <img src={"/selected-logo.png"} style={{ height: "20px", width: "55px" }} />

                                <Box sx={{ flexGrow: 1 }} />

                                <Close sx={{ height: "25px", width: "25px", fill: "#999999" }} />

                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", m: 1 }}>

                                <Avatar sx={{ height: "50px", width: "50px", m: 1 }} src={"/Leon.jpg"} />


                                <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "flex-start", m: 1 }}>

                                    <Typography variant="h6">Leon Lyakovetsky</Typography>

                                    <Box sx={{ display: "flex", flexDirection: "column", mt: .2, mb: 1 }}>

                                        <Box>
                                            <Typography variant="caption">Your dubsee Profile</Typography>
                                        </Box>

                                    </Box>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "column", m:1}}>
                                    <Box>
                                        <Typography variant="h6" align="center">72</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption" align="center">updates submitted</Typography>
                                    </Box>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "column", m:1}}>
                                    <Box>
                                        <Typography variant="h6" align="center">63</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption" align="center">viewer accepted</Typography>
                                    </Box>
                                    </Box>
                
                                </Box>
                            
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center"}}>

                                    
                                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center"}}>

                                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", m:1}}>
                                                <LocalPolice sx={{height:"50px", width:"50px", m:1}} color="success"/> &nbsp;
                                                <Typography variant="body1"> Watchful Eye</Typography>
                                            </Box>

                                            <Box sx={{display:"flex", flexDirection:"column",alignItems:"center", m:1}}>
                                                <Verified sx={{height:"50px", width:"50px", m:1}} color="primary"/> &nbsp;
                                                <Typography variant="body1">Punctuation Pro</Typography>
                                            </Box>
                                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", m:1}}>
                                                <Loyalty sx={{height:"50px", width:"50px", m:1, fill:"rgba(255,255,255,.2)"}}/> &nbsp;
                                                <Typography sx={{color:"rgba(255,255,255,.2)"}} variant="body1">Spelling Superstar</Typography>
                                            </Box>

                                        
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", m:1}}>
                                                <School sx={{height:"50px", width:"50px", m:1}} color="secondary"/> &nbsp;
                                                <Typography variant="body1">Grammar Guru</Typography>
                                            </Box>
                                    
                                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", m:1}}>
                                                <MilitaryTech sx={{height:"50px", width:"50px", m:1, fill:"rgba(255,255,255,.2)"}}/> &nbsp;
                                                <Typography sx={{color:"rgba(255,255,255,.2)"}} variant="body1">Editor In Chief</Typography>
                                            </Box>
                                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", m:1}}>
                                                <Language sx={{height:"50px", width:"50px", m:1, fill:"rgba(255,255,255,.2)"}}/> &nbsp;
                                                <Typography sx={{color:"rgba(255,255,255,.2)"}} variant="body1">Translation Titan</Typography>
                                            </Box>
                                            </Box>

                                            <Box sx={{ flexGrow: 1 }} />

                                        </Box>            
                                    

                            <Box sx={{ml:1, mr: 1, mt:2, mb:.5, display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-strech" }}>
                            <Typography variant="caption">28 More Changes to Next Level </Typography>

                            <Box sx={{flexGrow:1}}/>
                            
                            <Box sx={{display:"flex", alignItems:"center"}}>
                            <Typography variant="caption">72/100
                            </Typography>&nbsp;
                            <MilitaryTech sx={{height:"15px", width:"15px"}} color="primary" />
                            </Box>
                            
                            </Box>

                            <LinearProgress sx={{height:"10px"}} color="secondary" variant="determinate" value={80} />

                        </Card>
                    </Box>

                </Box> */}

        </Box>

    );

}


export const CaptionFeedbackButton = (props) => {

    const { player } = props;

    return (

        <ButtonBase
            sx={{
                background: "rgba(20,20,20, 0.7)", height: "45px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center"
                , borderBottom: props.feedbackMode ? "2px solid white" : ""
            }}


            onClick={() => {
                props.setFeedbackMode(!props.feedbackMode);
            }
            }>


            <img style={{ height: "22px", width: "60px" }} src={props.feedbackMode ? "/selected-logo.png" : "/unselected-logo.svg"} />

            <Error sx={{ fill: props.feedbackMode ? "#ffbd58" : "white", ml: -.8, mt: -1, height: "13px", width: "13px" }} />


        </ButtonBase>

    )
}

export function FeedbackFlag(props) {


    const { player, cue, cueItems, hasActiveCue, handleCreateFlag, handleUp, handleDown, isItemOwner, hasVoted, upVotedItems, downVotedItems, hasVotedUp, hasVotedDown, userItems, itemsToVoteOn, demo, handleAddChange, seekToNextCue, seekToLastCue, play, findDiffs, hasItemsToVoteOn, setHidePanel} = props;

    const [show, showPanel] = React.useState(false);
    const [originalText, setOriginalText] = React.useState("");
    const [text, setText] = React.useState(cue.text);
    const [cardFlipped, flipCard] = React.useState(hasItemsToVoteOn);
    const [loading, setLoading] = React.useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [flagType, setFlagType] = React.useState(flagTypes[0].type);
    const [revert, setRevert] = React.useState(false);

    React.useEffect(() => {
        showPanel(false);
        setLoading(true);
        setText(cue.text);
        setOriginalText(cue.text);


        setTimeout(() => {
            showPanel(true);
        }, 250);
        setTimeout(() => {
            setLoading(false);

        }, 300);
    }, [cue]);

    const message = () => {
        let status =
            <>
                {
                    hasItemsToVoteOn ?

                        <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                            <Info sx={{ width: "16px", height: "16px" }} />

                            <Typography variant="body1" sx={{ color: "white", ml: 1, mr: 1 }}>
                                Select the better caption.
                            </Typography>

                        </Box>
                        :

                        (!flipCard && !isItemOwner && !hasVotedUp && !hasVotedDown) ?
                            <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                                <Info sx={{ width: "16px", height: "16px" }} />

                                <Typography variant="body1" sx={{ color: "white", ml: 1, mr: 1 }}>
                                    Mark this caption as right or wrong.
                                </Typography>

                            </Box>
                            : null}

               

                    {!flipCard && (isItemOwner) && !hasVotedDown ?
                        <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                            <CheckCircleOutline sx={{ width: "16px", height: "16px" }} />
                            <Typography lineHeight={2.5}
                                variant="caption" sx={{ color: "white", mr: 1, ml: 1 }}>
                                You flagged this caption.&nbsp;
                            </Typography>
                        </Box>    
                        : null}
            </>;
        
        return status;
    }

    const handleSubmitChange = (item) => {
        setSubmitting(true);
        handleAddChange(item, text);
        setTimeout(() => {
            flipCard(false);
            setSubmitting(false);
        }, 2500);
    }

    const handleCreateNewFlag = () => {
        handleCreateFlag(flagType)
        flipCard(true);
        setFlagType(flagTypes[0].type);
    }

    const handleCorrectFlag = () => {
        // handleCreateFlag(flagType)
        // flipCard(true);
        // setFlagType(flagTypes[0].type);
    }



    const handleUpVote = (item) => {
        setSubmitting(true);
        handleUp(item);
        setTimeout(() => {
            setSubmitting(false);
            flipCard(false);

        }, 2500);
    }


    const handleDownVote = (item) => {
        setSubmitting(true);
        handleDown(item);
        setTimeout(() => {
            setSubmitting(false);
            flipCard(false);

        }, 2500);
    }

    return (
        <Grow direction="down" in={show} mountOnEnter unmountOnExit container={player.current}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", m: 1, maxWidth: "70%" }}>


                {loading ?

                    <Card elevation={0} style={{ background: "rgba(0,0,0,0.7)", maxHeight: "90%" }}>
                        <Box sx={{ mt: 1, mb: 2 }}>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Box sx={{ m: 1 }}>
                                    <Skeleton variant="circular" animation="wave" width={40} height={40} />
                                </Box>
                                <Box>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Skeleton variant="rectangular" animation="wave" width={500} height={40} />
                                    </Box>
                                    <Box>
                                        <Skeleton variant="rectangular" animation="wave" width={500} height={20} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ m: 2 }}>
                                <Skeleton variant="rectangular" animation="wave" width={500} height={80} />
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                <Box sx={{ m: 2 }}>
                                    <Skeleton variant="rectangular" animation="wave" width={250} height={80} />
                                </Box>
                                <Box sx={{ m: 2 }}>
                                    <Skeleton variant="rectangular" animation="wave" width={250} height={80} />
                                </Box>
                            </Box>
                        </Box>
                    </Card>

                    :
                    <>
                        <ReactCardFlip isFlipped={cardFlipped} flipDirection="horizontal">
                            {/* Primary Side of Card */}
                            <Box>
                                <Card elevation={0} style={{ background: "rgba(0,0,0,0.7)" }}>

                                    <Box sx={{ display: "flex", m: .5, cursor: "pointer", alignItems: "top", justifyContent: "flex-stretch" }}>

                                        <Box sx={{ flexGrow: 1 }} />

                                        <IconButton onClick={() => setHidePanel(true)}>
                                        <Close sx={{ height: "25px", width: "25px", fill: "#999999" }} />
                                        </IconButton>

                                    </Box>

                                    <Box sx={{ m: 2, mt: -2 }}>
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                                            <Box sx={{ m: 1 }}>

                                                <Box sx={{ height: "45px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", mr: 1 }} >

                                                    <Box sx={{ m: 1 }}>
                                                        <CaptionFlagIcon size={40} />
                                                    </Box>

                                                </Box>

                                            </Box>

                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>

                                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <Typography variant="h6">Caption Feedback</Typography>
                                                    
                                                    {!hasItemsToVoteOn ?
                                                        <Box sx={{ display: "flex", m: 1, height: "25px", width: "25px", cursor: "pointer", alignItems: "center" }} onClick={() => flipCard(true)}>
                                                            <CaptionEditIcon size={15} />
                                                        </Box> : null}

                                                </Box>
                                                <Box>
                                                    <Typography variant="caption">{message()}</Typography>
                                                </Box>
                                                <Box>

                                                    {userItems.filter(item => (item.change && item.change.text != "")).length > 0 ?
                                                        <>
                                                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", mt: 1 }}>
                                                                <RateReview sx={{ color: "white", width: "16px", height: "16px", mr: 1 }} />
                                                                <Typography variant="caption">
                                                                    You updated this caption.
                                                                </Typography>
                                                            </Box>
                                                        </> : 
                                                        
                                                        userItems.filter(item => (item.change && item.change.text == "")).length > 0 ?
                                                        
                                                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", mt: 1 }}>
                                                                <Flag sx={{ color: "white", width: "16px", height: "16px", mr: 1 }} />
                                                                <Typography variant="caption">
                                                                    You flagged this caption.
                                                                </Typography>
                                                            </Box>
                                                     : null
                                                        
                                                        
                                                        
                                                        }

                                                    {upVotedItems.filter(item => (item.change && item.change.text != "")).length > 0 ?
                                                        <>
                                                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", mt: 1 }}>
                                                                <ThumbUp sx={{ color: "white", width: "16px", height: "16px", mr: 1 }} />
                                                                <Typography variant="caption">
                                                                    Thank you for selecting the better caption.
                                                                </Typography>
                                                            </Box>
                                                        </> : null}

                                                    {downVotedItems.filter(item => (item.change && item.change.text != "")).length > 0 ?
                                                        <>
                                                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", mt: 1 }}>
                                                                <ThumbUp sx={{ color: "white", width: "16px", height: "16px", mr: 1 }} />
                                                                <Typography variant="caption">
                                                                    Thank you for selecting the better caption.
                                                                </Typography>
                                                            </Box>
                                                        </> : null}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>

                                                {upVotedItems.filter(item => (item.change && item.change.text != "")).length > 0 ?

                                                    upVotedItems.filter(item => (item.change && item.change.text != "")).map((item, index) => (
                                                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", m: 2 }}>

                                                            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: -4, mr: -5, mb: -2 }}>
                                                                <IconButton size="small" onClick={() => setRevert(!revert)} >{revert ? <Update sx={{ fill: "#999999" }} /> : <Restore sx={{ fill: "#999999" }} />}</IconButton>
                                                            </Box>

                                                            <Card sx={{ p: 2, background: "#373737", width: "100%", justifyContent: "center" }}>
                                                                <Typography variant="h6" align="center">
                                                                    {revert ?

                                                                        findDiffs(item.change.text, originalText).map(diff =>

                                                                            (diff.change == "") ?
                                                                                <>{diff.original + " "}</> :
                                                                                <mark>{diff.change + " "}</mark>
                                                                        )

                                                                        :

                                                                        findDiffs(originalText, item.change.text).map(diff =>

                                                                            (diff.change == "") ?
                                                                                <>{diff.original + " "}</> :
                                                                                <mark>{diff.change + " "}</mark>

                                                                        )}
                                                                </Typography>
                                                            </Card>
                                                        </Box>
                                                    ))

                                                    :

                                                    userItems.filter(item => (item.change && item.change.text != "")).length > 0 ?


                                                        userItems.filter(item => (item.change && item.change.text != "")).map((item, index) => (
                                                            <>
                                                                <Box sx={{ display: "flex", flexDirection: "column", width: "100%", m: 2 }}>

                                                                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: -4, mr: -5, mb: -2 }}>
                                                                        <IconButton size="small" onClick={() => setRevert(!revert)} >{revert ? <Update sx={{ fill: "#999999" }} /> : <Restore sx={{ fill: "#999999" }} />}</IconButton>
                                                                    </Box>

                                                                    <Box>
                                                                        <Card sx={{ p: 2, background: "#373737", width: "100%" }}>

                                                                            <Typography variant="h6" align="center">

                                                                                {revert ?

                                                                                    findDiffs(item.change.text, originalText).map(diff =>
                                                                                        (diff.change == "") ? <>{diff.original + " "}</> : <mark>{diff.change + " "}</mark>

                                                                                    )

                                                                                    :

                                                                                    findDiffs(originalText, item.change.text).map(diff =>
                                                                                        (diff.change == "") ? <>{diff.original + " "}</> : <mark>{diff.change + " "}</mark>
                                                                                    )

                                                                                }

                                                                            </Typography>
                                                                        </Card>
                                                                    </Box>


                                                                </Box>
                                                            </>
                                                        ))

                                                        :

                                                        <Card variant="outlined" sx={{ flexGrow: 1, p: 2, cursor: "pointer", background: "#373737" }}>
                                                            <Typography variant="h6" align="center">
                                                                {originalText}
                                                            </Typography>
                                                        </Card>
                                                }
                                            </Box>


                                            {(userItems.length > 0 || hasVotedUp) ?

                                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        size="small"
                                                        color="success"
                                                        variant="contained"
                                                        endIcon={<PlayArrow />}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            play();
                                                        }}
                                                    >
                                                        Resume Playing
                                                    </Button>
                                                </Box>
                                                :

                                                <Box sx={{ display: "flex", flexDirection: "row", mr: 2, ml: 2 }}>

                                                    <Box sx={{ flexGrow: .2, display: "flex" }}>
                                                        <Button size="small" endIcon={<Check />} variant="outlined" color="success" fullWidth onClick={() => handleCorrectFlag()}>
                                                            Correct
                                                        </Button>
                                                    </Box>
                                                    <Box sx={{ flexGrow: 1, ml: 1, display: "flex", alignItems: "center" }}>
                                                        <Tooltip title={"Flag This Caption"} placement="top">
                                                            <Button
                                                                color={flagTypes[0].color}
                                                                fullWidth
                                                                size="small"
                                                                variant="contained"
                                                                endIcon={flagTypes[0].icon}
                                                                onClick={() => handleCreateNewFlag()}
                                                            >
                                                                This Caption Is Wrong
                                                            </Button>
                                                        </Tooltip>

                                                    </Box>
                                                </Box>
                                            }

                                        </>
                                    </Box>
                                </Card>
                            </Box>
                            {/* Flipped Side of Card */}
                            <Box>
                                <Card elevation={0} style={{ background: "rgba(0,0,0,0.7)" }}>

                                {submitting ? <LinearCountdown stopped={demo} {...props} time={2.5} open={submitting} handleOpen={() => setSubmitting(false)} /> : null}

                                    <Box sx={{ display: "flex", mr: .5, ml:.5, cursor: "pointer", alignItems: "top", justifyContent: "flex-stretch" }} >
                                        <Box sx={{ flexGrow: 1 }} />
                                        <IconButton onClick={() => setHidePanel(true)}>
                                        <Close sx={{ height: "25px", width: "25px", fill: "#999999" }} />
                                        </IconButton>
                                    </Box>

                                  

                                    {hasItemsToVoteOn ?
                                        <>

                                            <Box sx={{ m: 2, mt: -2 }}>
                                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <Box sx={{ m: 1 }}>

                                                        <Box sx={{ height: "45px", display: "flex", alignItems: "center", justifyContent: "center", mr: 1 }} >

                                                            <IconButton size="small" onClick={seekToLastCue}><FirstPage sx={{ height: "20px", width: "20px", fill: "#999999", mr: -1 }} /></IconButton>

                                                            <Box sx={{ m: 2 }}>
                                                                <CaptionVoteIcon size={30} />
                                                            </Box>
                                                            <IconButton size="small" onClick={seekToNextCue} sx={{ ml: -1, transform: "rotateY(180deg)" }}><FirstPage sx={{ height: "20px", width: "20px", fill: "#999999" }} /></IconButton>
                                                        </Box>

                                                    </Box>

                                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        <Box>
                                                            <Typography variant="h6">Caption Feedback</Typography>

                                                            <Box sx={{ display: "flex", flexDirection: "row", mt: .2, mb: 1 }}>
                                                                <Box>
                                                                    <Typography variant="caption">{message()}</Typography>
                                                                </Box>
                                                                <Box sx={{ flexGrow: 1 }} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center", width: "100%", mt: 1, mb: 1 }}>

                                                    <Box sx={{ mr: 1 }}>
                                                        <Typography variant="h6">A: </Typography>
                                                    </Box>

                                                    <Card variant="outlined" sx={{ border: "1px solid white", flexGrow: 1, p: 2, cursor: "pointer", background: "#373737" }} onClick={() => handleDownVote(itemsToVoteOn[0])}>
                                                        <Typography variant="h6" align="center">
                                                            {findDiffs(itemsToVoteOn[0].change.text, originalText).map(diff =>


                                                                (diff.change == "") ?
                                                                    <>{diff.original + " "}</> :
                                                                    <mark>{diff.change + " "}</mark>

                                                            )}
                                                        </Typography>
                                                    </Card>

                                                </Box>

                                                <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center", width: "100%", mt: 1, mb: 1 }}>

                                                    <Box sx={{ mr: 1 }}>
                                                        <Typography variant="h6">B: </Typography>
                                                    </Box>
                                                    <Card variant="outlined" sx={{ border: "1px solid white", flexGrow: 1, p: 2, cursor: "pointer", background: "#373737" }} onClick={() => handleUpVote(itemsToVoteOn[0])}>
                                                        <Typography variant="h6" align="center">

                                                            {findDiffs(originalText, itemsToVoteOn[0].change.text).map(diff =>


                                                                (diff.change == "") ?
                                                                    <>{diff.original + " "}</> :
                                                                    <mark>{diff.change + " "}</mark>

                                                            )}

                                                        </Typography>
                                                    </Card>
                                                </Box>

                                                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>

                                                    <Box sx={{ flexGrow: 1 }} />
                                                    <Button onClick={() => handleDownVote(itemsToVoteOn[0])}
                                                        variant="outlined" endIcon={<ArrowRight />}>Neither</Button>
                                                </Box>

                                            </Box>

                                        </>
                                        :
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                                    <Box sx={{ mr: 3, ml:3 }}>
                                                        <CaptionEditIcon size={30} />
                                                    </Box>
                                                    <Box >
                                                        <Typography variant="h6">Update This Caption</Typography>
                                                        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                                                            <Info sx={{ width: "18px", height: "18px", color: "white" }} />
                                                            <Typography variant="caption" sx={{ color: "white", mr: 1, ml: 1 }}>
                                                                Fix this caption.
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant="caption">{message()}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ m: 1, mt:2, width: "35vw" }}>
                                                    <TextField
                                                        InputProps={{
                                                            inputProps: {
                                                                style: { textAlign: "center", }
                                                            },
                                                            style: {

                                                                fontSize: "1.1rem",
                                                                color: "white",
                                                                border: "1px solid #ffffff"
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        multiline
                                                        rows={2}
                                                        fullWidth
                                                        value={text}
                                                        onKeyDownCapture={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                        onChange={(e) => {
                                                            setText(e.target.value)
                                                        }} />
                                                </Box>

                                                <Box sx={{ display: "flex", flexDirection: "row", m: 1, justifyContent: "flex-end" }}>
                                                    <Box sx={{ display: "flex", flexGrow: .2, mr: 1 }}>
                                                        <Button
                                                            onClick={() => flipCard(false)}
                                                            fullWidth
                                                            size="small"
                                                            startIcon={<PlayArrow sx={{ transform: "rotate(180deg)" }} />}
                                                            variant="outlined" >Go Back</Button>
                                                    </Box>
                                                    <Box sx={{ display: "flex", flexGrow: .7 }}>
                                                        <Button
                                                            color="success"
                                                            fullWidth
                                                            disabled={(text == originalText) || submitting}
                                                            size="small"
                                                            onClick={() => {
                                                                handleSubmitChange(userItems[0]);
                                                            }}
                                                            variant="contained" >Submit Change</Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>

                                    }

                                </Card>
                            </Box>
                        </ReactCardFlip>
                    </>
                }
            </Box>
        </Grow>
    );
}

function HiddenCard(props){
    

    return (
        <Box sx={{m:1}}>
        {/* <Card elevation={0} > */}
        {/* style={{ background: "rgba(0,0,0,0.7)" }}> */}
        <Box sx={{m:2}}>
            <ButtonBase onClick={() => props.setHidePanel(false)}>
                <CaptionVisibilityIcon size={30}/>
            </ButtonBase>
        </Box>
            
    {/* </Card> */}
    </Box>

    )
}



function LinearCountdown(props) {

    const { time, stopped } = props;

    const [progress, setProgress] = React.useState(100);

    React.useEffect(() => {

        if (progress <= 0) {
            props.handleOpen();
            setProgress(100);
        }

    }, [progress]);

    React.useEffect(() => {

        setProgress(100);

    }, [props.open]);


    React.useEffect(() => {


        const timer =
            !stopped ?
                setInterval(() => {
                    setProgress((prevProgress) => (prevProgress - (10 / time)));
                }, (100)) : null;

        if (!stopped) {
            setTimeout(() => {
                clearInterval(timer);
            }, ((time * 1000) + 100));
        }


        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress color="error" style={{ height: "10px", transform: "rotate(180deg)" }} variant="determinate" value={progress} />
        </Box>
    );
}

export function CaptionAddIcon(props) {


    return (

        <Box sx={{ position: "relative" }}>

            <ClosedCaptionOutlined sx={{ color: "#ffbd58", width: props.size, height: props.size }} />

            <Box sx={{ position: "absolute", top: -props.size / 5, left: props.size / 1.8 }}>
                <AddCircle sx={{ color: "#ffbd58", height: props.size / 1.5, width: props.size / 1.5 }} />
            </Box>

            {props.children}

        </Box>

    );
}

export function CaptionFlagIcon(props) {


    return (

        <Box sx={{ position: "relative" }}>

            <ClosedCaptionOutlined sx={{ color: "#ffbd58", width: props.size, height: props.size }} />

            <Box sx={{ position: "absolute", top: -props.size / 5, left: props.size / 1.8 }}>
                <Error sx={{ color: "#ffbd58", height: props.size / 1.5, width: props.size / 1.5 }} />
            </Box>

            {props.children}

        </Box>

    );
}

export function CaptionVisibilityIcon(props) {


    return (

        <Box sx={{ position: "relative" }}>

            <ClosedCaptionOutlined sx={{ color: "#ffbd58", width: props.size, height: props.size }} />

            {/* <Box sx={{ position: "absolute", top: -props.size / 5, left: props.size / 1.8 }}>
                <Error sx={{ color: "#ffbd58", height: props.size / 1.5, width: props.size / 1.5 }} />
            </Box> */}

            <Box sx={{ position: "absolute", top: props.size / 2, left: props.size / 1.8 }}>
                <Visibility sx={{ color: "#ffbd58", height: props.size/1.5, width: props.size/1.5 }} />
            </Box>

            {props.children}

        </Box>

    );
}


export function CaptionFlagIconMarker(props) {
    return (

        <Box sx={{ position: "relative" }}>

            {/* <ClosedCaption sx={{color:"#ffbd58", width: props.size, height: props.size }}  /> */}

            <Box sx={{ position: "absolute", left: props.size / 6 }}>
                <Error sx={{ color: "#ffbd58", height: props.size, width: props.size }} />
            </Box>

            <Box sx={{ position: "absolute", top: 16, left: 0, zIndex: 10 }}>
                <VerticalAlignBottom sx={{ color: "#ffbd58", height: props.size, width: props.size }} />
            </Box>

        </Box>

    );
}


export function CaptionEditIcon(props) {


    return (

        <Box sx={{ position: "relative" }}>

            <ClosedCaption sx={{ color: "#ffbd58", width: props.size, height: props.size }} />

            <Box sx={{ position: "absolute", top: props.size / 6, left: props.size / 3 }}>
                <Edit sx={{ color: "#ffbd58", height: props.size, width: props.size }} />
            </Box>

        </Box>

    );
}

export function CaptionEditIconMarker(props) {
    return (

        <Box sx={{ position: "relative" }}>

            <Edit sx={{ color: "#ffbd58", height: props.size, width: props.size }} />

            <Box sx={{ position: "absolute", top: 16, left: 0, zIndex: 10 }}>
                <VerticalAlignBottom sx={{ color: "#ffbd58", height: props.size, width: props.size }} />
            </Box>

        </Box>

    );
}

export function CaptionVoteIcon(props) {
    return (

        <Box sx={{ position: "relative" }}>

            <ClosedCaptionOutlined sx={{ color: "#ffbd58", width: props.size, height: props.size }} />


            <Box sx={{ position: "absolute", top: -props.size / 3, left: props.size / 2 }}>
                <CheckBox sx={{ color: "#ffbd58", height: props.size / 1.4, width: props.size / 1.4 }} />
            </Box>

            {/* <Box sx={{position:"absolute", top:-props.size/5, left:-props.size/6}}>
        <ThumbUp sx={{color:"#ffbd58", width: props.size/1.5, height: props.size/1.5 }}  />
    </Box>
                                    
    <Box sx={{position:"absolute", top:props.size/1.8, left:props.size/2}}>
        <ThumbDown sx={{color:"#ffbd58", width: props.size/1.5, height: props.size/1.5 }}  />
    </Box> */}

            {props.children}

        </Box>
    );
}

export function CaptionVoteIconMarker(props) {
    return (

        <Box sx={{ position: "relative" }}>
            <CheckBox sx={{ color: "#ffbd58", width: props.size, height: props.size }} />

            <Box sx={{ position: "absolute", top: 16, left: 0, zIndex: 10 }}>
                <VerticalAlignBottom sx={{ color: "#ffbd58", height: props.size, width: props.size }} />
            </Box>

        </Box>

    );
}


export function CaptionFeedBackSliderControlBar(props) {

    const { feedbackMode } = props;
    console.log("feedbackMode", feedbackMode);

    return (

        feedbackMode ?

            <MediaControlBar>
                <MediaPlayButton />

                <Box sx={{ height: "45px", display: "flex", width: "100%", alignItems: "center", background: "linear-gradient(90deg, rgba(25,25,25,0.72) 0%, rgba(0,0,0,0.8) 40%, rgba(0,0,0,0.8) 60%, rgba(25,25,25,0.72) 100%)" }} >

                    <div style={{ marginLeft: 10, marginRight: 10, position: "relative", width: "100%" }} id="slider">
                        <SeekSlider {...props} />
                    </div>

                </Box>

                <CaptionFeedbackButton feedbackMode={feedbackMode} {...props} />
                <MediaVolumeRange />

            </MediaControlBar>

            :

            <MediaControlBar>
                <MediaPlayButton />

                <MediaTimeRange />

                <MediaTimeDisplay showDuration></MediaTimeDisplay>

                <MediaCaptionsButton />

                <CaptionFeedbackButton feedbackMode={feedbackMode} {...props} />

                <MediaVolumeRange />

            </MediaControlBar>

    );
}

function SeekSlider(props) {

    const { player, cue, userId, ccfId, textTracks, feedbackItems, duration, handleSeek, seekToCue, captionsEnabled, demo, seekToLastCue, seekToNextCue } = props;


    const [sliderWidth, setSliderWidth] = React.useState(0);
    const [currentTime, setCurrentTime] = React.useState(player.current.currentTime);

    console.log("CAPTION FEEDBACK SLIDER CURRENT TIME", currentTime);
    console.log("Slider Width", sliderWidth);
    console.log("Duration", duration);


    const handleSliderWidth = () => {
        setSliderWidth(document.getElementById("slider").getBoundingClientRect().width);
    }

    const handleCurrentTime = () => {
        setCurrentTime(player.current.currentTime);
    }


    React.useEffect(() => {
        setSliderWidth(document.getElementById("slider").getBoundingClientRect().width);

        window.addEventListener("resize", handleSliderWidth, true);
        player.current.addEventListener("timeupdate", handleCurrentTime, true);


        return () => {

            window.removeEventListener("resize", handleSliderWidth, true);
            player.current.removeEventListener("timeupdate", handleCurrentTime, true);

        }


    }, []);

    return (
        <>
            <VideoSeekSlider
                max={duration}
                currentTime={currentTime}
                bufferTime={3000}
                onChange={handleSeek}
                secondsPrefix="00:00:"
                minutesPrefix="00:"
                hideThumbTooltip
            />

            {textTracks.cues ?
                feedbackItems.map((item, index) =>

                    <div onClick={() => seekToCue(textTracks.cues[item.key - 1])}
                        style={{ position: "absolute", cursor: "pointer", top: -24, left: -10 + (textTracks.cues[item.key - 1].startTime / duration) * sliderWidth }}>

                        {item.ouuid != userId ? <CaptionVoteIconMarker size={20} /> :

                            ((!item.change || item.change.text != "") ? <CaptionEditIconMarker size={20} /> : <CaptionFlagIconMarker size={20} />)}

                    </div>
                )

                : null}

        </>

    );
}